import { AxiosResponse } from "axios";
import BaseApi from "./base";

export default class AuthApi extends BaseApi {
  static async signup(
    email: string,
    password1: string,
    password2: string,
    first_name: string,
  ): Promise<AxiosResponse<void>> {
    return this.postApiCall("/dj-rest-auth/registration/", {
      username: email,
      email,
      password1,
      password2,
      first_name: first_name,
    });
  }

  static async signupSocial(
    provider: string,
    code: string,
  ): Promise<{
    token: string;
  }> {
    try {
      const response = await this.postApiCall<{
        token: string;
      }>("/dj-rest-auth/" + provider + "/", {
        code: code,
      });
      if ("token" in response) {
        this.setToken(response.token);
      }
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  static async login(
    email: string,
    password: string,
  ): Promise<{ token: string }> {
    const authObj = {
      username: email,
      email,
      password,
    };

    const data = await this.postApiCall<{
      token: string;
    }>("/dj-rest-auth/login/", authObj);
    if ("token" in data) {
      this.setToken(data.token);
    }
    return data;
  }

  static async resetPassword(email: string): Promise<void> {
    const authObj = {
      email,
    };
    await this.postApiCall("/auth/send-code-email/", authObj);
  }

  static async confirmCode(email: string, code: string): Promise<void> {
    const authObj = {
      email,
      code,
    };

    await this.postApiCall("/auth/send-code-email/conf/", authObj);
  }

  static async sendNewPassword(
    email: string,
    password1: string,
    password2: string,
    code: string,
  ): Promise<void> {
    await this.postApiCall("/auth/send-code-email/code/", {
      email,
      password1,
      password2,
      code,
    });
  }

  static async getSocialProviderLoginURL(provider: string): Promise<string> {
    return await this.getApiCall<string>(`/auth/${provider}/`);
  }

  static async confirmEmail(key: string): Promise<void> {
    await this.postApiCall("/auth/verify-email/", {
      key: key,
    });
  }

  static async resendConfirmationEmail(email: string): Promise<void> {
    await this.postApiCall("/auth/resend-email/", {
      email: email,
    });
  }
}
