import { Organization, Plan, PlanTypeDetails } from "../types";
import BaseApi from "./base";

interface StripeLinkResponse {
  success: boolean;
  customer_id: string;
  sessionId: string;
}

interface StripeSessionResponse {
  success: boolean;
  url: string;
  sessionId: string;
}

interface AgentPlanDetails {
  plan: Plan;
  organization: Organization;
  details: PlanTypeDetails;
}

export default class BillingApi extends BaseApi {
  static async getStripePaymentLinkForOrganization(
    organization_id: string,
    yearly: boolean = false,
  ): Promise<StripeLinkResponse> {
    return await this.authenticatedGetApiCall(
      `/stripe/payment-link/${organization_id}?yearly=${yearly}`,
    );
  }

  static async getStripeCustomerPortalSession(
    organization_id: string,
  ): Promise<StripeSessionResponse> {
    return await this.authenticatedGetApiCall(
      `/stripe/customer-portal-session/${organization_id}`,
    );
  }
  static async getPlanLimitPerAgent(
    agentUUID: string,
  ): Promise<AgentPlanDetails> {
    return await this.authenticatedGetApiCall(`/agents/${agentUUID}/plan/`);
  }
}
