import { useCallback, useEffect, useState } from "react";

import { Button } from "@/components/design-system/button";
import SourceSetImage from "@/components/SourceSetImage";
import SmallLabel from "@/components/styled/SmallLabel";
import Tooltip from "@/components/styled/Tooltip";
import TopicLabel from "@/components/styled/TopicLabel";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, EditMode, SourceSet, Topic } from "@/utils/types";
import classNames from "classnames";
import { usePostHog } from "posthog-js/react";
import {
  RiAttachment2,
  RiRssLine,
  RiSearch2Line,
  RiUserFollowFill,
} from "react-icons/ri";
import { Divider } from "./design-system/divider";
import { SourceSetInformation } from "./SourceSetInformation";
import SecondaryPanel from "./styled/panels/SecondaryPanel";

type SourceSetMosaicProps = {
  // sourcesets: SourceSet[];
  agent: Agent;
  tool_key: string;
  buttonAction?: () => void;
  editModeAction?: (mode: EditMode | null) => void;
  seeMoreAction?: () => void;
  onRefresh?: () => void;
  maxSourcesets?: number;
  maxSourcesetsPerCategory?: number;
  showSourcesets?: boolean;
  showTopics?: boolean;
  showSuggestedSourcesets?: boolean;
};

const SourceSetMosaic = ({
  agent,
  tool_key,
  editModeAction,
  seeMoreAction,
  buttonAction,
  onRefresh,
  maxSourcesets = 10,
  maxSourcesetsPerCategory = 5,
  showSourcesets = true,
  showTopics = true,
  showSuggestedSourcesets = false,
}: SourceSetMosaicProps) => {
  const posthog = usePostHog();
  const [sourcesetImagesByType, setSourcesetImagesByType] = useState<
    Record<string, SourceSet[]>
  >({});
  const [sourcesetByTypeWithoutImage, setSourcesetByTypeWithoutImage] =
    useState<Record<string, number>>({});

  const [sourcesets, setSourcesets] = useState<SourceSet[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedSourceSet, setSelectedSourceSet] = useState<SourceSet | null>(
    null,
  );
  const [sourcesetsLeft, setSourcesetsLeft] = useState(0);
  const [suggestedSourceSets, setSuggestedSourceSets] = useState<SourceSet[]>(
    [],
  );

  const toggleDetailModal = () => {
    setOpenDetailModal(!openDetailModal);
  };
  const openDetailModalAction = (sourceset: SourceSet) => {
    setSelectedSourceSet(sourceset);
    posthog.capture("sourceset mosaic: close detail modal");
    setOpenDetailModal(true);
  };

  const loadSourceSets = useCallback(async () => {
    if (!agent.artefact || !agent.artefact.inputs) {
      setTopics([]);
      setSourcesets([]);
      setSuggestedSourceSets([]);
      setSourcesetImagesByType({});
      setSourcesetsLeft(0);
      return;
    }
    let inputs = agent.artefact.inputs;
    let toolInputSourcesets = inputs.sourceset;
    if (!toolInputSourcesets) {
      setSourcesets([]);
      setSuggestedSourceSets([]);
      setSourcesetImagesByType({});
      setSourcesetsLeft(0);
      return;
    }
    //filter per tool_key
    toolInputSourcesets = toolInputSourcesets.filter(
      (sourceset) => sourceset.tool_key === tool_key,
    );

    const suggested_sourcesets_uuids = toolInputSourcesets
      .filter((sourceset) => sourceset.metadata.suggested)
      .map((sourceset) => sourceset.object.uuid);

    const sourceSetUUIDs = toolInputSourcesets.map((sourceset) => {
      return sourceset.object.uuid;
    });
    const sourcesets =
      await MarconipyApi.source.batchGetSourceSets(sourceSetUUIDs);
    const newSuggestedSourcesets = !showSuggestedSourcesets
      ? sourcesets.filter((sourceset) =>
          suggested_sourcesets_uuids.includes(sourceset.uuid),
        )
      : [];
    const filteredSourcesets = sourcesets.filter(
      (sourceset) =>
        newSuggestedSourcesets.findIndex((s) => s.uuid === sourceset.uuid) ===
        -1,
    );
    if (filteredSourcesets.length > 0) {
      setSourcesets(filteredSourcesets);
    }
    if (newSuggestedSourcesets.length > 0) {
      setSuggestedSourceSets(newSuggestedSourcesets);
    }
    if (showSuggestedSourcesets) {
      setSourcesetsLeft(toolInputSourcesets.length - maxSourcesets);
    } else {
      setSourcesetsLeft(
        toolInputSourcesets.length -
          sourcesets.length -
          suggested_sourcesets_uuids.length,
      );
    }
  }, [maxSourcesets, showSuggestedSourcesets, tool_key, agent.artefact]);

  const loadTopics = useCallback(async () => {
    if (!showTopics) {
      return;
    }
    if (!agent.artefact || !agent.artefact.inputs) {
      setSourcesets([]);
      setTopics([]);
      setSuggestedSourceSets([]);
      setSourcesetImagesByType({});
      setSourcesetsLeft(0);
      return;
    }
    let inputs = agent.artefact.inputs;
    if (typeof agent.artefact.inputs === "string") {
      inputs = JSON.parse(agent.artefact.inputs);
    }
    let toolInputTopics = inputs.topic;
    if (!toolInputTopics || toolInputTopics.length == 0) {
      setTopics([]);
      return;
    }
    const topicUUIDs = toolInputTopics.map((topic) => {
      return topic.object.uuid;
    });
    if (topicUUIDs.length === 0) {
      return;
    }
    const topics = await MarconipyApi.source.batchGetTopics(topicUUIDs);
    setTopics(topics);
  }, [showTopics, agent.artefact]);

  useEffect(() => {
    const refreshView = async () => {
      await loadSourceSets();
      await loadTopics();
    };
    refreshView();
  }, [loadSourceSets, loadTopics, agent.artefact.inputs, agent.uuid]);

  const typesToWords: Record<string, string> = {
    text: "Websites",
    rssapp: "RSS Feeds",
    youtube: "YouTube Channels",
    podcast: "Podcasts & Audio",
    twitter: "Twitter",
    facebook: "Facebook",
    mastodon: "Mastodon",
    rss_app: "Feed",
    topic: "Keywords",
    default: "Other",
  };

  const removeSourceset = async function (sourceset_uuid: string) {
    await MarconipyApi.input.remove(agent.uuid, sourceset_uuid, tool_key);
    onRefresh && onRefresh();
  };

  const seeMore = () => {
    seeMoreAction && seeMoreAction();
  };

  useEffect(() => {
    const setSourcesetsByImage = async () => {
      let tempSourcesetByType: Record<string, SourceSet[]> = {};
      let tempSourcesetByTypeWithoutImage: Record<string, number> = {};
      sourcesets.forEach((sourceset) => {
        // if (sourceset.image && sourceset.image !== "default") {
        if (!tempSourcesetByType[sourceset.type]) {
          tempSourcesetByType[sourceset.type] = [];
        }
        if (
          !tempSourcesetByType[sourceset.type].includes(sourceset) &&
          tempSourcesetByType[sourceset.type].length < maxSourcesetsPerCategory
        ) {
          tempSourcesetByType[sourceset.type].push(sourceset);
        } else {
          if (!tempSourcesetByTypeWithoutImage[sourceset.type]) {
            tempSourcesetByTypeWithoutImage[sourceset.type] = 0;
          }
          tempSourcesetByTypeWithoutImage[sourceset.type] += 1;
        }
        // } else {
        //   if (!tempSourcesetByTypeWithoutImage[sourceset.type]) {
        //     tempSourcesetByTypeWithoutImage[sourceset.type] = 0;
        //   }
        //   tempSourcesetByTypeWithoutImage[sourceset.type] += 1;
        // }
        setSourcesetImagesByType(tempSourcesetByType);
        setSourcesetByTypeWithoutImage(tempSourcesetByTypeWithoutImage);
      });
    };
    setSourcesetsByImage();
  }, [maxSourcesetsPerCategory, sourcesets]);

  const handleTopicRemoval = async () => {
    onRefresh && onRefresh();
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {showTopics && topics.length > 0 && (
          <div className="text-left flex flex-col gap-2">
            <SmallLabel>Suggest sources about </SmallLabel>
            <div className="grid">
              <div className="flex flex-wrap gap-2">
                {topics.map((topic) => (
                  <TopicLabel
                    topic={topic}
                    tool_key={tool_key}
                    onRemove={handleTopicRemoval}
                    agent={agent}
                    key={topic.uuid}
                    onRefresh={onRefresh}
                    showSourcesCounter={false}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {showSourcesets && (
          <>
            {Object.keys(sourcesetImagesByType)
              .sort(
                (a, b) =>
                  sourcesetImagesByType[b].length -
                  sourcesetImagesByType[a].length,
              )
              .map((type, index) => (
                <div
                  className={classNames("text-left", {
                    "my-0": index != 0,
                    "mb-0": index == 0,
                  })}
                  key={type}
                >
                  <SmallLabel>{typesToWords[type]}</SmallLabel>
                  <div className="grid">
                    <div className="flex flex-wrap gap-2">
                      {sourcesetImagesByType[type].map((sourceset, index) =>
                        sourceset.image !== "default" || !sourceset.image ? (
                          <div
                            className="hover:cursor-pointer"
                            key={index}
                            onClick={() => openDetailModalAction(sourceset)}
                          >
                            <Tooltip content={sourceset.name}>
                              <SourceSetImage
                                image={sourceset.image}
                                type={type}
                              />
                            </Tooltip>
                          </div>
                        ) : null,
                      )}
                      {sourcesetByTypeWithoutImage[type] && (
                        <div
                          className="h-8 w-8 flex-shrink-0 text-center flex justify-center items-center bg-gray-200 dark:bg-black-light rounded-full hover:cursor-pointer"
                          onClick={seeMore}
                        >
                          +{sourcesetByTypeWithoutImage[type]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {sourcesetsLeft > 0 && (
              <div
                className="h-8 w-8 flex-shrink-0 text-center flex justify-center items-center bg-gray-200 dark:bg-black-light rounded-full hover:cursor-pointer"
                onClick={seeMore}
              >
                +{sourcesetsLeft.toString()}
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {editModeAction && (
          <>
            {!(
              Object.keys(sourcesetImagesByType).length == 0 &&
              suggestedSourceSets.length == 0
            ) && (
              <>
                <Divider className="mt-2 mb-4" />
              </>
            )}
            <div className="flex items-center gap-2 flex-wrap">
              {/* <Button
                variant="secondary"
                onClick={() => {
                  editModeAction(EditMode.Wizard);
                }}
              >
                <RiMagicFill /> Suggest sources
              </Button> */}
              <Button
                variant="secondary"
                onClick={() => {
                  editModeAction(EditMode.Social);
                }}
              >
                <RiUserFollowFill /> Social accounts
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  editModeAction(EditMode.RSS);
                }}
              >
                <RiRssLine /> Blogs & websites
              </Button>
              <Tooltip content="Add a topic or a keyword and get the latest about it">
                <Button
                  variant="secondary"
                  onClick={() => {
                    editModeAction(EditMode.Topic);
                  }}
                >
                  <RiSearch2Line /> Monitor keyword
                </Button>
              </Tooltip>
              <Button
                variant="secondary"
                onClick={() => {
                  editModeAction(EditMode.Upload);
                }}
              >
                <RiAttachment2 /> Upload CSV
              </Button>
            </div>
          </>
        )}
        {buttonAction && (
          <Button
            onClick={buttonAction}
            variant="primary"
            disabled={sourcesets.length == 0}
          >
            Continue
          </Button>
        )}
      </div>
      <SecondaryPanel
        open={openDetailModal}
        onClose={toggleDetailModal}
        title={selectedSourceSet?.name ?? ""}
      >
        {selectedSourceSet && (
          <SourceSetInformation
            sourceset={selectedSourceSet}
            added={true}
            showAddRemoveButton={true}
            functionToAddButton={() => {}}
            functionToRemoveButton={removeSourceset}
            onRefresh={onRefresh}
          />
        )}
      </SecondaryPanel>
    </>
  );
};

export default SourceSetMosaic;
