import { Message, Run } from "../types";
import BaseApi from "./base";

export default class RunApi extends BaseApi {
  static async start(agentUUID: string): Promise<Run> {
    return await this.authenticatedPostApiCall<Run>(
      `/conversations/${agentUUID}/runs/`,
    );
  }

  static async getRuns(
    agentUUID: string,
    completed: boolean = false,
  ): Promise<Run[]> {
    return await this.authenticatedGetApiCall<Run[]>(
      `/agents/${agentUUID}/runs/`,
      {
        completed: completed,
      },
    );
  }

  static async getRun(runUUID: string): Promise<Run> {
    return await this.authenticatedGetApiCall<Run>(`/runs/${runUUID}/`);
  }

  static async getMessages(runUUID: string): Promise<Message[]> {
    return await this.authenticatedGetApiCall<Message[]>(
      `/runs/${runUUID}/messages/`,
    );
  }
}
