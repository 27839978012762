import SubchapterTitle from "@/components/SubchapterTitle";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import Tooltip from "@/components/styled/Tooltip";
import { Agent } from "@/utils/types";
import { useRef } from "react";
import { RiQuestionFill } from "react-icons/ri";
import OutputChannels from "./OutputChannels";
import OutputTemplates from "./OutputTemplates";
import Triggers from "./Triggers";

function AdvancedStep({ agent }: { agent: Agent }) {
  const triggersRef = useRef<HTMLDivElement>(null);
  const outputRef = useRef<HTMLDivElement>(null);
  const formatRef = useRef<HTMLDivElement>(null);

  return (
    <div className="overflow-y-auto overflow-x-hidden h-full flex flex-col gap-2">
      <div
        className="w-full flex items-center justify-between"
        ref={triggersRef}
      >
        <SubchapterTitle title="Triggers" />
        <Tooltip content="Choose when to trigger your task">
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
              )
            }
          >
            <RiQuestionFill />
          </Button>
        </Tooltip>
      </div>
      <p className="text-sm">Choose when to trigger your task</p>
      <Triggers agent={agent} />
      <Divider className="mb-12" />
      <div className="w-full flex items-center justify-between" ref={outputRef}>
        <SubchapterTitle title="Results" />
        <Tooltip content="Choose where to send the result of your task">
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
              )
            }
          >
            <RiQuestionFill />
          </Button>
        </Tooltip>
      </div>
      <p className="text-sm">Choose how to get the result of your task</p>
      <OutputChannels agent={agent} />
      <Divider className="mb-12" />
      <div className="w-full flex items-center justify-between" ref={formatRef}>
        <SubchapterTitle title="Result format" />
        <Tooltip content="Choose how to get the result of your task">
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
              )
            }
          >
            <RiQuestionFill />
          </Button>
        </Tooltip>
      </div>
      <p className="text-sm">Choose how to get the result of your task</p>
      <OutputTemplates agent={agent} />
    </div>
  );
}

export default AdvancedStep;
