import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import { Input } from "@/components/design-system/input";
import SearchResult from "@/components/SearchResult";
import SourceSetImage from "@/components/SourceSetImage";
import { IconsUtils } from "@/utils/Icons";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import {
  RiAddFill,
  RiAddLargeFill,
  RiCloseFill,
  RiFileWarningLine,
  RiLoader2Fill,
} from "react-icons/ri";
import { toast } from "react-toastify";
import { Agent, SourceSet } from "utils/types";

type SelectSourceSetsProps = {
  buttonAction?: () => void;
  agent: Agent;
  tool_key: string;
  onRefresh?: () => void;
  onLoading?: (value: boolean) => void;
  variant: "social" | "rss" | "topic";
};

const SelectSourceSets = ({
  buttonAction,
  agent,
  tool_key,
  onRefresh,
  onLoading,
  variant,
}: SelectSourceSetsProps) => {
  const posthog = usePostHog();
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<SourceSet[]>([]);
  const [searchInputIsFocused, setSearchInputIsFocused] =
    useState<boolean>(false);
  // const [searchNew, setSearchNew] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState<boolean>(false);
  const [manualLink, setManualLink] = useState<string>("");
  const [manualLinkLoading, setManualLinkLoading] = useState<string>("");
  const [justaddedsourceset, setJustAddedSourceSet] =
    useState<SourceSet | null>(null);
  const [showMosaicView, setShowMosaicView] = useState<boolean>(false);

  const searchNew = false; // not implemented, legacy from tailor

  useEffect(() => {
    setSearchInputIsFocused(true);
  }, [agent.uuid, variant]);

  const addSourceSet = async function (sourceset: SourceSet) {
    setQuery("");
    setShowMosaicView(false);
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setJustAddedSourceSet(sourceset);
    posthog.capture("agentEdit: add source set", {
      source: "select source sets partial view",
      mode_action: variant,
    });
    toast.success(`${sourceset.name} added!`, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    await MarconipyApi.input.add({
      agentUUID: agent.uuid,
      toolKey: tool_key,
      type: "sourceset",
      uuid: sourceset.uuid,
    });
    onRefresh && (await onRefresh());
    setShowMosaicView(true);
  };

  const searchAutocomplete = async function (squery: string) {
    setJustAddedSourceSet(null);
    setShowAddLinkModal(false);
    setIsSearching(true);
    setQuery(squery);
    if (squery.length < 3) {
      setSuggestions([]);
      return;
    }
    let sourcesets =
      await MarconipyApi.source.searchSourceSetAutocomplete(squery);
    setSuggestions(sourcesets);
    setIsSearching(false);
  };
  // const searchSourcesets = async function (force = false) {
  //   setJustAddedSourceSet(null);
  //   if (query.length < 2) {
  //     setSuggestions([]);
  //     return;
  //   }
  //   setSearchNew(true);
  //   let sourcesets = (await MarconipyApi.searchSourceSet(
  //     query,
  //     force,
  //   ));
  //   setSuggestions(sourcesets);
  //   setSearchNew(false);
  // };

  const searchWasFocused = async function () {
    setSearchInputIsFocused(true);
  };
  const searchWasoutOfFocus = async function () {
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setQuery("");
  };

  const handleSourceSetSubmit = async function (e: any) {
    e.preventDefault();
  };

  const addManualLink = async function (url: string) {
    setManualLinkLoading("loading");
    onLoading?.(true);
    posthog.capture("agentEdit: add manual link", {
      source: "select source sets partial view",
    });
    const sourceset = await MarconipyApi.source.searchSourceSetByLink(url);
    if (sourceset && sourceset.uuid) {
      addSourceSet(sourceset);
      setManualLink("");
      setQuery("");
      setManualLinkLoading("done");
      setJustAddedSourceSet(sourceset);
    } else {
      setManualLinkLoading("noresults");
    }
    onLoading?.(false);
  };
  const toggleAddLinkModal = () => {
    //if the query is already a link, or if the variant is topic, we don't need to show the second step. just add it
    if (variant == "topic" || query.startsWith("http")) {
      addManualLink(query);
    }
    setShowAddLinkModal(!showAddLinkModal);
  };

  useEffect(() => {
    if (!showAddLinkModal) {
      setManualLinkLoading("");
    }
  }, [showAddLinkModal]);

  return (
    <div className="">
      {manualLinkLoading != "done" && (
        <div className="flex flex-col">
          <form onSubmit={handleSourceSetSubmit}>
            <div className="flex relative mt-4">
              <DebounceInput
                type="text"
                value={query}
                placeholder="🔎 Search..."
                onChange={(e) => searchAutocomplete(e.target.value)}
                onFocus={searchWasFocused}
                autoFocus={true}
                className="border border-gray rounded-md py-1 px-2 w-full dark:bg-black dark:text-white"
              />
              {searchInputIsFocused && (
                <span className="absolute flex p-2 items-center inset-y-0 right-0 cursor-pointer hover:text-primary">
                  <RiCloseFill onClick={searchWasoutOfFocus} className="" />
                </span>
              )}
            </div>
          </form>
          <div className="flex flex-col max-h-60 overflow-y-auto">
            {(suggestions.length > 0 || query?.length > 0) && (
              <div className="flex flex-col my-2 dark:text-white">
                {query?.length > 0 && searchNew && (
                  <SearchResult
                    key={query}
                    value={{
                      name: "Searching more sources for: " + query,
                      uuid: "temp",
                    }}
                    icon={<RiLoader2Fill className="animate-spin" />}
                    callback={() => {}}
                  />
                )}
                {suggestions &&
                  suggestions.map((sourceset) => {
                    const Icon = IconsUtils.fromSourceSetTypeToIcon(
                      sourceset.type,
                    ); // get the icon component
                    return (
                      <SearchResult
                        key={sourceset.uuid}
                        value={sourceset}
                        icon={<Icon />}
                        callback={addSourceSet}
                      />
                    );
                  })}
                {/* {query?.length > 1 &&
                  !isSearching &&
                  suggestions.length > 4 &&
                  !searchNew && (
                    <SearchResult
                      key={query + "_more"}
                      value={{
                        name: "Search more sources for: " + query,
                        uuid: "temp",
                      }}
                      icon={<RiSearch2Line />}
                      callback={function () {
                        searchSourcesets(true);
                      }}
                    />
                  )} */}
              </div>
            )}
            {searchInputIsFocused &&
              suggestions.length == 0 &&
              !searchNew &&
              !showMosaicView &&
              query == "" && (
                <div className="flex flex-col align-center text-center mt-8">
                  <h2 className="font-sans text-base">
                    {variant == "social" &&
                      "Add Twitter, Linkedin, Facebook, Youtube channels..."}
                    {variant == "rss" && "Add blogs, news websites..."}
                    {variant == "topic" && "Add a keyword to monitor..."}
                  </h2>
                </div>
              )}
            {/* {!searchInputIsFocused && sourcesets && (
            <div className="align-left py-4">
              {sourcesets.map((sourceset) => (
                <div key={sourceset.uuid}>
                  <SourceSetRowItem
                    sourceset={sourceset}
                    key={sourceset.uuid}
                    functionToAddButton={addSourceSet}
                    functionToRemoveButton={removeSourceset}
                  />
                </div>
              ))}
            </div>
          )} */}
            {query?.length > 2 &&
              !isSearching &&
              !searchNew &&
              suggestions.length == 0 &&
              !showAddLinkModal && (
                <div className="flex flex-row gap-4 items-center dark:text-white mt-2">
                  <p>
                    {`We don't have `}
                    {query} in our database. Want to add it?
                  </p>
                  <Button
                    onClick={toggleAddLinkModal}
                    variant="primary"
                    className="flex-none"
                  >
                    <RiAddLargeFill />
                    {variant == "topic" && "Add new topic"}
                    {variant != "topic" && "Add new from URL"}
                  </Button>
                </div>
              )}
          </div>
        </div>
      )}
      <div className={showAddLinkModal ? "dark:text-white" : "hidden"}>
        {manualLinkLoading == "" && (
          <>
            <Divider />
            <br />
            <h2 className="mt-2">
              {variant == "topic"
                ? "Type a topic, keyword or query"
                : "Paste a link"}
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addManualLink(manualLink);
              }}
              className="flex flex-col gap-2"
            >
              <Input
                type="text"
                name="url"
                placeholder={
                  variant == "social"
                    ? "Paste the link of a X (Twitter) account, a Youtube channel, a blog..."
                    : variant == "rss"
                      ? "Paste the link of a blog, a news website..."
                      : "Paste the link of a topic, a keyword, a query..."
                }
                value={manualLink}
                onChange={(e) => setManualLink(e.target.value)}
              />
              <Button
                variant="primary"
                disabled={manualLink.length == 0}
                type="submit"
              >
                <RiAddFill />
                Add
              </Button>
            </form>
          </>
        )}
        {manualLinkLoading == "loading" && (
          <>
            <h2 className="mt-2">Loading...</h2>
            <div className="p-4 flex">
              <RiLoader2Fill className="animate-spin text-xl mr-4" />
              <p className="opacity-70">Checking {manualLink} ...</p>
            </div>
          </>
        )}
        {manualLinkLoading == "done" && (
          <>
            <h2 className="mt-2">Done! This source was added:</h2>
            <br />
            {justaddedsourceset && (
              <>
                <Divider />
                <div className="flex my-2">
                  <SourceSetImage
                    image={justaddedsourceset.image}
                    type={justaddedsourceset.type}
                  />
                  <div className="flex flex-col">
                    <p className="ml-2 dark:text-white">
                      {justaddedsourceset?.name}
                    </p>
                    <p className="ml-2 text-sm opacity-70 dark:text-white">
                      {justaddedsourceset?.description}
                    </p>
                  </div>
                </div>
                <Divider />
              </>
            )}
            <div className="flex gap-2 mt-4 text-left">
              <Button
                onClick={function () {
                  setManualLink("");
                  setManualLinkLoading("");
                  setShowAddLinkModal(false);
                }}
                variant="primary"
              >
                Add more
              </Button>
              <Button
                onClick={function () {
                  setManualLinkLoading("");
                  buttonAction && buttonAction();
                }}
                variant="secondary"
              >
                Close
              </Button>
            </div>
          </>
        )}
        {manualLinkLoading == "noresults" && (
          <div className="flex flex-col gap-2 text-left">
            <div className="flex items-center gap-2">
              <RiFileWarningLine className="icon" />
              <p>
                {` `}No results were found for <strong>{manualLink}</strong>
                <br />
              </p>
            </div>
            <p>
              You can{" "}
              <a
                href="#"
                onClick={function () {
                  setManualLinkLoading("");
                }}
              >
                try with a different link
              </a>{" "}
              or{" "}
              <a
                href="mailto:info@tailortask.ai"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                get in touch with our team
              </a>
            </p>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default SelectSourceSets;
