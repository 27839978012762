import React from "react";
import ReactDOM from "react-dom";
import { RiCloseLine } from "react-icons/ri";

interface SecondaryPanelProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

const SecondaryPanel: React.FC<SecondaryPanelProps> = ({
  open: isOpen,
  onClose,
  title,
  children,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-30">
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black opacity-25 transition-opacity duration-300 ease-in-out"
        onClick={onClose}
      />

      {/* Secondary Panel */}
      <div className="absolute inset-y-0 sm:right-0 lg:right-auto lg:left-[500px] w-full sm:w-5/6 md:w-[400px] lg:w-[500px] bg-white dark:bg-zinc-800 shadow-lg-l border-r transform transition-transform duration-300 ease-in-out translate-x-0">
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-4 border-b dark:border-zinc-700">
            <h2 className="text-xl font-semibold text-zinc-900 dark:text-white">
              {title}
            </h2>
            <button
              onClick={onClose}
              className="p-2 rounded-full hover:bg-zinc-100 dark:hover:bg-zinc-700 transition-colors duration-200"
            >
              <RiCloseLine
                className="text-zinc-600 dark:text-zinc-400"
                size={24}
              />
            </button>
          </div>
          <div className="flex-grow overflow-auto p-4">{children}</div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default SecondaryPanel;
