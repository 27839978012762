import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
} from "@/components/design-system/dialog";
import { Select } from "@/components/design-system/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/design-system/table";
import { Loading } from "@/components/Loading";
import Tooltip from "@/components/styled/Tooltip";
import RunPartialView from "@/screens/partials/RunPartialView";
import MarconipyApi from "@/utils/marconipyApi";
import { NameConventions } from "@/utils/NameConventions";
import { Run, SelectOption } from "@/utils/types";
import { useEffect, useMemo, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Column, useSortBy, useTable } from "react-table";
import TimeAgo from "react-timeago";

const RunsPartialView = ({ agentUUID }: { agentUUID: string }) => {
  const [runs, setRuns] = useState<Run[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAllRuns = async () => {
      const runs = await MarconipyApi.run.getRuns(agentUUID);
      setRuns(runs);
      setLoading(false);
    };
    loadAllRuns();
  }, [agentUUID]);

  const stateTypeOptions: SelectOption[] = [
    { value: "all", label: "All States" },
    { value: "completed", label: "Completed" },
    { value: "failed", label: "Failed" },
    { value: "running", label: "Running" },
    { value: "pending", label: "Pending" },
    { value: "waiting_for_input", label: "Waiting for Input" },
  ];
  const [stateFilter, setStateFilter] = useState<string>(
    stateTypeOptions[0].value,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRun, setSelectedRun] = useState<Run | null>(null);

  const columns = useMemo<Column<Run>[]>(
    () => [
      {
        Header: "State",
        accessor: (row) => row.state.name,
        Cell: ({ value }: { value: string }) => (
          <p>{NameConventions.toHuman(value)}</p>
        ),
      },
      {
        Header: "Last updated",
        accessor: "updated_at",
        Cell: ({ value }: { value: Date }) => (
          <Tooltip
            content={
              typeof value != "string"
                ? value.toLocaleDateString() +
                  " - " +
                  value.toLocaleTimeString()
                : value
            }
          >
            <TimeAgo date={value} />
          </Tooltip>
        ),
      },
    ],
    [],
  );
  const tableData = useMemo(
    () =>
      runs
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
        )
        .filter((run) => {
          if (stateFilter !== "all" && run.state.name !== stateFilter)
            return false;
          return true;
        }),
    [stateFilter, runs],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData }, useSortBy);

  if (tableData.length === 0 && !loading) {
    return (
      <div className="flex flex-col gap-2 h-full min-h-80 p-12">
        <p className="text-center">No runs found</p>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-2 h-full min-h-80 p-2">
      <div className="flex gap-4">
        <Select name="state" onChange={(e) => setStateFilter(e.target.value)}>
          {stateTypeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>

      <Table {...getTableProps()} className="ml-1">
        <TableHead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any) => (
                <TableHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  <div className="flex flex-row gap-4">
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <RiArrowDownSLine width={16} />
                      ) : (
                        <RiArrowUpSLine width={16} />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                key={row.original.uuid}
                onClick={() => {
                  setSelectedRun(row.original);
                  setModalOpen(true);
                }}
                className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900"
              >
                {row.cells.map((cell: any) => (
                  <TableCell
                    {...cell.getCellProps()}
                    className="py-4 whitespace-nowrap"
                    key={cell.column.id}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} size="5xl">
        {!selectedRun && <Loading />}
        {selectedRun && (
          <>
            <DialogBody>
              <RunPartialView agentUUID={agentUUID} run={selectedRun} />
            </DialogBody>
            <DialogActions>
              <Button onClick={() => setModalOpen(false)} variant="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};
export default RunsPartialView;
