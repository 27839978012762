export class TextFrontend {
  static humanUrl(url?: string): string {
    if (!url) {
      return "";
    }
    let formattedUrl = url.replace("https://", "").replace("www.", "");
    //check if longer than 30 characters, and in case cut it
    if (formattedUrl.length > 30) {
      formattedUrl = formattedUrl.substring(0, 60) + "...";
    }
    return formattedUrl;
  }
  static stripMarkdownLines(
    input: string,
    keep_newlines: boolean = false,
    removeChapterTitles: boolean = false,
  ): string {
    // Regular expression patterns for markdown syntax
    const linePattern = /^(#{1,6} |[*]{2}|\*|_|~~|\[|<)/;
    const inlinePattern =
      /(\*\*|__)(.*?)\1|(\*|_)(.*?)\3|~~(.*?)~~|\[(.*?)\]\((.*?)\)/g;

    // Separate the input into lines
    const lines = input.split("\n");

    // Filter out lines that start with markdown syntax
    const filteredLines = lines.filter((line) => !linePattern.test(line));

    // Remove inline markdown from remaining lines
    const plainTextLines = filteredLines.map((line) =>
      line.replace(inlinePattern, "$2$4$5$6"),
    );

    // Join the remaining lines back into a single string and trim white space
    let divider = " ";
    if (keep_newlines) {
      divider = "<br/>";
    }
    let output = plainTextLines.join(divider);
    output = output.trim();

    if (removeChapterTitles) {
      //remove all of the lines that start with # or ## or ###
      const lines = output.split("\n");
      const filteredLines = lines.filter((line) => !line.startsWith("#"));
      output = filteredLines.join("\n");
      output.trim();
      output.trim();
    }
    //check if the output starts or ends with \n and in case remove it
    while (output.startsWith("<br/>")) {
      output = output.substring(5);
    }
    while (output.endsWith("<br/>")) {
      output = output.substring(0, output.length - 5);
    }

    return output;
  }

  static stripHtml(input: string): string {
    // Regular expression pattern to match HTML tags
    const htmlTagPattern = /<[^>]*>/g;
    // Replace HTML tags with an empty string
    const plainText = input.replace(htmlTagPattern, "");
    return plainText;
  }
  static timeAgo(date: Date): string {
    const now = new Date();
    const seconds = Math.round((now.getTime() - date.getTime()) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      // You can format this as you prefer using date methods or libraries like "date-fns" or "moment"
      return date.toLocaleDateString();
    }
  }

  static getDaySuffix(day: number): string {
    switch (day) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  static formatDate(date: Date): string {
    // 15/03/2021 -> Thursday 15th March 2021
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const monthName = TextFrontend.fromMonthNumberToMonthName(month);
    const daySuffix = TextFrontend.getDaySuffix(day);
    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
    return `${dayName} ${day}${daySuffix} ${monthName} ${year}`;
  }

  static fromMonthNumberToMonthName(monthNumber: number | string): string {
    if (typeof monthNumber === "string") {
      monthNumber = parseInt(monthNumber);
    }

    switch (monthNumber) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";

      case 6:
        return "June";

      case 7:
        return "July";

      case 8:
        return "August";

      case 9:
        return "September";

      case 10:
        return "October";

      case 11:
        return "November";

      case 12:
        return "December";

      default:
        return "January";
    }
  }

  static formatNumber(n: number): string {
    if (n > 1000000) {
      return (n / 1000000).toFixed(1) + "m";
    }
    if (n > 1000) {
      return (n / 1000).toFixed(1) + "k";
    }
    return n.toFixed(0);
  }

  static fromCategoryNameToUrl(categoryName: string | null): string {
    if (!categoryName) {
      return "";
    }
    return categoryName.toLowerCase().replace(" ", "-");
  }

  static formatDuration = (seconds: number) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds % 60);
    return minutes + ":" + remainingSeconds.toString().padStart(2, "0");
  };

  static unescapeString(escapedString: string): string {
    return escapedString
      .replace(/\\n/g, "\n") // Unescape newlines
      .replace(/\\t/g, "\t") // Unescape tabs
      .replace(/\\r/g, "\r") // Unescape carriage returns
      .replace(/\\b/g, "\b") // Unescape backspaces
      .replace(/\\f/g, "\f") // Unescape formfeeds
      .replace(/\\'/g, "'") // Unescape single quotes
      .replace(/\\"/g, '"') // Unescape double quotes
      .replace(/\\\\/g, "\\"); // Unescape backslashes
  }
  static cleanText(
    input: string,
    removeQuotationMarks = false,
    removeSymbols = false,
    removeParenthesis = false,
    removeEncodingArtifacts = false,
    removeEmptyParagraphs = false,
  ) {
    // Remove leading 'b' and single quotes if present
    // input = input.replace(/^b'|'$/g, "");

    try {
      input = JSON.parse(`"${input}"`);
    } catch {}
    // Unescape escaped characters

    // Remove CDATA sections
    input = input.replace(/<!\[CDATA\[[\s\S]*?\]\]>/g, "");

    // Handle specific text replacements
    // input = input.replace(/<p><\/p>/g, "\n");
    // input = input.replace(/Continue reading\.\.\./g, "");
    // input = input.replace(/\n{4,}/g, "\n\n");
    // input = input.replace(/\n{3}/g, "\n\n");

    // remove scripts
    input = input.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      "",
    );

    if (removeEmptyParagraphs) {
      input = input.replace(/\\t/g, "\n");
      input = input.replace(/\\\\/g, "\n").replace(/\\n/g, "\n");
      input = input.replace(/\n{3,}/g, "\n\n");
    }

    if (removeEncodingArtifacts) {
      input = input.replace(/\\x[0-9a-fA-F]{2}/g, "");
      input = input.replace(/\\\\/g, "").replace(/\\n/g, "");
    }

    if (removeQuotationMarks) {
      input = input.replace(/"/g, "");
      input = input.replace(/^'|'$/g, "");
    }

    if (removeParenthesis) {
      input = input.replace(/[\(\[].*?[\)\]]/g, "");
    }

    if (removeSymbols) {
      input = input.replace(/[^a-zA-Z0-9\s]/g, "");
    }

    // Final cleanup
    input = input.replace(/\s+/g, " ").trim();

    return input;
  }
}
