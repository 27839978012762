import { Heading, Subheading } from "@/components/design-system/heading";
import yaml from "js-yaml";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkFrontmatter from "remark-frontmatter";
import remarkGfm from "remark-gfm";
import Tooltip from "./Tooltip";

const MarkdownRenderer = ({ text }: { text: string }) => {
  const yamlToHtmlTable = () => (tree: any) => {
    const yamlNode = tree.children.find((node: any) => node.type === "yaml");
    if (yamlNode) {
      const metadata = yaml.load(yamlNode.value);
      if (
        metadata &&
        typeof metadata === "object" &&
        !Array.isArray(metadata)
      ) {
        const tableRows = Object.entries(metadata as Record<string, unknown>)
          .map(
            ([key, value]) =>
              `<tr><td>${key}</td><td>${JSON.stringify(value)}</td></tr>`,
          )
          .join("");

        const htmlTable = `
        <table class="min-w-full border-collapse my-4">
          <thead class="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th class="px-4 py-2 text-left font-semibold dark:text-white">Metadata</th>
              <th class="px-4 py-2 text-left font-semibold dark:text-white">Value</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      `;

        tree.children.unshift({
          type: "html",
          value: htmlTable,
        });
      }
    }
  };

  return (
    <Markdown
      rehypePlugins={[rehypeRaw, remarkGfm]}
      remarkPlugins={[yamlToHtmlTable, remarkGfm, remarkFrontmatter]}
      components={{
        a: ({ ...props }) => (
          <a
            {...props}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline mx-1"
          />
        ),
        h1: ({ ...props }) => <Heading {...props} className="my-2" level={1} />,
        h2: ({ ...props }) => <Heading {...props} className="my-2" level={2} />,
        h3: ({ ...props }) => (
          <Subheading {...props} className="my-2" level={3} />
        ),
        ul: ({ ...props }) => <ul {...props} className="list-disc ml-5" />,
        li: ({ ...props }) => (
          <li {...props} className="mb-2 dark:text-white" />
        ),
        ol: ({ ...props }) => <ol {...props} className="list-decimal ml-5" />,
        p: ({ ...props }) => <p {...props} className="my-2 dark:text-white" />,
        table: ({ ...props }) => (
          <table {...props} className="min-w-full border-collapse my-4" />
        ),
        thead: ({ ...props }) => (
          <thead {...props} className="bg-gray-100 dark:bg-gray-700" />
        ),
        tbody: ({ ...props }) => <tbody {...props} />,
        tr: ({ ...props }) => (
          <tr {...props} className="border-b dark:border-gray-600" />
        ),
        th: ({ ...props }) => (
          <th
            {...props}
            className="px-4 py-2 text-left font-semibold dark:text-white"
          />
        ),
        td: ({ ...props }) => (
          <td {...props} className="px-4 py-2 dark:text-white" />
        ),
        em: ({ ...props }) => (
          <em {...props} className="font-italic dark:text-white" />
        ),
        img: ({ ...props }) => (
          <Tooltip content={props.alt ?? ""}>
            <img
              {...props}
              className="max-w-full my-4 max-h-32 mx-auto rounded-lg"
              style={{ maxWidth: "100%", height: "auto" }}
              onError={(e) => {
                e.currentTarget.src = "/image_placeholder.svg";
                e.currentTarget.alt = props.alt ?? "";
              }}
            />
          </Tooltip>
        ),
      }}
    >
      {text.toString()}
    </Markdown>
  );
};

export default MarkdownRenderer;
