// import { Task, Message } from "@/utils/types";
import ConnectSlackButton from "@/components/ConnectSlackButton";
import { Button } from "@/components/design-system/button";
import { Switch } from "@/components/design-system/switch";
import Tooltip from "@/components/styled/Tooltip";
import { Agent } from "@/utils/types";
import {
  RiCalendarScheduleLine,
  RiMicrosoftLine,
  RiPlayLine,
  RiRocket2Fill,
  RiTeamLine,
} from "react-icons/ri";
import Lottie from "react-lottie-player";
import successAnimation from "../../lottie/success.json";
export default function LaunchStep({
  agent,
  showSuccessAnimationToggle,
  onAgentActivate,
  onAdvancedSettingsClick,
  onNewConversation,
}: {
  agent: Agent;
  showSuccessAnimationToggle: boolean;
  onAgentActivate: () => void;
  onAdvancedSettingsClick: () => void;
  onNewConversation: () => void;
}) {
  return (
    <>
      {!agent.active &&
        !showSuccessAnimationToggle &&
        agent.setup_completed && (
          <>
            <p className="text-sm">Everything is ready!</p>
            <Button
              variant="primary"
              className="mt-4"
              onClick={onAgentActivate}
            >
              <RiRocket2Fill />
              Activate Agent
            </Button>
          </>
        )}
      {!agent.active &&
        !showSuccessAnimationToggle &&
        !agent.setup_completed && (
          <>
            <p className="text-sm">
              Complete the steps above to activate your agent.
            </p>
            <Button
              variant="primary"
              disabled={true}
              className="mt-4"
              onClick={onAgentActivate}
            >
              <RiRocket2Fill />
              Activate Agent
            </Button>
          </>
        )}
      {agent.active && !showSuccessAnimationToggle && (
        <>
          <div className="flex items-center gap-2">
            <Switch
              name="activate_agent"
              checked={agent.active}
              onChange={onAgentActivate}
              disabled={!agent.setup_completed && !agent.active}
            />
            <p className="text-sm">
              {agent.active
                ? "Agent is active"
                : "Your agent is inactive and will not run if triggered"}
            </p>
          </div>
          <p className="text-xs mt-4">
            Your agent is active and you can start using it.
          </p>
        </>
      )}
      {showSuccessAnimationToggle && (
        <Lottie
          loop={false}
          animationData={successAnimation}
          play
          style={{ width: 250, height: 250 }}
        />
      )}
      {agent.active && !showSuccessAnimationToggle && (
        <div className="space-y-4 gap-4 mt-4">
          <Tooltip content="Exit the Edit Mode and start a new conversation">
            <Button
              variant="secondary"
              onClick={() => {
                onNewConversation();
              }}
            >
              <RiPlayLine /> Save and start using your Agent
            </Button>
          </Tooltip>
          <div className="mt-4" />
          <ConnectSlackButton />
          <Button variant="secondary" disabled>
            <RiMicrosoftLine />
            Connect Agent to MS Teams (coming soon)
          </Button>
          <Button href="/team" variant="secondary">
            <RiTeamLine />
            Share it with a teammate
          </Button>
          <p className="text-xs pt-4">Advanced Settings</p>
          <Button variant="secondary" onClick={onAdvancedSettingsClick}>
            <RiCalendarScheduleLine />
            Schedule it
          </Button>
        </div>
      )}
    </>
  );
}
