import { useEffect, useState } from "react";

type AnimatedTextProps = {
  text: string;
  onAnimationComplete?: () => void;
};

const AnimatedText = ({ text, onAnimationComplete }: AnimatedTextProps) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 100); // Adjust the delay (in milliseconds) between each letter

      return () => clearTimeout(timer);
    } else if (onAnimationComplete) {
      onAnimationComplete();
    }
    return;
  }, [currentIndex, text, onAnimationComplete]);

  return <span>{displayText}</span>;
};

export default AnimatedText;
