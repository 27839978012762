"use client";

import clsx from "clsx";
import type React from "react";
import { createContext, useContext } from "react";
import { Link } from "./link";

const GridTableContext = createContext<{
  bleed: boolean;
  dense: boolean;
  striped: boolean;
}>({
  bleed: false,
  dense: false,
  striped: false,
});

export function GridTable({
  bleed = false,
  dense = false,
  striped = false,
  className,
  children,
  ...props
}: {
  bleed?: boolean;
  dense?: boolean;
  striped?: boolean;
} & React.ComponentPropsWithoutRef<"div">) {
  return (
    <GridTableContext.Provider value={{ bleed, dense, striped }}>
      <div
        {...props}
        className={clsx(
          className,
          "grid gap-4",
          dense ? "p-2" : "p-4",
          !bleed && "sm:px-[--gutter]",
        )}
      >
        {children}
      </div>
    </GridTableContext.Provider>
  );
}

export function GridTableHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-4 text-zinc-500 dark:text-zinc-400 font-medium",
      )}
    />
  );
}

export function GridTableRow({
  href,
  target,
  title,
  className,
  ...props
}: {
  href?: string;
  target?: string;
  title?: string;
} & React.ComponentPropsWithoutRef<"div">) {
  let { striped } = useContext(GridTableContext);

  return (
    <div
      {...props}
      className={clsx(
        className,
        "grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-4",
        striped && "even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%]",
        href && "hover:bg-zinc-950/[2.5%] dark:hover:bg-white/[2.5%]",
      )}
    >
      {href && (
        <Link
          href={href}
          to={href}
          target={target}
          aria-label={title}
          className="absolute inset-0 focus:outline-none"
        />
      )}
      {props.children}
    </div>
  );
}

export function GridTableCell({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  let { dense } = useContext(GridTableContext);

  return (
    <div
      {...props}
      className={clsx(className, "relative", dense ? "py-2.5" : "py-4")}
    />
  );
}
