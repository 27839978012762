import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, Conversation, RequiredInput } from "@/utils/types";
import { useEffect, useRef, useState } from "react";
import { RiAttachmentLine } from "react-icons/ri";
import { Dialog, DialogActions, DialogBody } from "./design-system/dialog";
import Textarea from "./styled/Textarea";
import Tooltip from "./styled/Tooltip";

type RequiredInputsFormProps = {
  agent: Agent;
  conversation: Conversation;
  fields: RequiredInput[];
  formType: "conversation" | "run";
  isOpen: boolean;
  onClose: () => void;
};

const bytesToMegabytes = (bytes: number): string => {
  let megabytes = bytes / (1024 * 1024);
  if (megabytes < 1) {
    return `${(megabytes * 1000).toFixed(2)} kB`;
  } else {
    return `${megabytes.toFixed(2)} MB`;
  }
};

const RequiredInputsForm = ({
  agent,
  conversation,
  fields: defaultFields,
  formType,
  isOpen,
  onClose,
}: RequiredInputsFormProps) => {
  const [fields, setFields] = useState<RequiredInput[]>(defaultFields ?? []);
  const [saving, setSaving] = useState(false);
  const fileInputRefs = useRef<HTMLInputElement[]>([]);

  const updateRequiredInputAnswer = (id: string, value: string) => {
    setFields((prev) =>
      prev.map((input) => (input.id === id ? { ...input, value } : input)),
    );
  };
  const handleFileChange = (id: string, file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      alert("File size exceeds 5MB limit");
      return;
    }

    // Add check for empty files
    if (file.size === 0) {
      alert("Cannot upload empty files");
      return;
    }
    setFields((prev) =>
      prev.map((input) =>
        input.id === id ? { ...input, value: file } : input,
      ),
    );
  };

  const handleSave = async () => {
    setSaving(true);
    if (formType === "run") {
      await MarconipyApi.conversation.sendRunFormInputs(
        conversation.uuid,
        fields,
      );
    } else if (formType === "conversation") {
      await MarconipyApi.conversation.sendFormInputs(conversation.uuid, fields);
    }
    setTimeout(() => {
      setSaving(false);
      setFields(defaultFields);
    }, 1000);
    onClose();
  };

  useEffect(() => {
    if (formType === "run") {
      setFields((prev) => {
        return agent.required_inputs.map((input) => {
          const existingInput = prev?.find(
            (existingInput) => existingInput.id === input.id,
          );
          return {
            ...input,
            value: existingInput?.value || "",
          };
        });
      });
    } else if (formType === "conversation") {
      setFields((prev) => {
        return defaultFields.map((input) => {
          const existingInput = prev?.find(
            (existingInput) => existingInput.id === input.id,
          );
          return {
            ...input,
            value: existingInput?.value || "",
          };
        });
      });
    }
  }, [formType, agent.required_inputs, defaultFields]);

  const allInputsFilled = fields.every((input: RequiredInput) =>
    input.optional
      ? true
      : input.value &&
        (typeof input.value === "string"
          ? input.value.trim() !== ""
          : input.value instanceof File),
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogBody>
        <div className="flex flex-col gap-2 mt-4">
          {fields.map((input: RequiredInput, index: number) => (
            <div key={index} className="space-y-2 mb-4 pb-1">
              <Tooltip content={input.description}>
                <div className="flex gap-2 items-center">
                  <div className="text-lg font-semibold capitalize dark:text-white">
                    {input.key}
                  </div>
                  <div>{input.optional ? " (optional)" : ""}</div>
                </div>
              </Tooltip>
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleFileChange(input.id, file);
                }}
                accept=".doc,.docx,.pdf,.txt"
                className="hidden"
              />
              <div className="flex flex-col gap-2">
                {input.value instanceof File ? (
                  <div className="flex gap-2 items-center">
                    <div className="flex-1">
                      <div className="text-sm font-semibold dark:text-white">
                        {input.value.name}
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">
                        {bytesToMegabytes(input.value.size)}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <Button
                        onClick={() => {
                          fileInputRefs.current[index].click();
                        }}
                        variant="secondary"
                        className="flex gap-2 items-center"
                      >
                        <RiAttachmentLine />
                        <div className="text-xs">Change</div>
                      </Button>
                      <Button
                        onClick={() => updateRequiredInputAnswer(input.id, "")}
                        variant="secondary"
                        className="flex gap-2 items-center"
                      >
                        <div className="text-xs">Remove</div>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <Textarea
                      value={input.value as string}
                      onValueChange={(value) =>
                        updateRequiredInputAnswer(input.id, value)
                      }
                      placeholder={input.description}
                    />
                    <input
                      ref={(ref) => {
                        if (ref) {
                          fileInputRefs.current[index] = ref;
                        }
                      }}
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) handleFileChange(input.id, file);
                      }}
                      accept=".txt,.doc,.docx,.pdf"
                      className="hidden"
                      id="file-upload"
                    />
                    <div className="flex">
                      <Tooltip content="Attach a document (PDF, DOC, DOCX, TXT)">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            fileInputRefs.current[index]?.click();
                          }}
                        >
                          <RiAttachmentLine />
                        </Button>
                      </Tooltip>{" "}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </DialogBody>
      <DialogActions>
        <Button
          variant={"primary"}
          onClick={handleSave}
          disabled={saving || !allInputsFilled}
        >
          {saving ? "Sending..." : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequiredInputsForm;
