import { OutputTemplate } from "../types";
import BaseApi from "./base";

export default class OutputTemplateApi extends BaseApi {
  static async getAll(agentUUID: string): Promise<OutputTemplate[]> {
    return await this.authenticatedGetApiCall<OutputTemplate[]>(
      `/agents/${agentUUID}/templates/`,
    );
  }

  static async createOutputTemplate(
    agentUUID: string,
    template: any,
  ): Promise<OutputTemplate> {
    return await this.authenticatedPostApiCall<OutputTemplate>(
      `/agents/${agentUUID}/templates/`,
      { template: template },
    );
  }

  static async update(
    agentUUID: string,
    templateUUID: string,
    template: any,
  ): Promise<OutputTemplate> {
    return await this.authenticatedPatchApiCall<OutputTemplate>(
      `/agents/${agentUUID}/templates/${templateUUID}/`,
      { template: template },
    );
  }

  static async deleteOutputTemplate(
    agentUUID: string,
    templateUUID: string,
  ): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/agents/${agentUUID}/templates/${templateUUID}/`,
    );
  }
}
