import { TextareaHTMLAttributes } from "react";

export default function Textarea({
  value,
  onValueChange,
  ...props
}: {
  value: string;
  onValueChange: (value: string) => void;
} & TextareaHTMLAttributes<HTMLTextAreaElement>) {
  return (
    <textarea
      rows={4}
      name="comment"
      id="comment"
      className="p-2 block w-full rounded-lg border py-1.5 text-gray-900 ring-0 ring-inset ring-gray placeholder:text-gray focus:ring-0 focus:ring-inset sm:text-sm sm:leading-6 dark:bg-gray-950 dark:text-white dark:outline-0"
      defaultValue={value}
      onChange={(e) => onValueChange(e.target.value)}
      {...props}
    />
  );
}
