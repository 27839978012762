import { useAuth } from "@/contexts/AuthenticationContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Button } from "./design-system/button";
import { Navbar } from "./design-system/navbar";
import Logo from "./styled/Logo";

type Variant = "login" | "signup" | "made-with";

type NavigationBarUnloggedProps = {
  variant: Variant;
};

const NavigationBarUnlogged = ({ variant }: NavigationBarUnloggedProps) => {
  const { isAuth } = useAuth();
  return (
    <div className="mx-8">
      <Navbar className="w-full items-center justify-between">
        <Link to="https://www.tailortask.ai">
          <div
            className={classNames({
              "flex items-center rounded-lg p-2": variant == "made-with",
            })}
          >
            {/* {variant == "made-with" && (
              <p className="text-sm text-gray-500">Made with</p>
            )} */}
            <Logo variant="full" className="h-8 cursor-pointer dark:brightness-0 dark:invert hover:opacity-80" />
          </div>
        </Link>
        <div className="flex flex-row gap-4 flex-grow justify-end">
          {isAuth && (
            <>
              <Link to="/">
                <Button variant="secondary">Back to Home</Button>
              </Link>
            </>
          )}
          {!isAuth && (
            <>
              <Link to="/login">
                <Button variant={variant == "login" ? "primary" : "secondary"}>
                  Login
                </Button>
              </Link>
              <Link to="/signup">
                <Button variant={variant == "login" ? "secondary" : "primary"}>
                  Sign up
                </Button>
              </Link>
            </>
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default NavigationBarUnlogged;
