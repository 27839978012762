//types.tsx - the frontend react types definition

export interface ApiError {
  json: object;
  noConnection?: boolean;
}
export type BaseItem = {
  uuid: string;
  name: string;
};

export type Suggestion = BaseItem;

export type PublicUser = {
  first_name: string;
  last_name: string | undefined;
  full_name: string;
  profile_picture: string;
};

export type User = PublicUser & {
  uuid: string;
  email: string;
  profile_picture: string;
  first_name: string;
  last_name: string | undefined;
  waitlist?: boolean;
  banned?: boolean;
  client_compatible_version?: string;
  onboarded: boolean;
};

export type TeamMember = {
  user: User;
  owner: boolean;
};
export type TeamInvite = {
  invitee: User;
  invited_email: string;
  sent_last: string;
};

export type Team = {
  uuid: string;
  name: string;
  users: TeamMember[];
  organization: string;
  owner: string;
  created_at: string;
};

export type Organization = {
  uuid: string;
  name: string;
  teams: Team[];
  runs_today: number;
};

export type NotificationSettings = {
  uuid: string;
  email_notification: boolean;
  notify_on_marketing: boolean;
};

export type UserWithStatus = User & {
  user_status: boolean;
  is_staff: boolean;
  waitlist: boolean;
  waitlist_position: number;
  banned: boolean;
  onboarded: boolean;
};

export type SourceSet = BaseItem & {
  description: string;
  type: string;
  rss_url: string;
  category: string;
  image: string | null;
  sourceitems?: SourceItem[];
};

export type GenericObjectToolUserInput = {
  type: "object";
  inputs: {
    [key: string]: BaseToolUserInput;
  };
};

export type BaseToolUserInput = {
  type: string;
  description: string;
  required: boolean;
  min_count?: number;
};

export type ToolUserInput = BaseToolUserInput | GenericObjectToolUserInput;

export type Tool = {
  key: string;
  name: string;
  public_description: string;
  public_instructions: string;
  user_inputs: { [key: string]: ToolUserInput };
  inputs_are_valid: boolean;
  validation_message?: string;
  async_response: boolean;
};

export type SourceItem = {
  uuid: string;
  title: string;
  link: string;
  published_at: Date;
  sourceset: SourceSet | null;
  type: string;
  image_url?: string;
  excerpt?: string;
};

export type Topic = {
  uuid: string;
  name: string;
  metadata: {
    add_suggested_sourcesets_per_topic_status: {
      status: "unstarted" | "running" | "completed" | "failed";
      types: string[];
    };
  };
};

export type ImageSet = {
  uuid: string;
  res_full: string;
  res_400: string;
  res_300: string;
  res_200: string;
  res_100: string;
  res_50: string;
  source: string;
};

export type Trigger = {
  uuid: string;
  trigger_type: string;
  active: boolean;
  schedule_type: string;
  execution_time: string;
  day_of_week?: number;
  day_of_month?: number;
  metadata: any;
};

export type OutputTemplate = {
  uuid: string;
  name: string;
  template: any;
};

export type OutputChannel = {
  uuid: string;
  channel_type: string;
  active: boolean;
  template_uuid?: string;
  metadata: any;
};

export type SlackChannel = {
  id: string;
  name: string;
};

export type AgentState = {
  name:
    | "unstarted"
    | "pending"
    | "running"
    | "waiting_for_input"
    | "run_waiting_for_input"
    | "waiting_for_required_input"
    | "waiting_for_ui_input"
    | "tool_in_progress"
    | "generating_response"
    | "sending_to_outputs"
    | "needs_customer_support"
    | "completed"
    | "failed";
  tool: string | null;
  reason: string | null;
  moved_into_state: Date;
  exit_state: Date | null;
};

export type Plan = {
  uuid: string;
  organization: string;
  type: string;
};

export type PlanTypeDetails = {
  id: string;
  run_limit: number;
  subjective_run_limit_left?: number;
};

export type AgentMetadata = {
  steps_status: {
    context: string;
    tools: string;
    test: string;
    output: string;
    triggers: string;
  };
  brief_confirmed: string;
  tools_confirmed: string;
  tools_input_valid: string;
  test_confirmed: string;
  output_confirmed: string;
  triggers_confirmed: string;
  template_instructions?: Record<string, string>;
  from_template?: boolean;
  template_uuid?: string;
  template_name?: string;
};

export type Spreadsheet = {
  uuid: string;
  spreadsheet_id: string;
  name: string;
  metadata: Record<string, never>;
};

export type SourceSetArtefactInput = {
  object: SourceSet;
  tool_key: string;
  metadata: SourceSetInputMetadata;
};

export type TopicArtefactInput = {
  object: Topic;
  tool_key: string;
  metadata: TopicInputMetadata;
};

export type SpreadsheetArtefactInput = {
  object: Spreadsheet;
  tool_key: string;
  metadata: Record<string, never>;
};

export type GenericObjectInput = {
  object: Record<string, any>;
  tool_key: string;
  metadata: Record<string, never>;
};

export type TextDocument = {
  uuid: string;
  name: string;
  excerpt: string;
  uploaded_at: string;
};

export type TextDocumentInput = {
  object: TextDocument;
  tool_key: string;
  metadata: Record<string, never>;
};

export type ArtefactInputsMap = {
  sourceset?: SourceSetArtefactInput[];
  topic?: TopicArtefactInput[];
  spreadsheet?: SpreadsheetArtefactInput[];
  genericobject?: GenericObjectInput[];
  textdocument?: TextDocumentInput[];
};

export type Artefact = {
  uuid: string;
  type: string;
  content: any;
  inputs: ArtefactInputsMap;
  created_at: Date;
  updated_at: Date;
  metadata: Record<string, never>;
};

export type SourceSetInputMetadata = {
  suggested: boolean;
  suggested_from_topic_uuid?: string;
};

export type TopicInputMetadata = {
  uuid: string;
  suggest_sourcesets: boolean;
  suggest_sourcesets_types: string[];
};

export type RequiredInput = {
  id: string;
  key: string;
  description: string;
  optional: boolean;
  value?: string | File;
};

export type Agent = {
  uuid: string;
  name: string;
  team: string;
  steps: any;
  context: any;
  artefact: Artefact;
  deleted: boolean;
  active: boolean;
  metadata: AgentMetadata;
  required_inputs: RequiredInput[];
  created_at: Date;
  updated_at: Date;
  selected_tools_for_run: string[];
  selected_tools_for_self_reflection: string[];
  setup_completed: boolean;
  icon: string;
};

export type Conversation = {
  uuid: string;
  state: AgentState;
  created_at: Date;
  updated_at: Date;
  edit_mode: boolean;
  agent: string;
  last_message_text: string;
};

export type MessageQuickReply = {
  type: string;
  action: string;
};

export type MessageContent = {
  type: string;
  name?: string;
  input?: Record<string, any>;
  id?: string;
  tool_use_id?: string;
  text?: string;
  content?: string;
  quick_replies?: MessageQuickReply[];
};

export type Message = {
  uuid: string;
  content: MessageContent[] | string;
  role: string;
  timestamp: Date;
  localized_timestamp: Date;
  pagination_timestamp: number;
  conversation: string;
  run?: string;
  hide_from_ui?: boolean;
};

export type WebSocketMessage = {
  type:
    | "chat_message"
    | "agent_update"
    | "conversation_state_update"
    | "agent_name_update"
    | "open_setting_step";
  message: Message | AgentState | string;
};

export type Run = {
  uuid: string;
  conversation: Conversation;
  messages: Message[];
  type: string;
  completed_at: Date;
  updated_at: Date;
  created_at: Date;
  state: AgentState;
  artefact: Artefact;
  selected_tools: string[];
};

export type RightSidePanelOptions =
  | "settings"
  | "runs"
  | "help"
  | "details"
  | "artefact"
  | "none";

export enum EditMode {
  Topic = "topic",
  RSS = "rss",
  Social = "social",
  Wizard = "wizard",
  Upload = "upload",
}

export type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

export type BackgroundJob = {
  uuid: string;
  conversation: string;
  run: string;
  type: string;
  state: string;
  tool_key: string;
  tool_use_id: string;
  created_at: Date;
  updated_at: Date;
  metadata: any;
  total_time_estimate_seconds: number;
  subtask_time_estimate_seconds: number;
  total_subtask_count: number;
  completed_subtask_count: number;
};

export type ArtefactDocument =
  | {
      document: string;
      metadata: any;
    }
  | string;

export type AgentTemplate = {
  uuid: string;
  name: string;
  description: string;
  category: string;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
  agent: Agent;
  metadata: Record<string, any>;
};

export type ShareableArtefact = {
  uuid: string;
  content: ArtefactDocument[];
  title: string;
  team_name: string;
  agent_name: string;
  metadata: Record<string, any>;
};

export type APIToken = {
  uuid: string;
  token: string;
  created_at: Date;
  updated_at: Date;
  revoked_at: Date | null;
  team: string;
};
