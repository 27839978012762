import { Input } from "@/components/design-system/input";
import MarconipyApi from "@/utils/marconipyApi";
import {
  Agent,
  GenericObjectInput,
  GenericObjectToolUserInput,
} from "@/utils/types";
import { throttle } from "lodash";
import { useEffect, useRef, useState } from "react";

type GenericObjectInputPartialViewProps = {
  agent: Agent;
  tool_key: string;
  schema: GenericObjectToolUserInput;
  onRefresh?: () => void;
};

const GenericObjectInputPartialView = ({
  agent,
  tool_key,
  schema,
  onRefresh,
}: GenericObjectInputPartialViewProps) => {
  const [inputValues, setInputValues] = useState<{
    [key: string]: string;
  }>({});
  const throttledApiCall = useRef(
    throttle((values: { [key: string]: string }) => {
      MarconipyApi.input.add({
        agentUUID: agent.uuid,
        toolKey: tool_key,
        type: "genericobject",
        object: values,
      });
      setTimeout(() => {
        onRefresh && onRefresh();
      }, 1000);
    }, 1000),
  ).current;

  const handleChange = (key: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [key]: value }));
    throttledApiCall({ ...inputValues, [key]: value });
  };

  useEffect(() => {
    const currentObject = agent.artefact.inputs.genericobject?.find(
      (input: GenericObjectInput) => input.tool_key === tool_key,
    );
    if (currentObject?.object && Object.keys(inputValues).length === 0) {
      setInputValues(currentObject.object);
    }
  }, [agent, tool_key, inputValues]);
  return (
    <div className="flex flex-col gap-4">
      {Object.keys(schema.inputs).map((key: string) => (
        <div key={key} className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <p>
              {schema.inputs[key].description}
              {schema.inputs[key].required && (
                <span className="text-red-500 ml-1">*</span>
              )}
            </p>
          </div>
          <Input
            value={inputValues[key]}
            onChange={(e) => {
              handleChange(key, e.target.value);
            }}
            placeholder={schema.inputs[key].description}
          />
        </div>
      ))}
    </div>
  );
};
export default GenericObjectInputPartialView;
