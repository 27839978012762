import { APIToken } from "../types";
import BaseApi from "./base";

export default class ApiTokenApi extends BaseApi {
  static async getAll(teamUUID: string): Promise<APIToken[]> {
    return await this.authenticatedGetApiCall<APIToken[]>(
      `/teams/${teamUUID}/tokens/`,
    );
  }

  static async create(teamUUID: string): Promise<APIToken> {
    return await this.authenticatedPostApiCall<APIToken>(
      `/teams/${teamUUID}/tokens/`,
    );
  }
}
