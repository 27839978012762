import { IconsUtils } from "@/utils/Icons";
import classNames from "classnames";
import { useState } from "react";

const SourceSetImage = ({
  image,
  type,
  alt,
  size = "md",
}: {
  image: string | null;
  type?: string;
  alt?: string;
  size?: "xs" | "sm" | "md" | "lg";
}) => {
  const Icon = IconsUtils.fromSourceSetTypeToIcon(type ?? "unknown");
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div
      className={classNames(
        "rounded-full flex-shrink-0 flex items-center justify-center overflow-hidden border",
        {
          "bg-gray-100": image != null && image?.endsWith("png"),
          "h-4 w-4": size === "xs",
          "h-6 w-6": size === "sm",
          "h-8 w-8": size === "md",
          "h-12 w-12": size === "lg",
        },
      )}
      style={image && !imageError ? { backgroundImage: `url(${image})` } : {}}
    >
      {image != null && image != "default" && !imageError && (
        <img
          className="object-contain object-center"
          src={image}
          alt={alt}
          onError={handleImageError}
        />
      )}
      {(image === "default" || image == null || imageError) &&
        (type || imageError) && <Icon />}
    </div>
  );
};

export default SourceSetImage;
