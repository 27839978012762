import OutputTemplateComponent from "@/components/OutputTemplateComponent";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, OutputTemplate } from "@/utils/types";
import { useEffect, useState } from "react";

type OutputTemplatesProps = {
  agent: Agent;
};

const OutputTemplates = ({ agent }: OutputTemplatesProps) => {
  const [templates, setTemplates] = useState<OutputTemplate[]>([]);

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await MarconipyApi.outputTemplate.getAll(agent.uuid);
      setTemplates(templates);
    };

    loadTemplates();
  }, [agent.uuid]);

  return (
    <div className="">
      {templates.map((template) => (
        <OutputTemplateComponent
          key={template.uuid}
          agent={agent}
          template_uuid={template.uuid}
        />
      ))}
    </div>
  );
};

export default OutputTemplates;
