import { useEffect, useState } from "react";
import {
  RiArrowRightSLine,
  RiArticleLine,
  RiCalendarLine,
} from "react-icons/ri";
import { redirect, useLocation, useNavigate } from "react-router-dom";

import { Button } from "@/components/design-system/button";
import SkeletonElement from "@/components/SkeletonElement";
import Card from "@/components/styled/Card";
import Logo from "@/components/styled/Logo";
import MarconipyApi from "@/utils/marconipyApi";

export default function OAuthScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [appDetails, setAppDetails] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [authParams, setAuthParams] = useState<any>(null);
  const [connectingLogo, setConnectingLogo] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const clientId = urlParams.get("client_id");
    const responseType = urlParams.get("response_type");
    const redirectUri = urlParams.get("redirect_uri");
    const scope = urlParams.get("scope");
    const state = urlParams.get("state");

    if (clientId && responseType && redirectUri) {
      setAuthParams({ clientId, responseType, redirectUri, scope, state });
      fetchAppDetails(clientId, redirectUri, scope || "");
    } else {
      setError("Invalid authorization request");
    }
  }, [location]);

  const fetchAppDetails = async (
    clientId: string,
    redirectUri: string,
    scope: string,
  ) => {
    try {
      const response = await MarconipyApi.oauth.appDetails(
        clientId,
        redirectUri,
        scope,
      );
      setAppDetails(response);
    } catch (error) {
      console.error("Error fetching app details:", error);
      setError("Error fetching application details");
    }
  };

  const logoFromName = (name: string) => {
    if (name.toLowerCase().indexOf("zapier") > -1) {
      return "https://upload.wikimedia.org/wikipedia/commons/f/fd/Zapier_logo.svg";
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (appDetails) {
      setConnectingLogo(logoFromName(appDetails.name));
    }
  }, [appDetails]);

  const handleAuthorize = async () => {
    try {
      if (!authParams) {
        throw new Error("Authorization parameters are missing");
      }
      const response = await MarconipyApi.oauth.authorizeOAuth(
        authParams.clientId,
        authParams.redirectUri,
        authParams.scope || "",
      );
      if (response.code) {
        console.log(response);
        // Redirect to the app's redirect URI with the authorization code
        const redirectUrl = new URL(authParams.redirectUri);
        redirectUrl.searchParams.append("code", response.code);
        if (authParams.state) {
          redirectUrl.searchParams.append("state", authParams.state);
        }
        window.location.href = redirectUrl.toString();
      } else {
        throw new Error("No authorization code received");
      }
    } catch (error) {
      console.error("Error during authorization:", error);
      setError("Error during authorization");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!appDetails) {
    return <div>Loading...</div>;
  }
  // https://dribbble.com/shots/21267751-Settings-Integrations
  return (
    <div className="flex flex-col items-center justify-center p-4">
      <Card className="flex flex-col items-center justify-center p-4">
        <div className="text-center p-2 items-center">
          <div className="flex justify-center items-center my-4">
            <Logo variant="icon" className="text-primary w-16" />
            <RiArrowRightSLine className="text-2xl my-2 mx-8" />
            {connectingLogo && (
              <img
                src={connectingLogo}
                alt={appDetails.name}
                className="w-12 h-12 rounded-full"
              />
            )}
            {!connectingLogo && (
              <SkeletonElement
                width={"4rem"}
                height={"4rem"}
                cornerRadius={100}
              />
            )}
          </div>
          <p className="text-xl my-2">
            Connecting <strong>TailorTask</strong> to{" "}
            <strong>{appDetails.name}</strong>
          </p>
          <div className="flex flex-col">
            <p className="mb-6">
              {appDetails.description} is requesting permission to access your
              TailorTask team account.
            </p>
            <div className="space-y-4 mb-6">
              <div className="flex items-start space-x-4">
                <div className="bg-gray-200 p-2 rounded-full">
                  <RiCalendarLine className="text-2xl text-primary" />
                </div>
                <div className="text-left">
                  <p className="font-bold">Triggers</p>
                  <p className="text-sm text-gray-600">
                    Zapier will be able to trigger your TailorTask Agents
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="bg-gray-200 p-2 rounded-full">
                  <RiArticleLine className="text-2xl text-primary" />
                </div>
                <div className="text-left">
                  <p className="font-bold">Results</p>
                  <p className="text-sm text-gray-600">
                    Zapier will be able to get the results of your Agent runs
                    after they complete.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-2 flex justify-center items-center space-x-2">
              <Button variant="primary" onClick={handleAuthorize}>
                Authorize
              </Button>
              <Button onClick={() => navigate("/")} variant="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    const loggedIn = await MarconipyApi.auth.isAuthenticated();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    return null;
  } catch (e: any) {
    console.log("error, check if waitlist");
    console.log(e.response?.data);
    if (e.response?.data?.detail?.includes("waitlist")) {
      return redirect("/waitlist");
    }
    if (e.response?.data?.includes("Banned")) {
      return redirect("/banned");
    }
    throw e;
  }
}
