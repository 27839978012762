import { TextFrontend } from "@/utils/Text";
import { SourceItem } from "utils/types";
import SourceSetImage from "./SourceSetImage";

type SourceItemRowItemProps = {
  sourceitem: SourceItem;
  index?: number;
  clickable?: boolean;
};

const SourceItemRowItem = ({
  clickable = true,
  ...props
}: SourceItemRowItemProps) => {
  const children = (
    <div className="md:text-xs flex flex-col gap-3 overflow-hidden justify-between p-4 h-full">
      <p className="font-semibold text-sm md:text-base line-clamp-2">
        {props.sourceitem.title}
      </p>
      <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-3">
        {TextFrontend.stripHtml(props.sourceitem.excerpt as string)}{" "}
      </p>
      <div className="flex flex-row items-center gap-2">
        <SourceSetImage
          image={props.sourceitem.sourceset?.image ?? ""}
          size="sm"
        />
        <div className="text-xs">
          <span className="text-blue-600 dark:text-blue-400 underline">
            {TextFrontend.humanUrl(props.sourceitem.link)}
          </span>
        </div>
      </div>
    </div>
  );
  if (clickable) {
    return (
      <a
        href={props.sourceitem.link}
        target="_blank"
        rel="noreferrer"
        className="flex flex-col bg-white dark:bg-black-dark border border-gray-100 dark:border-gray-600 rounded-lg hover:shadow-md hover:border-gray-300 dark:hover:border-gray-700 transition duration-300 ease-in-out"
      >
        {children}
      </a>
    );
  } else {
    return children;
  }
};

export default SourceItemRowItem;
