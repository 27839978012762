import BaseApi from "./base";

export default class InputApi extends BaseApi {
  static async add(input: {
    agentUUID: string;
    toolKey: string;
    type: string;
    uuid?: string;
    object?: any;
    metadata?: {
      suggest_sourcesets_types?: string[];
    };
  }): Promise<void> {
    let params: Record<string, any> = {
      tool_key: input.toolKey,
      type: input.type,
    };
    if (input.uuid) {
      params["uuid"] = input.uuid;
    }
    if (input.object) {
      params["object"] = input.object;
    }
    if (input.metadata) {
      params["metadata"] = input.metadata;
    }
    await this.authenticatedPostApiCall<void>(
      `/agents/${input.agentUUID}/inputs/`,
      params,
    );
  }

  static async remove(
    agentUUID: string,
    uuid: string,
    toolKey: string,
  ): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/agents/${agentUUID}/inputs/${toolKey}/${uuid}/`,
    );
  }

  static async uploadFile(
    agentUUID: string,
    tool_key: string,
    formData: FormData,
  ): Promise<void> {
    return this.authenticatedFormPostApiCall(
      `/agents/${agentUUID}/inputs/file/?tool_key=` + tool_key,
      formData,
    );
  }
}
