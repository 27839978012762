import { NameConventions } from "@/utils/NameConventions";
import { Message } from "@/utils/types";
import React, { useEffect } from "react";
import {
  RiBook2Fill,
  RiCalendarLine,
  RiCheckFill,
  RiClipboardFill,
  RiCursorLine,
  RiEarthLine,
  RiErrorWarningFill,
  RiFile2Fill,
  RiFileChartFill,
  RiFileTextLine,
  RiInputField,
  RiLoader2Fill,
  RiMessage2Fill,
  RiPlayLine,
  RiQuestionnaireFill,
  RiRewindLine,
  RiRobot2Line,
  RiSendPlaneLine,
  RiTestTubeLine,
  RiThumbUpFill,
  RiToolsLine,
  RiWindowLine,
} from "react-icons/ri";
import Tooltip from "./styled/Tooltip";

export const toolToIcon: Record<string, React.ReactElement> = {
  add_scheduled_trigger: <RiCalendarLine />,
  ask_user_to_set_up_actions: <RiToolsLine />, // legacy
  ask_user_to_set_up_action: <RiToolsLine />,
  ask_user_to_fill_up_form: <RiInputField />,
  confirm_test: <RiTestTubeLine />,
  edit_brief: <RiFileTextLine />,
  add_required_input: <RiInputField />,
  new_document: <RiFileTextLine />,
  pause_task: <RiQuestionnaireFill />,
  read_document: <RiFileTextLine />,
  read_url: <RiWindowLine />,
  crawl_website: <RiWindowLine />,
  run_test_workflow: <RiTestTubeLine />, // legacy
  run_workflow: <RiPlayLine />, // legacy
  run_agent: <RiPlayLine />,
  select_actions: <RiToolsLine />,
  stop_task: <RiErrorWarningFill />,
  suggest_sources: <RiToolsLine />,
  summarize: <RiBook2Fill />,
  task_completed: <RiCheckFill />,
  web_search: <RiEarthLine />,
  read_spreadsheet: <RiFileChartFill />,
  write_to_spreadsheet: <RiFileChartFill />,
  get_past_execution_results: <RiRewindLine />,
  use_browser_with_goal: <RiCursorLine />,
  run_seo_analysis: <RiFile2Fill />,
  monitor_content_from_social: <RiThumbUpFill />,
  ongoing: <RiLoader2Fill className="animate-spin" />,
  send_to_clay: <RiSendPlaneLine />,
};

type MessageIconProps = {
  message: Message;
  isBrief?: boolean;
  isLastMessage?: boolean;
};

export function MessageIcon(props: MessageIconProps) {
  const [label, setLabel] = React.useState<string | undefined>(undefined);
  const [icon, setIcon] = React.useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    const getSourceIcon = () => {
      const content = props.message.content;
      if (props.isBrief) {
        setLabel("Brief");
        setIcon(<RiClipboardFill />);
        return;
      }
      if (Array.isArray(content)) {
        if (
          content.length == 1 &&
          content[0].type &&
          content[0].type == "text" &&
          props.message.role != "assistant"
        ) {
          setLabel("Message");
          setIcon(<RiMessage2Fill />);
          return;
        }
        for (const item of content) {
          if (item.type == "text") {
            continue;
          }
          if (item.type == "tool_use") {
            setLabel(item.name);
            // if (props.isLastMessage) {
            //   setIcon(<RiLoader2Fill className="animate-spin" />);
            //   continue;
            // }
            if (props.isLastMessage) {
              setIcon(<RiLoader2Fill className="animate-spin" />);
              break;
            } else {
              if (item.name && Object.keys(toolToIcon).includes(item.name)) {
                setIcon(toolToIcon[item.name]);
                break;
              } else {
                setIcon(<RiRobot2Line />);
                break;
              }
            }
          }
          if (item.type == "tool_result") {
            if (item.content) {
              setLabel(item.content);
            }
            setIcon(<RiCheckFill />);
          }
          if (
            item.type != "tool_result" &&
            item.type != "tool_use" &&
            item.type != "text"
          ) {
            setIcon(<RiErrorWarningFill />);
          }
        }
      } else {
        if (props.message.role != "assistant") {
          setLabel("Message");
          setIcon(<RiMessage2Fill />);
        }
      }
    };
    getSourceIcon();
  }, [
    props.message.uuid,
    props.isLastMessage,
    props.message.content,
    props.message.role,
    props.isBrief,
  ]);

  if (!icon || !label) {
    return null;
  }

  return (
    <div className="max-w-4 mr-2 message-icon">
      <Tooltip placement="top" content={NameConventions.toHuman(label)}>
        {icon}
      </Tooltip>
    </div>
  );
}
