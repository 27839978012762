import {
  Agent,
  AgentTemplate,
  Conversation,
  RequiredInput,
  Tool,
} from "../types";
import BaseApi from "./base";

interface NewAgentResponse {
  conversation: Conversation;
  agent: Agent;
}

export default class AgentApi extends BaseApi {
  static async getAgents(): Promise<Agent[]> {
    return await this.authenticatedGetApiCall<Agent[]>(`/agents/`);
  }
  static async get(agentUUID: string): Promise<Agent> {
    return await this.authenticatedGetApiCall<Agent>(`/agents/${agentUUID}/`);
  }
  static async delete(agentUUID: string): Promise<void> {
    await this.authenticatedDeleteApiCall(`/agents/${agentUUID}/`);
  }
  static async getAllByTeam(teamUUID: string): Promise<Agent[]> {
    return await this.authenticatedGetApiCall<Agent[]>(
      `/teams/${teamUUID}/agents/`,
    );
  }
  static async create(
    name?: string,
    teamUUID?: string,
  ): Promise<NewAgentResponse> {
    let obj: any = {};
    if (name) {
      obj["name"] = name;
    }
    if (teamUUID) {
      obj["teamUUID"] = teamUUID;
    }
    return await this.authenticatedPostApiCall<NewAgentResponse>(
      `/agents/`,
      obj,
    );
  }
  static async update(
    agentUUID: string,
    params: any,
    conversationUUID: string,
  ): Promise<Agent> {
    let obj: any = {
      agent: { ...params },
      conversation_uuid: conversationUUID,
    };
    return await this.authenticatedPatchApiCall<Agent>(
      `/agents/${agentUUID}/`,
      obj,
    );
  }
  static async restoreAgent(agentUUID: string): Promise<Agent> {
    return await this.authenticatedPatchApiCall<Agent>(
      `/agents/${agentUUID}/`,
      {
        deleted: false,
      },
    );
  }

  static async updateAgentContext(
    agentUUID: string,
    context: any,
    requiredInputs?: RequiredInput[],
    conversationUUID?: string,
  ): Promise<Agent> {
    let obj: any = {
      agent: {
        context: context,
      },
    };
    if (requiredInputs) {
      obj["agent"]["required_inputs"] = requiredInputs;
    }
    if (conversationUUID) {
      obj["conversation_uuid"] = conversationUUID;
    }
    return await this.authenticatedPatchApiCall<Agent>(
      `/agents/${agentUUID}/`,
      obj,
    );
  }

  static async getAgentTemplates(): Promise<AgentTemplate[]> {
    return await this.authenticatedGetApiCall<AgentTemplate[]>(
      `/agents/templates/`,
    );
  }
  static async clone(
    agentUUID: string,
    metadata: any,
  ): Promise<NewAgentResponse> {
    return await this.authenticatedPostApiCall<NewAgentResponse>(
      `/agents/${agentUUID}/clone/`,
      { metadata },
    );
  }

  static async getAvailableTools(conversationUUID: string): Promise<Tool[]> {
    return await this.authenticatedGetApiCall<Tool[]>(
      `/agents/${conversationUUID}/tools/`,
    );
  }
  static async getAllTools(conversationUUID: string): Promise<Tool[]> {
    return await this.authenticatedGetApiCall<Tool[]>(
      `/agents/${conversationUUID}/tools/?all=true`,
    );
  }
}
