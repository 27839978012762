import { Organization, Plan, Team, TeamInvite, TeamMember } from "../types";
import BaseApi from "./base";

interface TeamResponse {
  team: Team;
  organization: Organization;
  plan: Plan;
}

export default class TeamApi extends BaseApi {
  static async get(teamUUID: string): Promise<TeamResponse> {
    return await this.authenticatedGetApiCall<TeamResponse>(
      `/teams/${teamUUID}/`,
    );
  }

  static async default(): Promise<TeamResponse> {
    return await this.authenticatedGetApiCall<TeamResponse>(`/teams/default/`);
  }

  static async changeDefaultTeam(teamUUID: string): Promise<void> {
    //changes the default team to the one specified
    await this.authenticatedPostApiCall(`/teams/default/`, {
      team_uuid: teamUUID,
    });
  }

  static async getTeamMembers(teamUUID: string): Promise<TeamMember[]> {
    return await this.authenticatedGetApiCall<TeamMember[]>(
      `/teams/${teamUUID}/members/`,
    );
  }
  static async updateName(teamUuid: string, newName: string): Promise<Team> {
    return await this.authenticatedPatchApiCall<Team>(`/teams/${teamUuid}/`, {
      name: newName,
    });
  }

  static async create(): Promise<Team> {
    return await this.authenticatedPostApiCall<Team>(`/teams/`, {});
  }
  static async delete(teamUUID: string): Promise<void> {
    await this.authenticatedDeleteApiCall(`/teams/${teamUUID}/`);
  }

  static async removeTeamMember(
    teamUUID: string,
    memberUUID: string,
  ): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/teams/${teamUUID}/members/${memberUUID}/`,
    );
  }

  static async leave(teamUUID: string, memberUUID: string): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/teams/${teamUUID}/members/${memberUUID}/leave`,
    );
  }
  static async getAll(): Promise<Team[]> {
    return await this.authenticatedGetApiCall<Team[]>(`/teams/`);
  }

  static async getOrganization(
    organization_id: string | null,
  ): Promise<Organization> {
    return await this.authenticatedGetApiCall<Organization>(
      `/organizations/${organization_id}/`,
    );
  }

  static async getAllTeamInvites(teamUUID: string): Promise<TeamInvite[]> {
    return await this.authenticatedGetApiCall<TeamInvite[]>(
      `/teams/${teamUUID}/invites/`,
    );
  }

  static async inviteTeamMember(
    teamUUID: string,
    email: string,
  ): Promise<TeamInvite> {
    return await this.authenticatedPostApiCall<TeamInvite>(
      `/teams/${teamUUID}/invites/`,
      {
        email: email,
      },
    );
  }

  static async getInvite(inviteUUID: string): Promise<TeamInvite> {
    return await this.authenticatedGetApiCall<TeamInvite>(
      `/invites/${inviteUUID}/`,
    );
  }
}
