import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { useEffect, useState } from "react";
import { RiLoader2Fill } from "react-icons/ri";

type SignInWithGoogleProps = {
  redirectUrl?: string;
  provider?: "google" | "google/drive";
};

const SignInWithGoogle = (props: SignInWithGoogleProps = {}) => {
  const [googleURL, setGoogleURL] = useState<string | null>(null);

  useEffect(() => {
    const loadGoogleLoginURL = async () => {
      try {
        const url = (await MarconipyApi.auth.getSocialProviderLoginURL(
          props.provider || "google",
        )) as any;
        setGoogleURL(url["url"]);
      } catch (error: any) {
        if (error.code == "ERR_NETWORK") {
          setRedirectAfterError(true);
        } else {
          throw error;
        }
      }
    };
    if (props.redirectUrl) {
      localStorage.setItem("redirectUrl", props.redirectUrl || "");
    }
    loadGoogleLoginURL();
  }, [props.provider, props.redirectUrl]);

  const [redirectAfterError, setRedirectAfterError] = useState(false);

  return (
    <>
      {redirectAfterError && (
        <p className="text-black dark:text-white text-center">
          No connection! Try again later
        </p>
      )}
      {!redirectAfterError && (
        <div className="w-full flex items-center justify-center">
          <Button
            disabled={googleURL == null}
            href={googleURL || ""}
            variant="plain"
          >
            {googleURL != null ? (
              // <RiGalleryLine />
              <img src="/signinwithgoogle.svg" alt="Sign in with Google" />
            ) : (
              <RiLoader2Fill />
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default SignInWithGoogle;
