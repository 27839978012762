import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useState } from "react";
// import { Task, Message } from "@/utils/types";
import { AgentNavigationBar } from "@/components/AgentNavigationBar";
import ChatComponent from "@/components/ChatComponent";
import { ContextComponent } from "@/components/ContextComponent";
import { Button } from "@/components/design-system/button";
import { Loading } from "@/components/Loading";
import RequiredInputsForm from "@/components/RequiredInputsForm";
import RightSidePanel from "@/components/RightSidePanel";
import SkeletonElementPreset from "@/components/SkeletonElementPreset";
import { useAgents } from "@/contexts/AgentsContext";
import { useTeams } from "@/contexts/TeamContext";
import useChat from "@/hooks/useChat";
import useTools from "@/hooks/useTools";
import {
  AgentState,
  RequiredInput,
  RightSidePanelOptions,
  WebSocketMessage,
} from "@/utils/types";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import {
  RiEditLine,
  RiFileTextLine,
  RiSendPlaneLine,
  RiSettings3Line,
  RiToolsLine,
} from "react-icons/ri";
import { toast } from "react-toastify";
import AdvancedStep from "./partials/AdvancedStep";
import LaunchStep from "./partials/LaunchStep";
import ToolsMainPanel from "./partials/ToolsMainPanel";
import ToolsPartialView from "./partials/ToolsPartialView";

type SettingStep = {
  id: string;
  title: string;
  tooltip: string;
  icon: React.ReactNode;
  isCompleted: boolean;
};

export default function AgentView() {
  const posthog = usePostHog();
  const [activeStep, setActiveStep] = useState<string | null>(null);
  const {
    agent,
    reloadCurrentAgent,
    updateCurrentAgent,
    currentConversation,
    navigateToEditMode,
    newConversation,
  } = useAgents();
  const [sidePanelActiveTab, setSidePanelActiveTab] =
    useState<RightSidePanelOptions>(agent?.active ? "settings" : "none");
  const [showSuccessAnimationToggle, setShowSuccessAnimationToggle] =
    useState(false);
  const [newAnimatedAgentName, setNewAnimatedAgentName] = useState<
    string | undefined
  >(undefined);
  const [isRequiredInputsFormOpen, setRequiredInputsFormOpen] = useState(false);
  const [formFields, setFormFields] = useState<RequiredInput[]>([]);
  const [formType, setFormType] = useState<"conversation" | "run">(
    "conversation",
  );
  const { showUpgradeDialog } = useTeams();

  const [agentState, setAgentState] = useState<AgentState>(
    currentConversation?.state || {
      name: "running",
      tool: null,
      reason: "launch",
      moved_into_state: new Date(),
      exit_state: null,
    },
  );

  const handleActivateAgent = useCallback(async () => {
    if (!agent) {
      return;
    }
    await updateCurrentAgent(agent, {
      active: !agent.active,
    });
    await reloadCurrentAgent();
    posthog.capture("agentEdit: agent_activated", {
      active: !agent.active,
      source: "agentview",
    });
    if (!agent.active) {
      showSuccessAnimation();
    }
  }, [posthog, reloadCurrentAgent, updateCurrentAgent, agent]);

  const showSuccessAnimation = () => {
    setShowSuccessAnimationToggle(true);
    setTimeout(() => {
      setShowSuccessAnimationToggle(false);
    }, 3000);
  };
  const switchSidePanelTab = useCallback(
    (tab: RightSidePanelOptions) => {
      posthog.capture("agentView: switchSidePanelTab", { tab: tab });
      setSidePanelActiveTab(tab);
    },
    [posthog],
  );
  const toggleStep = useCallback(
    (id: string, automatic: boolean = false) => {
      posthog.capture("agentView: toggleStep", {
        step_id: id,
        automatic: automatic,
      });
      switchSidePanelTab("settings");
      setActiveStep((prev) => (prev === id ? null : id));
    },
    [posthog, switchSidePanelTab],
  );
  const steps: SettingStep[] = useMemo(() => {
    return [
      {
        id: "brief",
        title: "Brief",
        tooltip: "The brief that your agent will follow to complete the task",
        icon: <RiFileTextLine className="w-5 h-5" />,
        isCompleted: agent
          ? agent.metadata.steps_status.context == "completed"
          : false,
      },
      {
        id: "tools",
        title: "Actions",
        tooltip:
          "The actions that your agent will have at its disposal to complete your task",
        icon: <RiToolsLine className="w-5 h-5" />,
        isCompleted: agent
          ? agent.metadata.steps_status.tools == "completed"
          : false,
      },
      {
        id: "confirm",
        title: "Activate your Agent",
        tooltip: "Activate your Agent now",
        icon: <RiSendPlaneLine className="w-5 h-5" />,
        isCompleted: agent ? agent.active : false,
      },
      {
        id: "advanced",
        title: "Advanced Settings",
        tooltip: "Advanced settings for your Agent",
        icon: <RiSettings3Line className="w-5 h-5" />,
        isCompleted: false, // this step doesn't require completion
      },
    ];
  }, [agent]);

  const switchToNextPendingStep = useCallback(
    async (justConfirmed: string) => {
      if (sidePanelActiveTab != "none") {
        setTimeout(() => {
          const nextStep = steps.find(
            (step) => !step.isCompleted && step.id != justConfirmed,
          );
          if (nextStep) {
            // console.log("Switching to next step", nextStep.id);
            toggleStep(nextStep.id);
          }
        }, 400);
      }
    },
    [sidePanelActiveTab, steps, toggleStep],
  );

  const {
    isLoading: isLoadingTools,
    currentTool,
    currentToolIndex,
    tools,
    selectedTools,
    handleToolSelection,
    openSetupModalAtTool,
    isToolSetupDialogOpen,
    closeToolSetupDialog,
    allToolsRequiringUserInputs,
    toolsThatNeedSetup,
    isStepCompleted: isToolStepCompleted,
    handleConfirmTools,
    navigateTools,
    isSaving: isSavingTools,
    error: toolError,
  } = useTools({ agent, onToolsConfirmed: switchToNextPendingStep });

  const startRun = useCallback(async () => {
    if (!currentConversation) {
      return;
    }
    posthog.capture("start run", {
      source: "agentview",
    });
    try {
      await MarconipyApi.run.start(currentConversation.uuid);
    } catch (e: any) {
      //check if you got a 402 error and in case show a toast
      if (e.response && e.response.status == 402) {
        posthog.capture("billing limit reached", {
          source: "agentview",
        });
        showUpgradeDialog();
      } else {
        posthog.capture("error starting run", {
          source: "agentview",
        });
        toast.error("Error starting run: " + e.message);
      }
    }
  }, [currentConversation, posthog, showUpgradeDialog]);

  const stepsToContent: Record<string, React.ReactNode | null> = useMemo(() => {
    return {
      brief: agent && <ContextComponent agent={agent} />,
      tools: agent && (
        <ToolsPartialView
          isLoading={isLoadingTools}
          tools={tools}
          selectedTools={selectedTools}
          handleToolSelection={handleToolSelection}
          openSetupModalAtTool={openSetupModalAtTool}
          toolsThatNeedSetup={toolsThatNeedSetup}
          isStepCompleted={isToolStepCompleted}
          handleConfirmTools={handleConfirmTools}
          isSaving={isSavingTools}
          error={toolError}
        />
      ),
      confirm: agent && (
        <LaunchStep
          agent={agent}
          showSuccessAnimationToggle={showSuccessAnimationToggle}
          onAgentActivate={handleActivateAgent}
          onAdvancedSettingsClick={() => setActiveStep("advanced")}
          onNewConversation={() => newConversation(agent)}
        />
      ),
      advanced: agent && <AdvancedStep agent={agent} />,
    };
  }, [
    agent,
    isLoadingTools,
    tools,
    selectedTools,
    handleToolSelection,
    openSetupModalAtTool,
    toolsThatNeedSetup,
    isToolStepCompleted,
    handleConfirmTools,
    isSavingTools,
    toolError,
    showSuccessAnimationToggle,
    handleActivateAgent,
    newConversation,
  ]);

  const handleImportantMessage = useCallback(
    (message: WebSocketMessage) => {
      if (message.type == "agent_name_update") {
        setNewAnimatedAgentName(message.message as string);
      }
      if (message.type == "open_setting_step") {
        const step_id = message.message as string;
        const step = steps.find((step) => step.id == step_id);
        if (step) {
          toggleStep(step.id, true);
        }
      }
    },
    [steps, toggleStep],
  );
  const {
    isConnected,
    messages,
    sendMessage,
    isLoading: isLoadingMessages,
    loadMessages,
    chatRef,
  } = useChat({
    pageTitle: agent ? `${agent?.name} - TailorTask` : "TailorTask",
    conversationUUID: currentConversation?.uuid,
    onAgentRefresh: reloadCurrentAgent,
    onImportantMessage: handleImportantMessage,
    onNewAgentState: (state: AgentState) => {
      setAgentState(state);
    },
  });

  useEffect(() => {
    if (currentConversation) {
      setAgentState(currentConversation.state);
    }
  }, [currentConversation]);

  useEffect(() => {
    setNewAnimatedAgentName(agent?.name);
  }, [agent?.name]);

  const handleQuickReplyAction = useCallback(
    async (action: string) => {
      if (!currentConversation) {
        return;
      }
      if (action.startsWith("open_tool:")) {
        const toolToOpen = action.replace("open_tool:", "");
        const tool = tools.find((t) => t.key == toolToOpen);
        openSetupModalAtTool(tool);
        return;
      }
      if (action.startsWith("skip_tool:")) {
        const toolToSkip = action.replace("skip_tool:", "");
        const tool = tools.find((t) => t.key == toolToSkip);
        if (tool) {
          handleToolSelection(tool.key);
          MarconipyApi.conversation.skipTool(
            currentConversation?.uuid,
            tool.key,
          );
        }
        return;
      }
      switch (action) {
        case "open_tool_settings":
          setSidePanelActiveTab("settings");
          setActiveStep("tools");
          break;
        case "open_brief_settings":
          setSidePanelActiveTab("settings");
          setActiveStep("brief");
          break;
        case "confirm_test_result": // legacy
        case "open_output_settings": // legacy
        case "open_triggers_settings": // legacy
        case "confirm_workflow": // legacy
        case "confirm_agent":
          setSidePanelActiveTab("settings");
          setActiveStep("confirm");
          handleActivateAgent();
          break;
        case "payment_required": {
          location.href = "/upgrade";
          break;
        }
        case "open_required_inputs_form": {
          setRequiredInputsFormOpen(true);
          setFormFields(agent?.required_inputs ?? []);
          setFormType("run");
          break;
        }
        case "run_agent": {
          startRun();
          break;
        }
        default:
          console.log(`Unhandled action: ${action}`);
      }
    },
    [
      currentConversation,
      tools,
      openSetupModalAtTool,
      handleToolSelection,
      handleActivateAgent,
      agent?.required_inputs,
      startRun,
    ],
  );

  const handleQuickReplyForm = useCallback((fields: RequiredInput[]) => {
    setRequiredInputsFormOpen(true);
    setFormFields(fields);
    setFormType("conversation");
  }, []);

  const handleEditAgent = () => {
    if (!agent) {
      return;
    }
    navigateToEditMode(agent);
  };

  const editMode = useMemo(
    () => currentConversation?.edit_mode ?? false,
    [currentConversation?.edit_mode],
  );

  if (!agent) {
    return <Loading />;
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>{agent.name} - TailorTask</title>
      </Helmet>
      <div className="flex h-full w-full">
        <div
          className={classNames("flex", {
            "w-full": sidePanelActiveTab === "none" || !editMode,
            "w-1/2 md:w-2/3 lg:3/5": sidePanelActiveTab !== "none" && editMode,
          })}
        >
          <AgentNavigationBar
            agent={agent}
            activeSidePanelTab={sidePanelActiveTab}
            switchSidePanelTab={switchSidePanelTab}
            newAnimatedName={newAnimatedAgentName}
            onNewRun={() => startRun()}
          >
            {(agent.active || editMode) && (
              <div
                className={classNames("", {
                  "md:w-2/3 md:max-w-2/3 mx-auto grow":
                    sidePanelActiveTab === "none" || !editMode,
                  "w-1/2 md:w-full": sidePanelActiveTab !== "none" && editMode,
                })}
              >
                {currentConversation && (
                  <ChatComponent
                    chatRef={chatRef}
                    agentUUID={agent.uuid}
                    conversationUUID={currentConversation?.uuid}
                    agentIcon={
                      agent.icon != "" ? agent.icon : agent.name.split("")[0]
                    }
                    agentState={agentState}
                    isAgentActive={agent.active}
                    messages={messages}
                    isConnected={isConnected}
                    isLoading={isLoadingMessages}
                    onSendMessage={(message: string) => {
                      sendMessage(message);
                    }}
                    loadMessages={loadMessages}
                    onAgentRefresh={reloadCurrentAgent}
                    onQuickReplyAction={handleQuickReplyAction}
                    onQuickReplyForm={handleQuickReplyForm}
                    shiftToBottom={true}
                    showQuickReplyBubbles={
                      !agent.metadata.from_template && !agent.active
                    }
                    editMode={editMode}
                  />
                )}
              </div>
            )}
            {!agent.active && !editMode && (
              <div className="relative flex flex-col items-center justify-center h-full w-full">
                <div className="absolute inset-0 flex flex-col gap-8 p-8 opacity-20 pointer-events-none">
                  <div className="w-full overflow-hidden">
                    <SkeletonElementPreset variant="chat-messages" />
                    <SkeletonElementPreset variant="chat-messages" />
                  </div>
                </div>
                <div className="z-10 flex flex-col items-center gap-8 text-center">
                  <p className="text-lg font-medium">
                    To start using this agent, edit it to complete the setup.
                  </p>
                  <Button onClick={handleEditAgent} size="lg">
                    <RiEditLine className="w-5 h-5 mr-2" />
                    Edit Agent
                  </Button>
                </div>
              </div>
            )}
          </AgentNavigationBar>
        </div>
        {editMode && (
          <RightSidePanel
            agentUUID={agent.uuid}
            sidePanelActiveTab={sidePanelActiveTab}
            activeStep={activeStep}
            toggleStep={toggleStep}
            steps={steps.map((step: SettingStep) => {
              return {
                ...step,
                content: stepsToContent[step.id],
              };
            })}
            switchSidePanelTab={switchSidePanelTab}
          />
        )}
        {currentConversation && (
          <RequiredInputsForm
            agent={agent}
            conversation={currentConversation}
            formType={formType}
            fields={formFields}
            isOpen={isRequiredInputsFormOpen}
            onClose={() => {
              setRequiredInputsFormOpen(false);
            }}
          />
        )}
      </div>
      <ToolsMainPanel
        agent={agent}
        onAgentRefresh={reloadCurrentAgent}
        isLoading={isLoadingTools}
        currentTool={currentTool}
        currentToolIndex={currentToolIndex}
        isToolSetupDialogOpen={isToolSetupDialogOpen}
        closeToolSetupDialog={closeToolSetupDialog}
        allToolsRequiringUserInputs={allToolsRequiringUserInputs}
        toolsThatNeedSetup={toolsThatNeedSetup}
        handleConfirmTools={handleConfirmTools}
        navigateTools={navigateTools}
      />
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  return {};
}
