import { Tool } from "@/utils/types";
import {
  RiCheckboxBlankLine,
  RiCheckboxLine,
  RiCheckFill,
  RiCheckLine,
  RiSettings2Fill,
  RiToolsLine,
} from "react-icons/ri";

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import Tooltip from "@/components/styled/Tooltip";
import SubchapterTitle from "@/components/SubchapterTitle";

type ToolsPartialViewProps = {
  forcedTool?: string;
  onForcedToolDismiss?: () => void;
  isLoading: boolean;
  tools: Tool[];
  selectedTools: string[];
  handleToolSelection: (toolKey: string) => void;
  openSetupModalAtTool: (tool?: Tool) => void;
  toolsThatNeedSetup: Tool[];
  isStepCompleted: boolean;
  handleConfirmTools: () => void;
  isSaving: boolean;
  error: string | null;
};

const ToolsPartialView = ({
  isLoading,
  tools,
  selectedTools,
  handleToolSelection,
  openSetupModalAtTool,
  toolsThatNeedSetup,
  isStepCompleted,
  handleConfirmTools,
  isSaving,
  error,
}: ToolsPartialViewProps) => {
  return (
    <div className="h-full">
      {isLoading ? (
        <p>Loading actions...</p>
      ) : (
        <div className="flex flex-col gap-4 overflow-y-scroll max-h-[calc(100%-86px)]">
          <SubchapterTitle title="Actions" />
          <p className="text-sm">
            Select and set up the actions your Agent can perform
          </p>
          {tools.map((tool) => (
            <div
              key={tool.key}
              className={`p-4 border rounded-lg transition-colors ${
                selectedTools.includes(tool.key)
                  ? "border-gray-300"
                  : "bg-white dark:bg-black-dark border-gray-300 hover:bg-white hover:dark:bg-black"
              }`}
            >
              <div className="flex items-start gap-4">
                <div
                  className="flex-shrink-0 mt-0.5 hover:cursor-pointer hover:opacity-70"
                  onClick={() => handleToolSelection(tool.key)}
                >
                  {selectedTools.includes(tool.key) ? (
                    <RiCheckboxLine className="w-5 h-5 text-blue" />
                  ) : (
                    <RiCheckboxBlankLine className="w-5 h-5 text-black dark:text-white" />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div
                    className="flex hover:cursor-pointer"
                    onClick={() => handleToolSelection(tool.key)}
                  >
                    <h3 className="font-semibold">{tool.name}</h3>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-100 mt-1 mb-2">
                    {tool.public_description}
                  </p>
                  {Object.entries(tool.user_inputs).length > 0 &&
                    selectedTools.includes(tool.key) && (
                      <div className="flex gap-2">
                        <Button
                          variant="secondary"
                          onClick={() => openSetupModalAtTool(tool)}
                          size="sm"
                          disabled={!selectedTools.includes(tool.key)}
                        >
                          {tool.inputs_are_valid ? (
                            <RiCheckFill />
                          ) : (
                            <RiSettings2Fill />
                          )}
                          {tool.inputs_are_valid
                            ? "Set up completed"
                            : "Set up"}
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <br />
      <Divider />
      <br />
      {toolsThatNeedSetup.length == 0 && (
        <Tooltip
          content={
            isSaving
              ? "Processing..."
              : toolsThatNeedSetup.length == 0
                ? "Confirm the Actions you have selected to proceed"
                : "Some of the Actions have not been configured correctly."
          }
        >
          <Button
            variant={isStepCompleted ? "secondary" : "primary"}
            onClick={handleConfirmTools}
            disabled={isSaving || isStepCompleted}
          >
            <RiCheckLine />
            {isSaving
              ? "Processing..."
              : isStepCompleted
                ? "Confirmed"
                : "Confirm Actions"}
          </Button>
          {error && <div className="text-red-500 mt-2">{error}</div>}
        </Tooltip>
      )}
      {toolsThatNeedSetup.length > 0 && (
        <Button
          variant="primary"
          onClick={() => openSetupModalAtTool()}
          disabled={isLoading || toolsThatNeedSetup.length > 0}
        >
          <RiToolsLine />
          Set up Actions
        </Button>
      )}
    </div>
  );
};

export default ToolsPartialView;
