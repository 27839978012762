import axios, { AxiosResponse } from "axios";
import { posthog } from "posthog-js";
import { ApiError, User } from "../types";

export default class BaseApi {
  protected static updateRequired = false;
  protected static token = "";
  static currentUser: User | null = null;
  protected static baseUrl = (() => {
    switch (import.meta.env.MODE) {
      case "production":
        return "https://bkn.tailortask.ai";
      default:
        return "http://localhost:8000";
      // return "https://bkn.tailortask.ai";
    }
  })();
  protected static headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  };
  protected static client = axios.create({
    baseURL: BaseApi.baseUrl,
    headers: BaseApi.headers,
  });
  protected static refreshClient() {
    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: this.headers,
    });
  }

  constructor() {}

  protected static async apiCall<T>(
    requestFn: () => Promise<AxiosResponse<T>>,
  ): Promise<T> {
    this.loadToken();
    const response = await requestFn();
    if (response.status > 299) {
      console.warn(response.data);
      throw new Error(JSON.stringify(response.data));
    }
    return response.data;
  }
  protected static async authenticatedApiCall<T>(
    requestFn: () => Promise<AxiosResponse<T>>,
  ): Promise<T> {
    try {
      const response = await this.apiCall(requestFn);
      return response;
    } catch (error) {
      this.checkToInvalidateToken(error);
      throw error;
    }
  }

  protected static getApiCall<T>(endpoint: string, params?: any): Promise<T> {
    return this.apiCall(() => this.client.get(endpoint, { params }));
  }

  protected static postApiCall<T>(endpoint: string, data?: any): Promise<T> {
    return this.apiCall(() => this.client.post(endpoint, data));
  }

  protected static putApiCall<T>(endpoint: string, data?: any): Promise<T> {
    return this.apiCall(() => this.client.put(endpoint, data));
  }

  protected static deleteApiCall<T>(endpoint: string, data?: any): Promise<T> {
    return this.apiCall(() => this.client.delete(endpoint, data));
  }

  protected static patchApiCall<T>(endpoint: string, data?: any): Promise<T> {
    return this.apiCall(() => this.client.patch(endpoint, data));
  }

  protected static authenticatedGetApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() => this.client.get(endpoint, data));
  }
  protected static authenticatedPostApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() => this.client.post(endpoint, data));
  }
  protected static authenticatedFormPostApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() =>
      this.client.post(endpoint, data, {
        headers: {
          ...this.headers,
          "Content-Type": "multipart/form-data",
        },
      }),
    );
  }

  protected static authenticatedPutApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() => this.client.put(endpoint, data));
  }

  protected static authenticatedDeleteApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() => this.client.delete(endpoint, data));
  }

  protected static authenticatedPatchApiCall<T>(
    endpoint: string,
    data?: any,
  ): Promise<T> {
    return this.authenticatedApiCall(() => this.client.patch(endpoint, data));
  }

  protected static noConnectionError(): ApiError {
    return { json: { server: "down" }, noConnection: true };
  }

  protected static checkToInvalidateToken(error: any) {
    console.log(error);
    if (error && error.response && error.response.status === 401) {
      this.setToken("");
      this.saveToken();
      this.logout();
      return;
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.detail
    ) {
      //create an ApiError object and throw it with the detail data
      throw { json: { detail: error.response.data.detail } };
    }
    if (error && error.code == "ERR_NETWORK") {
      throw error;
    }
  }

  protected static setToken(token: string) {
    this.token = token;

    if (token !== "") {
      this.headers = {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    } else {
      this.headers = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    }
    this.saveToken();
    this.refreshClient();
  }

  protected static saveToken() {
    // Add your token saving implementation here
    localStorage.setItem("token", this.token);
  }

  protected static loadToken() {
    // Add your token loading implementation here
    const token = localStorage.getItem("token");
    if (token) {
      this.setToken(token);
    }
  }

  protected static discardToken() {
    // Add your token discarding implementation here
    localStorage.removeItem("token");
    this.setToken("");
  }

  static isAuthenticated() {
    if (this.token === "" || this.token === undefined) {
      this.loadToken();
      if (this.token === "" || this.token === undefined) {
        return false;
      }
    }
    return true;
  }

  static async logout(): Promise<AxiosResponse<any>> {
    this.setToken("");
    posthog?.capture("logout");
    posthog.reset();
    return this.postApiCall("/dj-rest-auth/logout/");
  }
}
