import { SourceItemsPartialView } from "@/screens/partials/SourceItemsPartialView";
import { NameConventions } from "@/utils/NameConventions";
import { Artefact, Message, Tool } from "@/utils/types";
import React, { useEffect, useState } from "react";
import ContentShareButtons from "./ContentShareButtons";
import { Select } from "./design-system/select";
import MarkdownRenderer from "./styled/MarkdownRenderer";

interface ResultExplorerProps {
  messages: Message[];
  tools: Tool[];
  artefact: Artefact;
}
const ResultExplorer: React.FC<ResultExplorerProps> = ({
  messages,
  tools,
  artefact,
}) => {
  const [nodes, setNodes] = useState<Record<string, string>[]>([]);
  const [currentToolKey, setCurrentToolKey] = useState<string>("");
  const [sourceItemsUUIDs, setSourceItemsUUIDs] = useState<string[]>([]);
  const [result, setResult] = useState<string>("");

  useEffect(() => {
    console.log("artefact", artefact);
    for (const key in artefact.content) {
      if (artefact.content[key].metadata) {
        if (artefact.content[key].metadata.sourceitems) {
          setSourceItemsUUIDs(artefact.content[key].metadata.sourceitems);
        }
      }
    }
  }, [artefact]);

  useEffect(() => {
    const findToolNodes = () => {
      let nodes: { name: string; result: string; key: string }[] = [];
      const toolUses = messages.flatMap((msg) =>
        Array.isArray(msg.content)
          ? msg.content.filter((c) => c.type === "tool_use")
          : [],
      );

      toolUses.forEach((toolUse) => {
        const tool = tools.find((t) => t.key === toolUse.name);
        if (!tool) return;

        if (tool.key == "monitor_content_from_social") {
          // special case, we want to render the references
          nodes.push({
            name: "Monitor Content from Social",
            key: "monitor_content_from_social",
            result: "",
          });
          return;
        }
      });

      //loop through the other keys on the artefact.content and add them as nodes, since they are documents
      for (const key in artefact.content) {
        if (key !== "result" && key !== "sourceitems") {
          let result = "";
          if (typeof artefact.content[key] == "string") {
            result = artefact.content[key];
          } else if (
            typeof artefact.content[key] == "object" &&
            artefact.content[key].document
          ) {
            result = artefact.content[key].document;
          }
          nodes.push({
            name: NameConventions.replaceKnownConventionsFromString(key),
            result: result,
            key: key,
          });
        }
      }

      if (artefact.content.result) {
        let result = "";
        if (typeof artefact.content.result == "string") {
          result = artefact.content.result;
        } else if (
          typeof artefact.content.result == "object" &&
          artefact.content.result.document
        ) {
          result = artefact.content.result.document;
        }
        nodes = [
          {
            name: "Result",
            result: result,
            key: "result",
          },
          ...nodes,
        ];
      }

      return nodes;
    };

    const localNodes = findToolNodes();
    setNodes(localNodes);
    if (localNodes.length > 0) {
      const lastNode = localNodes[localNodes.length - 1];
      setCurrentToolKey("result");
      setResult(lastNode.result);
    }
  }, [artefact.content, messages, tools]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex">
        <Select
          value={currentToolKey}
          onChange={(e) => {
            setCurrentToolKey(e.target.value);
            setResult(nodes.find((n) => n.key == e.target.value)?.result || "");
          }}
        >
          {nodes.map((node, index) => (
            <option key={index} value={node.key}>
              {node.name}
            </option>
          ))}
        </Select>
        <ContentShareButtons artefactUUID={artefact.uuid} content={result} />
      </div>
      <div className="flex">
        <div className="flex flex-col gap-4 overflow-y-auto px-4">
          <div>
            {currentToolKey == "monitor_content_from_social" && (
              <SourceItemsPartialView sourceItemsUUIDs={sourceItemsUUIDs} />
            )}
            <MarkdownRenderer text={result} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultExplorer;
