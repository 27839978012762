import Container from "@/components/styled/Container";
import MarconipyApi from "@/utils/marconipyApi";
import { User } from "@/utils/types";
import { Helmet } from "react-helmet";
import { redirect, useLoaderData } from "react-router-dom";

type userDataResponse = {
  user?: any;
};

export default function BannedView() {
  const usr = useLoaderData() as userDataResponse;

  return (
    <>
      <Helmet>
        <title>{"You are banned"}</title>
      </Helmet>
      <Container>
        {/* {redirectTo && <Navigate to={redirectTo} />} */}
        <div className="flex flex-col gap-4">
          <h1>Banned</h1>
          <h2>
            Hey {usr.user.first_name}
            {", your account was suspended from using Tailor."}
          </h2>
          <p>
            If you have any questions, or would like to appeal your ban, please
            contact us at:
            <a href="mailto:info@tailortask.ai">info@tailortask.ai</a>
          </p>
        </div>
      </Container>
    </>
  );
}

export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.auth.isAuthenticated();
  if (!loggedIn) {
    console.log("User is not logged in");
    return redirect("/");
  } else {
    let user: User | null = MarconipyApi.currentUser;
    if (!user) {
      user = await MarconipyApi.user.me();
    }
    return { user: user };
  }
}
