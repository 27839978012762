import { OutputChannel, SlackChannel } from "../types";
import BaseApi from "./base";

export default class OutputChannelApi extends BaseApi {
  static async getAll(agentUUID: string): Promise<OutputChannel[]> {
    return await this.authenticatedGetApiCall<OutputChannel[]>(
      `/agents/${agentUUID}/channels/`,
    );
  }

  static async create(agentUUID: string): Promise<OutputChannel> {
    return await this.authenticatedPostApiCall<OutputChannel>(
      `/agents/${agentUUID}/channels/`,
      {},
    );
  }

  static async update(
    agentUUID: string,
    channelUUID: string,
    obj: {
      channel_type: string;
      active: boolean;
      templateUUID?: string;
      metadata?: any;
    },
  ): Promise<OutputChannel> {
    return await this.authenticatedPatchApiCall<OutputChannel>(
      `/agents/${agentUUID}/channels/${channelUUID}/`,
      obj,
    );
  }

  static async delete(agentUUID: string, channelUUID: string): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/agents/${agentUUID}/channels/${channelUUID}/`,
    );
  }

  static async getSlack(agentUUID: string): Promise<SlackChannel[]> {
    return await this.authenticatedGetApiCall<SlackChannel[]>(
      `/agents/${agentUUID}/channels/slack/`,
    );
  }
}
