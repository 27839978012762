import React, { createContext, useContext, useReducer, ReactNode } from "react";

// Define the structure of an event
type Event = {
  type: string;
  payload?: any;
};

// Define the state structure
type State = {
  events: Event[];
};

// Define action types
type Action = { type: "ADD_EVENT"; event: Event } | { type: "CLEAR_EVENTS" };

// Create the context
const EventContext = createContext<
  | {
      state: State;
      dispatch: React.Dispatch<Action>;
      emit: (event: Event) => void;
    }
  | undefined
>(undefined);

// Create the reducer function
const eventReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_EVENT":
      return { ...state, events: [...state.events, action.event] };
    case "CLEAR_EVENTS":
      return { ...state, events: [] };
    default:
      return state;
  }
};

// Create the provider component
export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(eventReducer, { events: [] });

  const emit = (event: Event) => {
    dispatch({ type: "ADD_EVENT", event });
  };

  return (
    <EventContext.Provider value={{ state, dispatch, emit }}>
      {children}
    </EventContext.Provider>
  );
};

// Custom hook to use the event context
export const useEventContext = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error("useEventContext must be used within an EventProvider");
  }
  return context;
};

// Custom hook to listen for specific event types
export const useEventListener = (
  eventType: string,
  callback: (payload: any) => void,
) => {
  const { state } = useEventContext();

  React.useEffect(() => {
    const event = state.events.find((e) => e.type === eventType);
    if (event) {
      callback(event.payload);
    }
  }, [state.events, eventType, callback]);
};
