import { ShareableArtefact } from "../types";
import BaseApi from "./base";

export default class PublicApi extends BaseApi {
  static async getShareableResult(
    agentRunUUID: string,
  ): Promise<ShareableArtefact> {
    return await this.authenticatedGetApiCall<ShareableArtefact>(
      `/results/${agentRunUUID}/`,
    );
  }
}
