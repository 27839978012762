import SkeletonElement from "./SkeletonElement";

type SkeletonElementPresetProps = {
  variant: "chat-messages" | "other";
};

const SkeletonElementPreset = ({ variant }: SkeletonElementPresetProps) => {
  switch (variant) {
    case "chat-messages":
      return (
        <>
          <div className="flex message-group mb-2 justify-start bot-message">
            <SkeletonElement width={35} height={35} cornerRadius={100} />
            <div className="max-w-[70%] lg:max-w-[90%] overflow-hidden rounded-lg px-1">
              <SkeletonElement width={345} height={150} cornerRadius={5} />
            </div>
          </div>
          <div className="flex mb-2 message group justify-end user-message">
            <div className="max-w-[70%] lg:max-w-[90%] overflow-hidden rounded-lg px-1">
              <SkeletonElement width={245} height={80} cornerRadius={5} />
            </div>
          </div>
          <div className="flex message-group mb-2 justify-start bot-message">
            <SkeletonElement width={35} height={35} cornerRadius={100} />
            <div className="max-w-[70%] lg:max-w-[90%] overflow-hidden rounded-lg px-1">
              <SkeletonElement width={225} height={80} cornerRadius={5} />
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default SkeletonElementPreset;
