import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useState } from "react";
import { RiCheckFill, RiDeleteBinFill, RiLoader2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Label } from "./design-system/fieldset";
import { Input } from "./design-system/input";
import { Select } from "./design-system/select";
import { Switch, SwitchField } from "./design-system/switch";
import Tooltip from "./styled/Tooltip";

type TriggerSchedule = {
  agent: Agent;
  trigger_uuid?: string;
  onRefresh?: () => void;
};

type ScheduleType = "daily" | "weekly" | "monthly";

export function TriggerSchedule({
  agent,
  trigger_uuid,
  onRefresh,
}: TriggerSchedule) {
  const posthog = usePostHog();
  const [triggerUUID, setTriggerUUID] = useState<string | undefined>(
    trigger_uuid,
  );
  const [isActive, setIsActive] = useState(false);
  const [scheduleType, setScheduleType] = useState<ScheduleType>("daily");
  const [scheduleTime, setScheduleTime] = useState("12:00");
  const [dayOfWeek, setDayOfWeek] = useState<number>(0);
  const [dayOfMonth, setDayOfMonth] = useState<number>(1);

  const [anychangesMade, setAnyChangesMade] = useState(false);

  const loadTrigger = useCallback(async () => {
    if (triggerUUID) {
      const triggers = await MarconipyApi.trigger.getAll(agent.uuid);
      const ftrigger = triggers.find((trigger) => trigger.uuid === triggerUUID);
      if (!ftrigger) return;
      setIsActive(ftrigger.active);
      setScheduleType(ftrigger.schedule_type as ScheduleType);
      // Parse the execution_time correctly
      const [hours, minutes, _] = ftrigger.execution_time.split(":");
      const formattedTime = `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
      setScheduleTime(formattedTime);

      setDayOfWeek(ftrigger.day_of_week || 0);
      setDayOfMonth(ftrigger.day_of_month || 1);
    }
  }, [triggerUUID, agent.uuid]);

  const handleToggle = () => {
    if (isActive) {
      posthog.capture("agentEdit: toggle trigger off", {
        source: "agent trigger",
        type: "email",
      });
    } else {
      posthog.capture("agentEdit: toggle trigger on", {
        source: "agent trigger",
        type: "email",
      });
    }
    setIsActive(!isActive);
    handleSave(!isActive);
  };

  const deleteTrigger = async () => {
    if (!trigger_uuid) return;
    await MarconipyApi.trigger.delete(agent.uuid, trigger_uuid);
    onRefresh && onRefresh();
  };

  const handleSave = async (force_active?: boolean) => {
    const [hours, minutes] = scheduleTime.split(":");
    const paddedHour = parseInt(hours, 10).toString().padStart(2, "0");
    const paddedMinutes = parseInt(minutes, 0).toString().padStart(2, "0");
    const executionTime = `${paddedHour}:${paddedMinutes}`;

    let active: boolean = isActive;
    if (force_active === true || force_active === false) {
      active = force_active;
    }
    if (active) {
      posthog.capture("agentEdit: activate trigger", {
        source: "agent output channels",
        type: "schedule",
      });
    } else {
      posthog.capture("agentEdit: deactivate trigger", {
        source: "agent output channels",
        type: "schedule",
      });
    }

    const triggerData: any = {
      active: active,
      trigger_type: "time",
      schedule_type: scheduleType,
      execution_time: executionTime,
    };

    if (scheduleType === "weekly") {
      triggerData.day_of_week = dayOfWeek;
    } else if (scheduleType === "monthly") {
      triggerData.day_of_month = dayOfMonth;
    }

    if (!triggerUUID) {
      const trigger = await MarconipyApi.trigger.create(
        agent.uuid,
        triggerData,
      );
      trigger_uuid = trigger.uuid;
      setTriggerUUID(trigger_uuid);
      onRefresh && onRefresh(); // Ensure the parent component gets the new trigger_uuid
    } else {
      await MarconipyApi.trigger.update(agent.uuid, triggerUUID, triggerData);
    }
    setAnyChangesMade(false);
    onRefresh && onRefresh();
    if (active && !force_active) {
      toast.success(`Saved`);
    }
  };

  const handleScheduleTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setScheduleType(event.target.value as ScheduleType);
    setAnyChangesMade(true);
    onRefresh && onRefresh();
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleTime(event.target.value);
    setAnyChangesMade(true);
  };

  const handleDayOfTheWeekChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDayOfWeek(parseInt(event.target.value, 10));
    setAnyChangesMade(true);
  };

  useEffect(() => {
    loadTrigger();
  }, [loadTrigger, agent.uuid]);

  return (
    <div className="">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Label className="font-semibold">
            ⏰ Schedule at specific time/day
          </Label>
          <Switch onChange={handleToggle} checked={isActive} />
        </SwitchField>
        {trigger_uuid && isActive && (
          <div className="ml-4">
            <Tooltip content="Delete email trigger">
              <Button variant="secondary" onClick={deleteTrigger}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm mb-2">
        {isActive ? (
          <>
            <RiCheckFill className="text-tertiary mr-1" />
            Trigger is active
          </>
        ) : (
          <>
            <RiLoader2Fill className="opacity-50 mr-1" />
            Trigger is inactive
          </>
        )}
      </div>

      {isActive && (
        <>
          <div className="mb-4">
            <label htmlFor="scheduleType" className="block mb-2">
              Schedule Type:
            </label>
            <Select
              id="scheduleType"
              value={scheduleType}
              onChange={handleScheduleTypeChange}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Select>
          </div>
          {scheduleType === "weekly" && (
            <div className="mb-4">
              <label htmlFor="dayOfWeek" className="block mb-2">
                Weekly on:
              </label>
              <Select
                id="dayOfWeek"
                value={dayOfWeek}
                onChange={handleDayOfTheWeekChange}
              >
                <option value={0}>Monday</option>
                <option value={1}>Tuesday</option>
                <option value={2}>Wednesday</option>
                <option value={3}>Thursday</option>
                <option value={4}>Friday</option>
                <option value={5}>Saturday</option>
                <option value={6}>Sunday</option>
              </Select>
            </div>
          )}

          {scheduleType === "monthly" && (
            <div className="mb-4">
              <label htmlFor="dayOfMonth" className="block mb-2">
                Monthly on the:
              </label>
              <Select
                id="dayOfMonth"
                value={dayOfMonth}
                onChange={(e) => setDayOfMonth(parseInt(e.target.value, 10))}
                className="w-full p-2 border rounded dark:bg-black-dark"
              >
                {[...Array(31).keys()].map((i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}th
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div className="mb-4">
            <label htmlFor="scheduleTime" className="block mb-2">
              At:
            </label>
            <Input
              type="time"
              id="scheduleTime"
              value={scheduleTime}
              onChange={handleTimeChange}
            />
          </div>
          {anychangesMade && (
            <div className="mt-4">
              <Button
                variant="secondary"
                onClick={() => {
                  handleSave();
                }}
              >
                Save changes
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
