import { Trigger } from "../types";
import BaseApi from "./base";

export default class TriggerApi extends BaseApi {
  static async getAll(agentUUID: string): Promise<Trigger[]> {
    return await this.authenticatedGetApiCall<Trigger[]>(
      `/agents/${agentUUID}/triggers/`,
    );
  }

  static async create(
    agentUUID: string,
    data: {
      trigger_type: string;
      active: boolean;
      schedule_type?: string;
      execution_time?: string;
    },
  ): Promise<Trigger> {
    return await this.authenticatedPostApiCall<Trigger>(
      `/agents/${agentUUID}/triggers/`,
      data,
    );
  }

  static async update(
    agentUUID: string,
    triggerUUID: string,
    params: {
      trigger_type: string;
      active: boolean;
      schedule_type?: string;
      execution_time?: string;
      day_of_week?: number;
      day_of_month?: number;
      metadata: {
        trigger_phrase?: string;
        channel_id?: string;
      };
    },
  ): Promise<Trigger> {
    return await this.authenticatedPatchApiCall<Trigger>(
      `/agents/${agentUUID}/triggers/${triggerUUID}/`,
      params,
    );
  }

  static async delete(agentUUID: string, triggerUUID: string): Promise<void> {
    await this.authenticatedDeleteApiCall(
      `/agents/${agentUUID}/triggers/${triggerUUID}/`,
    );
  }
}
