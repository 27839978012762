import { Button } from "@/components/design-system/button";
import Label from "@/components/styled/Label";
import { useAuth } from "@/contexts/AuthenticationContext";
import MarconipyApi from "@/utils/marconipyApi";
import domains from "disposable-email-domains";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import SignInWithGoogle from "../SignInWithGoogle";
import { Input } from "../design-system/input";

type SignupFormProps = {
  onLoginRedirect: () => void;
  redirectToAfterLogin?: string;
};

export default function SignupForm({
  onLoginRedirect,
  redirectToAfterLogin,
}: SignupFormProps) {
  const [firstName, setFirstName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [justloggedin, setJustLoggedIn] = useState(false);
  const { updateAuth } = useAuth();
  const [signupButtonClicked, setSignupButtonClicked] = useState(false);
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const invite = searchParams.get("invite");

  useEffect(() => {
    const getInvite = async () => {
      if (invite) {
        const inviteObj = await MarconipyApi.team.getInvite(invite);
        if (inviteObj.invited_email) {
          setUsername(inviteObj.invited_email);
        }
      }
    };
    if (searchParams.get("invite")) {
      getInvite();
    }
  }, [invite, searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSignupButtonClicked(true);
    try {
      const email_domain = username.split("@")[1];
      if (domains.includes(email_domain)) {
        setError("Please don't use a disposable email address.");
        setJustLoggedIn(false);
        setSignupButtonClicked(false);
        return;
      }
      await MarconipyApi.auth.signup(username, password, password, firstName);
      await MarconipyApi.auth.login(username, password);
      await MarconipyApi.user.update({ first_name: firstName });
      // this capture event needs to happen after we have identified the user in updateAuth
      await updateAuth();
      let invite = searchParams.get("invite");
      if (invite) {
        posthog?.capture("signup with invite code");
      }
      window.location.reload();
    } catch (err: any) {
      let errors = err.response.data;
      for (let key of Object.keys(errors)) {
        setError(errors[key][0]);
        break;
      }
      setJustLoggedIn(false);
      setSignupButtonClicked(false);
    }
  };

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit} className="flex flex-col gap-2 my-4">
        <div>
          <Label>First name:</Label>
          <Input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Aria Factwriter"
            autoFocus
          />
        </div>
        <div>
          <Label>Email:</Label>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="aria@yourdomain.com"
          />
        </div>
        <div>
          <Label>Password:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="a strong password"
          />
        </div>
        {error && <small className="text-primary">{error}</small>}
        <Button
          variant="primary"
          type="submit"
          disabled={
            signupButtonClicked ||
            firstName.length == 0 ||
            username.length == 0 ||
            password.length == 0
          }
          className="mt-4"
        >
          {!signupButtonClicked && "Create free account"}
          {signupButtonClicked && "Loading"}
        </Button>
        <small className="text-xs text-black dark:text-white">
          By creating an account, you accept our{" "}
          <a
            href="https://www.tailortask.ai/terms-of-use"
            target="_blank"
            rel="noreferrer"
            className="text-primary"
          >
            Terms of use
          </a>{" "}
          and{" "}
          <a
            href="https://www.tailortask.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="text-primary"
          >
            Privacy Policy. You will be subscribed to our monthly newsletter.
          </a>
        </small>
      </form>
      {justloggedin && <Navigate to="/?reload=true" replace={true} />}
      <br />
      <SignInWithGoogle redirectUrl={redirectToAfterLogin} />
      <br />
      <Button onClick={onLoginRedirect} variant="secondary">
        Already have an account?{" "}
        <span className="text-primary pl-1">Login</span>
      </Button>
    </div>
  );
}
