export default function SmallLabel({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <label className="block text-xs font-medium leading-6 text-black dark:text-white uppercase">
      {children}
    </label>
  );
}
