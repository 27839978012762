"use client";

import * as Headless from "@headlessui/react";
import clsx from "clsx";
import { RiArrowDownSLine } from "react-icons/ri";

export function Accordion(props: Headless.DisclosureProps) {
  return <Headless.Disclosure {...props} />;
}

export function AccordionButton({
  className,
  children,
  showArrow = true,
  ...props
}: { className?: string; showArrow?: boolean } & Omit<
  Headless.DisclosureButtonProps,
  "className"
>) {
  return (
    <Headless.DisclosureButton
      className={clsx(
        className,
        "flex w-full justify-start gap-1 items-center rounded-lg bg-zinc-100 px-3.5 py-2 text-left text-sm font-medium text-zinc-900 hover:bg-zinc-200 focus:outline-none focus-visible:ring focus-visible:ring-zinc-500 focus-visible:ring-opacity-75 dark:bg-zinc-800 dark:text-zinc-100 dark:hover:bg-zinc-700",
      )}
      {...props}
    >
      {({ open }) => (
        <>
          {children}
          {showArrow && (
            <RiArrowDownSLine
              className={clsx(
                "h-5 w-5 text-zinc-500 transition-transform",
                open && "rotate-180 transform",
              )}
            />
          )}
        </>
      )}
    </Headless.DisclosureButton>
  );
}

export function AccordionPanel({
  className,
  ...props
}: { className?: string } & Omit<Headless.DisclosurePanelProps, "className">) {
  return (
    <Headless.DisclosurePanel
      className={clsx(
        className,
        "px-4 py-1 text-sm text-zinc-500 dark:text-zinc-400",
      )}
      {...props}
    />
  );
}

export function AccordionItem({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) {
  return (
    <Accordion>
      {({ open }) => (
        <>
          <AccordionButton>
            <span>{question}</span>
          </AccordionButton>
          <AccordionPanel>{answer}</AccordionPanel>
        </>
      )}
    </Accordion>
  );
}
