import { RiFileCopyLine, RiShareLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button } from "./design-system/button";

const ContentShareButtons = ({
  artefactUUID,
  content,
}: {
  artefactUUID: string;
  content: string;
}) => {
  return (
    <div className="w-full flex justify-end gap-2">
      <Button
        onClick={() => {
          let frontendUrl =
            window.location.protocol + "//" + window.location.host;
          navigator.clipboard.writeText(frontendUrl + "/share/" + artefactUUID);
          toast.success(`Shareable link copied to clipboard!`);
        }}
        variant="secondary"
      >
        <RiShareLine />
        Share
      </Button>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(content);
          toast.success(`Document copied to clipboard!`);
        }}
        variant="secondary"
      >
        <RiFileCopyLine />
      </Button>
    </div>
  );
};

export default ContentShareButtons;
