import { RiMailFill } from "react-icons/ri";
// import './CopyEmailButton.scss';import { Button } from '@/components/design-system/button';
import { toast } from "react-toastify";
import { Button } from "../design-system/button";

type CopyEmailButtonProps = {
  variant: "primary" | "secondary" | "plain";
  email: string; // Add email prop
};

export default function CopyEmailButton(props: CopyEmailButtonProps) {
  const handleCopyEmail = () => {
    navigator.clipboard
      .writeText(props.email)
      .then(() => {
        toast.success(`${props.email} copied to your clipboard!`);
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err);
      });
  };

  return (
    <Button variant={props.variant} onClick={handleCopyEmail}>
      <RiMailFill className="mr-2" />
      {props.email}
    </Button>
  );
}
