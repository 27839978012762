import { Outlet } from "react-router-dom";

export default function RootStatic() {
  return (
    <>
      {/* <div className=""> */}
      {/* <div className="font-sans tracking-wide text-black dark:text-white dark:bg-black-deep"> */}
      {/* <div className="static grid grid-cols-12"> */}
      <Outlet />
      {/* </div> */}
      {/* <SVGBackground /> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
