import { FC } from "react";
import {
  RiAB,
  RiHeadphoneLine,
  RiLinksFill,
  RiMastodonFill,
  RiNewspaperLine,
  RiQuestionLine,
  RiQuestionMark,
  RiRssFill,
  RiSearchLine,
  RiTwitterFill,
  RiYoutubeFill,
} from "react-icons/ri";

export class IconsUtils {
  static fromSourceSetTypeToIcon(type: string): FC {
    switch (type) {
      case "text":
        return RiNewspaperLine;
      case "podcast":
        return RiHeadphoneLine;
      case "youtube":
        return RiYoutubeFill;
      case "twitter":
        return RiTwitterFill;
      case "mastodon":
        return RiMastodonFill;
      case "feed":
      case "rss_app":
        return RiRssFill;
      case "topic":
        return RiAB;
      default:
        return RiQuestionMark;
    }
  }
  static fromUserQueryTypeToIcon(type: string): FC {
    switch (type) {
      case "url":
        return RiLinksFill;
      case "search":
        return RiSearchLine;
      case "question":
        return RiQuestionLine;
      default:
        return RiQuestionLine;
    }
  }
}
