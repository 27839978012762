import { Button } from "@/components/design-system/button";
import { useAuth } from "@/contexts/AuthenticationContext";
import MarconipyApi from "@/utils/marconipyApi";
import { NameConventions } from "@/utils/NameConventions";
import { BackgroundJob, Message, RequiredInput, Tool } from "@/utils/types";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RiArticleLine,
  RiCalendarLine,
  RiCloseLine,
  RiCodeBlock,
  RiEditLine,
  RiFeedbackFill,
  RiInputField,
  RiPlayLine,
  RiRestartLine,
  RiRocket2Fill,
  RiSaveLine,
  RiSendPlaneLine,
  RiSparkling2Line,
  RiStickyNoteLine,
  RiToolsLine,
} from "react-icons/ri";
import JSONPretty from "react-json-pretty";
import { Dialog, DialogBody, DialogTitle } from "./design-system/dialog";
import { Divider } from "./design-system/divider";
import { MessageIcon, toolToIcon } from "./MessageIcon";
import AgentIcon from "./styled/AgentIcon";
import MarkdownRenderer from "./styled/MarkdownRenderer";
import ProgressBar from "./styled/ProgressBar";
import ThinkingComponent from "./styled/ThinkingComponent";
import TimeLabel from "./styled/TimeLabel";
import Tooltip from "./styled/Tooltip";

type MessageComponentProps = {
  message: Message;
  index: number;
  conversationUUID: string;
  agentIcon?: string;
  isLastMessage?: boolean;
  isRun?: boolean;
  refreshAllMessages?: ({ force }: { force?: boolean }) => void;
  advancedMode?: boolean;
  onQuickReplyAction?: (action: string) => void;
  onQuickReplyForm?: (fields: RequiredInput[]) => void;
  onOpenDocument?: (content: string, artefactUUID: string | null) => void;
  tools: Tool[];
};

const isDevEnvironment = process.env.NODE_ENV === "development";

export const MessageComponent = forwardRef<
  HTMLDivElement,
  MessageComponentProps
>((props, ref) => {
  const { user } = useAuth();
  const [isUser, setIsUser] = useState(false);
  const [isTool, setIsTool] = useState(false);
  const [toolResult, setToolResult] = useState(false);
  const [textContent, setTextContent] = useState<string[]>([]);
  const [thinkingContent, setThinkingContent] = useState<string[]>([]);
  const [actionText, setActionText] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [quickReplies, setQuickReplies] = useState<any[]>([]);
  const [toolUseID, setToolUseID] = useState<string | null>(null);
  const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
    null,
  );
  const backgroundJobInterval = useRef<number | null>(null);
  const [timeEstimate, setTimeEstimate] = useState<number>(0);
  const quickReplyActionToRender = (quickReply: any): any => {
    if (quickReply.type == "action") {
      if (quickReply.action.startsWith("open_tool:")) {
        const action = quickReply.action.replace("open_tool:", "");
        return {
          label: `Set up ${NameConventions.toHuman(action)}`,
          tooltip: `Set up the action: ${NameConventions.toHuman(action)} to continue`,
          icon: toolToIcon[action],
          ...quickReply,
        };
      }
      if (quickReply.action.startsWith("skip_tool:")) {
        return {
          label: `Skip action`,
          tooltip: `Skip setting up this action if you don't want the Agent to use it`,
          icon: <RiCloseLine />,
          variant: "secondary",
          ...quickReply,
        };
      }
      switch (quickReply.action) {
        case "open_tool_settings": {
          // legacy
          return {
            label: "Set up Actions",
            tooltip:
              "Set up the Actions that TailorTask will use to complete the task",
            icon: <RiToolsLine />,
            ...quickReply,
          };
        }
        case "open_brief_settings": {
          return {
            label: "View Brief",
            tooltip:
              "View the brief that TailorTask will use to complete the task",
            icon: <RiCodeBlock />,
            ...quickReply,
          };
        }
        case "confirm_test_result": {
          // legacy
          return {
            label: "Confirm Dry Run",
            tooltip: "Approve the result of the dry run",
            icon: <RiSparkling2Line />,
            ...quickReply,
          };
        }
        case "give_feedback": {
          return {
            label: "Give feedback",
            tooltip: "Improve the result by giving feedback",
            variant: "secondary",
            icon: <RiFeedbackFill />,
            ...quickReply,
          };
        }
        case "save_and_exit": {
          return {
            label: "Save and Exit",
            tooltip: "Start using your Agent",
            variant: "primary",
            icon: <RiSaveLine />,
            ...quickReply,
          };
        }
        case "open_output_settings": {
          return {
            label: "Set up result settings",
            tooltip: "Set up how to get results",
            icon: <RiArticleLine />,
            ...quickReply,
          };
        }
        case "open_triggers_settings": {
          return {
            label: "Set up triggers",
            tooltip: "Set up triggers",
            icon: <RiCalendarLine />,
            ...quickReply,
          };
        }
        case "confirm_workflow": {
          // legacy
          return {
            label: "Activate Agent",
            tooltip: "Activate the agent",
            icon: <RiSendPlaneLine />,
            ...quickReply,
          };
        }
        case "confirm_agent": {
          // legacy
          return {
            label: "Activate Agent",
            tooltip: "Activate the agent",
            icon: <RiSendPlaneLine />,
            ...quickReply,
          };
        }
        case "payment_required": {
          return {
            label: "Upgrade to Premium",
            tooltip:
              "Check out the details of our Premium and Enterprise plans",
            icon: <RiRocket2Fill />,
            ...quickReply,
          };
        }
        case "open_required_inputs_form": {
          return {
            label: "Fill up form",
            tooltip: "The Agent requires some information to start the task",
            icon: <RiInputField />,
            ...quickReply,
          };
        }
        case "run_agent": {
          return {
            label: "Run",
            tooltip: "Run Agent again",
            icon: <RiPlayLine />,
            ...quickReply,
          };
        }
        default:
          return quickReply;
      }
    } else if (quickReply.type == "preview") {
      return {
        label: "Read",
        tooltip: "Open the document the agent sent you",
        icon: <RiStickyNoteLine />,
        ...quickReply,
      };
    } else if (quickReply.type == "form") {
      return {
        label: "Fill up form",
        tooltip: "Fill up the form",
        icon: <RiInputField />,
        ...quickReply,
      };
    }
    return quickReply;
  };
  const parseContent = useCallback(() => {
    const content = props.message.content;
    let final = [];
    if (props.message.role == "user") {
      setIsUser(true);
    }
    if (content && Array.isArray(content)) {
      // if (content.length == 1 && content[0].type && content[0].type == "text") {
      let contentObject = content.find((item) => item.type == "text") || {
        text: "",
      };
      let textContent = contentObject.text;
      if (!textContent) {
        textContent = "";
      }
      let textContentOriginal = textContent;

      // Parse content between <thinking> tags
      const thinkingRegex = /<thinking>([\s\S]*?)<\/thinking>/g;
      const thinkingMatches = [...textContent.matchAll(thinkingRegex)];
      const newThinkingContent = thinkingMatches.map((match) =>
        match[1].trim(),
      );
      setThinkingContent(newThinkingContent);

      // Remove <thinking> tags from textContent
      textContent = textContent.replace(thinkingRegex, "");

      //replace leading and trailing newlines and spaces
      textContent = textContent.replace(/^\s+|\s+$/g, "");

      textContent = textContent.replace(/(?:\r\n|\r|\n)/g, "<br />");
      // textContent = textContent.strip();

      let toolUse = content.find((item) => item.type === "tool_use");
      let toolResult = content.find((item) => item.type === "tool_result");
      if (textContent.startsWith("BRIEF:") && props.message.role == "user") {
        setIsTool(true);
        setIsUser(false);
        toolUse = {
          type: "reading_brief",
          id: "reading_brief",
          name: "reading_brief",
        };
      }
      if (toolUse || toolResult) {
        setIsTool(true);
        setIsUser(false);
      }
      if (toolUse && toolUse.id && toolUse.name) {
        const key = toolUse.name;
        const localTool = props.tools.find((tool: Tool) => tool.key === key);
        if (localTool && localTool.async_response) {
          backgroundJobInterval.current = null;
          setToolUseID(toolUse.id);
        }
        setActionText(
          NameConventions.fromActionToVerb(
            toolUse.name,
            props.isLastMessage ? "present" : "past",
          ),
        );
      }
      if (toolResult) {
        setToolResult(true);
        setActionText("Action completed");
      }
      // }
      if (
        !toolUse &&
        !toolResult &&
        textContent.length === 0 &&
        thinkingMatches.length > 0
      ) {
        textContent = textContentOriginal
          ?.replace("<thinking>", "")
          .replace("</thinking>", "");
      }
      final.push(textContent);
    } else {
      if (content && typeof content === "string") {
        final.push(content.replace(/(?:\r\n|\r|\n)/g, "<br />"));
      }
    }
    setTextContent(final);
  }, [
    props.message.content,
    props.message.role,
    props.tools,
    props.isLastMessage,
  ]);

  const renderAction = useCallback(() => {
    const content = props.message.content;
    if (Array.isArray(content)) {
      for (const item of content) {
        if (item.type == "tool_use") {
          if (item.input && item.input.prompt && item.name) {
            setActionText(
              NameConventions.fromActionToVerb(
                item.name,
                props.isLastMessage ? "present" : "past",
              ),
            );
          }
          if (!item.input && item.name) {
            setActionText(
              NameConventions.fromActionToVerb(
                item.name,
                props.isLastMessage ? "present" : "past",
              ),
            );
          }
          setModalContent(JSON.stringify(item));
        }
        if (item.type == "tool_result") {
          setModalContent(JSON.stringify(item));
          if (item.content) {
            setActionText(item.content);
          }
        }
      }
      return "";
    }
    return "";
  }, [props.message.content, props.isLastMessage]);

  useEffect(() => {
    parseContent();
    renderAction();
    if (
      typeof props.message.content !== "string" &&
      Array.isArray(props.message.content)
    ) {
      const quickRepliesContent = props.message.content.find(
        (item) => item.type === "quick_replies",
      );
      if (quickRepliesContent && quickRepliesContent.quick_replies) {
        setQuickReplies(
          quickRepliesContent.quick_replies.map((item: any) => {
            return quickReplyActionToRender(item);
          }),
        );
      }
    }
  }, [
    parseContent,
    props.message.content,
    props.message.uuid,
    renderAction,
    props.isLastMessage,
  ]);

  const toggleModal = () => {
    if (props.index != 0) {
      setShowModal(!showModal);
    }
  };

  const handleEditMessageSave = async (
    editedContent: string | Record<string, any>[],
  ) => {
    try {
      let contentToSend = editedContent;
      if (editedContent && typeof editedContent === "string") {
        contentToSend = [
          {
            type: "text",
            text: editedContent,
          },
        ];
        setTextContent([editedContent.replace(/(?:\r\n|\r|\n)/g, "<br />")]);
      }
      await MarconipyApi.conversation.editMessage(
        props.conversationUUID,
        props.message.uuid,
        {
          content: contentToSend,
        },
      );
      setIsEditing(false);
      setShowModal(false);
      props.refreshAllMessages && props.refreshAllMessages({ force: true });
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };
  const handleOpenMessageEdit = () => {
    if (isUser && !isTool) {
      setIsEditing(true);
    }
  };

  const classGivenMessageType = () => {
    if (isUser && !isTool) {
      if (!props.message.run) {
        return "bg-sky-100 text-black dark:bg-black-light dark:text-white shadow-chat";
      } else {
        return "bg-sky-100 text-black dark:bg-black-light dark:text-white shadow-chat";
      }
    }
    if (isTool) {
      return "bg-white border border-gray dark:text-white dark:bg-black dark:border-black-light shadow-chat";
    }
    return "bg-white border border-gray shadow-chat dark:text-white dark:bg-black dark:border-black-light";
  };

  const completionPercentage =
    backgroundJob &&
    (backgroundJob.completed_subtask_count /
      backgroundJob.total_subtask_count) *
      100;
  useEffect(() => {
    const getBackgroundJob = async () => {
      if (!toolUseID) return;
      const backgroundJob = await MarconipyApi.conversation.getBackgroundJob(
        props.conversationUUID,
        toolUseID,
      );
      if (!backgroundJob) {
        if (backgroundJobInterval.current) {
          clearInterval(backgroundJobInterval.current);
        }
        return;
      }
      setBackgroundJob(backgroundJob);
      if (backgroundJob.total_time_estimate_seconds) {
        setTimeEstimate(backgroundJob.total_time_estimate_seconds);
      }

      if (
        backgroundJob.state === "completed" ||
        backgroundJob.state === "failed"
      ) {
        setTimeEstimate(0);
        if (backgroundJobInterval.current) {
          clearInterval(backgroundJobInterval.current);
        }
      }
    };

    setTimeout(() => {
      if (toolUseID) {
        if (!backgroundJobInterval.current) {
          getBackgroundJob();
          backgroundJobInterval.current = window.setInterval(
            getBackgroundJob,
            5000,
          ); // Poll every 5 seconds
        } else {
          console.log("Background job already polling");
        }
      }
    }, 1500);
  }, [toolUseID, backgroundJob, props.message.content, props.conversationUUID]);

  const timeEstimateString = useMemo(() => {
    if (timeEstimate < 60) {
      return "less than a minute";
    } else {
      const minutes = Math.ceil(timeEstimate / 60);
      return minutes < 2 ? "1 minute" : `${minutes} minutes`;
    }
  }, [timeEstimate]);

  const showText =
    !(toolResult && !props.advancedMode) &&
    (textContent.filter((t) => t.length > 0).length > 0 ||
      actionText.length > 0);

  const showQuickReplies = quickReplies.length > 0 && props.isLastMessage;
  const showRunPreview =
    quickReplies.length > 0 &&
    quickReplies.find((item) => item.type === "preview");
  const showFormCompletion =
    quickReplies.length > 0 &&
    !props.isLastMessage &&
    quickReplies.find((item) => item.type === "form");

  if (!showText && !showQuickReplies && !showRunPreview) {
    return null;
  }

  return (
    <div className="flex flex-col" ref={ref}>
      {showText && (
        <div
          className={`flex mb-2 message group ${isUser ? "justify-end user-message" : "justify-start bot-message"} ${props.index == 0 ? "" : ""}`}
        >
          {!isUser && !props.isRun && (
            //render the profile picture
            <div className="mr-2">
              <Tooltip content="TailorTask">
                <AgentIcon icon={props.agentIcon ?? ""} />
              </Tooltip>
            </div>
          )}
          {!isUser && props.isRun && (
            //render the profile picture for a run
            <div className="mr-2">
              <Tooltip content="TailorTask - Executing an Agent">
                <AgentIcon icon={props.agentIcon ?? ""} variant="tertiary" />
              </Tooltip>
            </div>
          )}
          <div
            className={`max-w-[70%] lg:max-w-[90%] overflow-hidden rounded-lg p-3 ${classGivenMessageType()} ${isEditing ? "min-w-[90%]" : ""}`}
          >
            {!actionText &&
              !isEditing &&
              !showFormCompletion &&
              ((isTool && props.advancedMode) || !isTool) && (
                <p
                  className="text-sm"
                  dangerouslySetInnerHTML={{
                    __html: textContent.join("<br />"),
                  }}
                />
              )}
            {actionText && (
              <div className="flex mb-2">
                <MessageIcon
                  message={props.message}
                  isLastMessage={props.isLastMessage}
                />
                {` `}
                <span className="text-xs mr-4">{actionText}</span>
              </div>
            )}
            {showFormCompletion && (
              <div className="flex gap-2">
                <RiInputField />
                {` `}
                <span className="text-xs mr-4">Form completed</span>
              </div>
            )}
            {isEditing && (
              <div className="flex flex-col ">
                <textarea
                  className="w-full mb-2 p-2 text-sm bg-white text-black border rounded"
                  defaultValue={textContent
                    .join("\n")
                    .replaceAll("<br />", "\n")}
                  onChange={(e) => setTextContent(e.target.value.split("\n"))}
                />
                <div className="flex align-center gap-4">
                  <Button
                    onClick={() =>
                      handleEditMessageSave(textContent.join("\n"))
                    }
                    variant="secondary"
                  >
                    <RiEditLine />
                    Edit
                  </Button>
                  <Button onClick={() => setIsEditing(false)} variant="plain">
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            {completionPercentage != null &&
            completionPercentage < 100 &&
            timeEstimate > 0 ? (
              <div className="flex flex-col gap-1 py-2">
                <p className="text-xs">
                  {Math.floor(completionPercentage)}% complete (
                  {timeEstimateString} estimated)
                </p>
                <div className="w-full">
                  <ProgressBar
                    steps={1}
                    widths={[100]}
                    currentStep={0}
                    stepProgress={completionPercentage}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex items-center justify-between opacity-0 hover:opacity-100">
              {props.message.localized_timestamp && (
                <TimeLabel
                  time={props.message.localized_timestamp}
                  additionalClasses="text-xxs text-gray-500"
                />
              )}
              {(!isUser || (isUser && props.advancedMode)) && (
                <div className="flex flex-row items-center">
                  <div className="flex justify-end items-center flex-grow opacity-0 group-hover:opacity-100">
                    {thinkingContent && thinkingContent.length > 0 && (
                      <ThinkingComponent thinkingContent={thinkingContent} />
                    )}
                    {props.advancedMode && props.index > 0 && (
                      <Tooltip content="Debug">
                        <Button variant="plain" onClick={toggleModal}>
                          <RiCodeBlock width={16} />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
              {isUser && !isTool && !isEditing && (
                <div className="flex align-right justify-end flex-grow gap-4 hover:cursor-pointer">
                  <Tooltip content="Edit and restart from here">
                    <Button
                      onClick={handleOpenMessageEdit}
                      className="opacity-0 group-hover:opacity-80 hover:opacity-100"
                      variant="plain"
                    >
                      <RiEditLine />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>

          <Dialog open={showModal} onClose={toggleModal} size="xl">
            <DialogTitle>Debug</DialogTitle>
            <DialogBody>
              <div className="text-wrap dark:text-white flex flex-col gap-4">
                {thinkingContent && thinkingContent.length > 0 && (
                  <ThinkingComponent thinkingContent={thinkingContent} />
                )}
                <JSONPretty
                  themeClassName="text-wrap truncate"
                  data={modalContent}
                ></JSONPretty>
                <Divider />
                <JSONPretty
                  themeClassName="text-wrap truncate"
                  data={props.message}
                ></JSONPretty>
                {(isDevEnvironment ||
                  user?.email.includes("@tailortask.ai")) && (
                  <div className="flex align-right justify-end flex-grow gap-4 hover:cursor-pointer">
                    <Tooltip content="Edit and restart from here">
                      <Button
                        onClick={() =>
                          handleEditMessageSave(props.message.content)
                        }
                        variant="secondary"
                      >
                        <RiRestartLine /> Restart from this message
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </DialogBody>
          </Dialog>
        </div>
      )}
      <div className="ml-8 flex flex-wrap gap-4">
        {quickReplies
          .filter((reply) => reply.type == "preview")
          .map((reply, index) => (
            <Tooltip content={reply.tooltip} key={index}>
              <div
                className="max-w-80 text-ellipsis overflow-hidden border border-gray rounded-lg p-4 cursor-pointer bg-white dark:bg-black shadow-chat"
                onClick={() => {
                  if (reply.type == "preview") {
                    props.onOpenDocument &&
                      props.onOpenDocument(
                        reply.content,
                        reply.artefact_uuid ?? null,
                      );
                  }
                }}
              >
                <div className="relative max-h-40 overflow-hidden mb-2">
                  <div className="absolute inset-x-0 top-0 h-full bg-gradient-to-b from-transparent via-transparent to-white dark:to-black pointer-events-none"></div>
                  <div className="max-h-28 max-w-80">
                    <MarkdownRenderer text={reply.content} />
                  </div>
                </div>
                {/* <div className="w-full flex justify-end mr-2 bg-white dark:bg-black"> */}
                <Button
                  variant={"secondary"}
                  className="flex flex-row gap-2 rounded-full w-full"
                >
                  {reply.icon}
                  {reply.label}
                </Button>
              </div>
            </Tooltip>
          ))}
      </div>

      {showQuickReplies && (
        <>
          <div className="ml-8 flex flex-wrap mt-2 gap-2 ">
            {quickReplies
              .filter((reply) => reply.type == "action")
              .map((reply, index) => (
                <Tooltip content={reply.tooltip} key={index}>
                  <div className="max-w-80">
                    <Button
                      onClick={() => {
                        props.onQuickReplyAction &&
                          props.onQuickReplyAction(reply.action);
                      }}
                      variant={reply.variant ? reply.variant : "primary"}
                      className="w-full"
                    >
                      {reply.icon}
                      {reply.label}
                    </Button>
                  </div>
                </Tooltip>
              ))}
            {quickReplies
              .filter((reply) => reply.type == "form")
              .map((reply, index) => (
                <Tooltip content={reply.tooltip} key={index}>
                  <div className="max-w-80">
                    <Button
                      onClick={() => {
                        props.onQuickReplyForm &&
                          props.onQuickReplyForm(reply.fields);
                      }}
                      variant={reply.variant ? reply.variant : "primary"}
                      className="w-full"
                    >
                      {reply.icon}
                      {reply.label}
                    </Button>
                  </div>
                </Tooltip>
              ))}
          </div>
        </>
      )}
    </div>
  );
});
MessageComponent.displayName = "MessageComponent";
