import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { RiArrowRightSFill } from "react-icons/ri";
import { Button } from "./button";

type FloatingMenuProps = {
  content: React.ReactNode;
  children: React.ReactNode;
};
const mergeRefs = (...refs: any[]) => {
  return (node: any) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  };
};

export const FloatingSubMenu = React.forwardRef<
  HTMLDivElement,
  FloatingMenuProps
>(({ content, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const wrappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        onClose: () => setIsOpen(false),
        ref: mergeRefs(child.props.ref, ref, menuRef),
      });
    }
    return child;
  });

  const renderSubmenu = (children: React.ReactNode) => {
    if (!isOpen) return null;

    const menuRect = menuRef.current?.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const shouldAlignLeft = menuRect && menuRect.right + 200 > windowWidth; // 200 is estimated

    return ReactDOM.createPortal(
      <div
        className="fixed z-50"
        style={{
          left: shouldAlignLeft
            ? menuRect?.left
              ? menuRect.left - 200
              : 0
            : menuRect?.right,
          top: menuRect?.top,
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className="min-w-48 rounded-md shadow-lg bg-white dark:bg-black ring-1 ring-black ring-opacity-5 pointer-events-auto">
          {React.Children.map(children, (child) => (
            <div className="pointer-events-auto">{child}</div>
          ))}
        </div>
      </div>,
      document.body,
    );
  };
  return (
    <div
      ref={mergeRefs(ref, menuRef)}
      className="relative group flex flex-col"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Button variant="plain">
        <div className="w-full flex flex-row flex-grow-1 items-center justify-between font-normal text-sm">
          <div className="flex gap-2 items-center">{content}</div>
          <RiArrowRightSFill
            className={`transition-transform duration-200 ${isOpen ? "transform rotate-90" : ""}`}
          />
        </div>
      </Button>
      {renderSubmenu(wrappedChildren)}
    </div>
  );
});
FloatingSubMenu.displayName = "FloatingSubMenu";

type FloatingSubMenuItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
  onClose?: () => void;
};

export const FloatingSubMenuItem = React.forwardRef<
  HTMLDivElement,
  FloatingSubMenuItemProps & React.RefAttributes<HTMLDivElement>
>(({ children, onClick, onClose }, ref) => {
  const itemRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={mergeRefs(ref, itemRef)}>
      <div
        className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          onClick?.();
          onClose?.();
        }}
      >
        <div className="w-full flex flex-row flex-grow-1 items-center justify-between text-sm font-normal dark:text-white">
          <div className="flex gap-2 items-center">{children}</div>
        </div>
      </div>
    </div>
  );
});

FloatingSubMenuItem.displayName = "FloatingSubMenuItem";
type MenuRef = {
  element: HTMLDivElement;
  children: MenuRef[];
};
export const FloatingMenu = ({ content, children }: FloatingMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const menuHierarchy = useRef<MenuRef[]>([]);
  const registerMenuRef = (el: HTMLDivElement | null, path: number[]) => {
    if (!el) return;

    let current = menuHierarchy.current;
    for (let i = 0; i < path.length - 1; i++) {
      current = current[path[i]].children;
    }

    if (path.length === 0) {
      menuHierarchy.current.push({ element: el, children: [] });
    } else {
      current.push({ element: el, children: [] });
    }
  };

  const wrappedChildren = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClose: () => setIsMenuOpen(false),
        ref: (el: HTMLDivElement | null) => registerMenuRef(el, [index]),
        registerSubMenu: (el: HTMLDivElement | null, subIndex: number) =>
          registerMenuRef(el, [index, subIndex]),
      });
    }
    return child;
  });
  useEffect(() => {
    const isClickInMenuHierarchy = (
      event: MouseEvent,
      refs: MenuRef[],
    ): boolean => {
      for (const ref of refs) {
        if (ref.element.contains(event.target as Node)) return true;
        if (ref.children.length && isClickInMenuHierarchy(event, ref.children))
          return true;
      }
      return false;
    };
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current?.contains(event.target as Node) ||
        portalRef.current?.contains(event.target as Node) ||
        isClickInMenuHierarchy(event, menuHierarchy.current)
      ) {
        return;
      }
      setIsMenuOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const renderMenu = (wrappedChildren: React.ReactNode) => {
    if (!isMenuOpen) return null;
    const menuRect = menuRef.current?.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const shouldAlignLeft = menuRect && menuRect.right + 200 > windowWidth;

    return ReactDOM.createPortal(
      <div
        ref={portalRef}
        className="fixed z-50"
        style={{
          left: shouldAlignLeft
            ? menuRect?.right
              ? menuRect.right - 300
              : 0
            : (menuRect?.left ?? 0),
          top: (menuRect?.bottom ?? 0) + 8,
        }}
      >
        <div className="rounded-md shadow-lg bg-white dark:bg-black dark:text-white ring-1 ring-black ring-opacity-5 pointer-events-auto">
          <div
            className="py-1 pointer-events-auto"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {wrappedChildren}
          </div>
        </div>
      </div>,
      document.body,
    );
  };
  return (
    <div className="relative" ref={menuRef}>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setIsMenuOpen(!isMenuOpen);
        }}
        className="inline-flex items-center justify-center px-2 py-1.5 cursor-pointer hover:bg-zinc-950/5 dark:hover:bg-white/10 rounded-lg"
        role="button"
        tabIndex={0}
      >
        {content}
      </div>
      {isMenuOpen && renderMenu(wrappedChildren)}
    </div>
  );
};
