import { TriggerEmail } from "@/components/TriggerEmail";
import { TriggerSchedule } from "@/components/TriggerSchedule";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import Tooltip from "@/components/styled/Tooltip";
import { useAgents } from "@/contexts/AgentsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, Trigger } from "@/utils/types";
import { useCallback, useEffect, useState } from "react";
import { RiAddBoxFill } from "react-icons/ri";

type TriggersProps = {
  agent: Agent;
};

const Triggers = ({ agent }: TriggersProps) => {
  const [scheduleTriggers, setScheduleTriggers] = useState<Trigger[]>([]);
  const [emailTriggers, setEmailTriggers] = useState<Trigger[]>([]);
  const { agentWasRefreshed } = useAgents();

  const loadTriggers = useCallback(async () => {
    const triggers = await MarconipyApi.trigger.getAll(agent.uuid);
    setScheduleTriggers(
      triggers.filter((trigger) => trigger.trigger_type === "time"),
    );
    setEmailTriggers(
      triggers.filter((trigger) => trigger.trigger_type === "email"),
    );
  }, [agent.uuid]);

  const newScheduleTrigger = async () => {
    await MarconipyApi.trigger.create(agent.uuid, {
      trigger_type: "time",
      active: true,
      schedule_type: "daily",
      execution_time: "12:00",
    });
  };

  const handleAddScheduleTrigger = async () => {
    await newScheduleTrigger();
    await loadTriggers();
  };

  useEffect(() => {
    loadTriggers();
  }, [loadTriggers, agent.uuid, agentWasRefreshed]);

  return (
    <div>
      <div className="flex flex-col gap-2 mb-2">
        {scheduleTriggers.length === 0 && (
          <TriggerSchedule agent={agent} onRefresh={loadTriggers} />
        )}
        {scheduleTriggers.map((trigger) => (
          <TriggerSchedule
            key={trigger.uuid}
            agent={agent}
            trigger_uuid={trigger.uuid}
            onRefresh={loadTriggers}
          />
        ))}
        <Tooltip content="Add another schedule trigger">
          <Button variant="secondary" onClick={handleAddScheduleTrigger}>
            <RiAddBoxFill />
          </Button>
        </Tooltip>
      </div>
      <Divider />
      {emailTriggers.map((trigger) => (
        <TriggerEmail
          key={trigger.uuid}
          agent={agent}
          trigger_uuid={trigger.uuid}
          onRefresh={loadTriggers}
        />
      ))}
      {emailTriggers.length === 0 && (
        <TriggerEmail agent={agent} onRefresh={loadTriggers} />
      )}
    </div>
  );
};

export default Triggers;
