import { NameConventions } from "@/utils/NameConventions";
import { useState } from "react";
import {
  RiBloggerFill,
  RiNewsFill,
  RiThumbUpFill,
  RiYoutubeFill,
} from "react-icons/ri";
import { Switch } from "./design-system/switch";

type SourceSetTypeSelectionProps = {
  onChange?: (value: string[], add: boolean) => void;
  selected_types: string[];
};

type SourceSetType = {
  id: string;
  icon: JSX.Element;
};

export default function SourceSetTypeSelection({
  onChange,
  selected_types,
}: SourceSetTypeSelectionProps) {
  const [selectedTypes, setSelectedTypes] = useState<string[]>(selected_types);

  const types: SourceSetType[] = [
    { id: "blogs", icon: <RiBloggerFill /> },
    { id: "social", icon: <RiThumbUpFill /> },
    { id: "news", icon: <RiNewsFill /> },
    { id: "youtube", icon: <RiYoutubeFill /> },
  ];

  const handleToggle = (typeId: string) => {
    let new_selected_types = selectedTypes;
    if (new_selected_types.includes(typeId)) {
      new_selected_types = new_selected_types.filter((id) => id !== typeId);
    } else {
      new_selected_types.push(typeId);
    }
    setSelectedTypes(new_selected_types);
    if (onChange) {
      onChange(new_selected_types, true); //the parameter `add` removes the whole input, in this case the topic. but here we are editing ONLY the selected_type of an existing topic, so this has to be always true
    }
  };

  return (
    <div className="flex flex-wrap gap-4">
      {types.map((type) => (
        <div
          key={type.id}
          className="flex gap-2 items-center hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg p-2 hover:cursor-pointer"
          onClick={() => handleToggle(type.id)}
        >
          <Switch
            onChange={() => {}}
            checked={selectedTypes.includes(type.id)}
          />
          <span className="text-md">{type.icon}</span>
          <p className="text-sm">{NameConventions.toHuman(type.id)}</p>
        </div>
      ))}
    </div>
  );
}
