import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent } from "@/utils/types";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { RiCheckFill, RiDeleteBinFill, RiLoader2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Label } from "./design-system/fieldset";
import { Input } from "./design-system/input";
import { Switch, SwitchField } from "./design-system/switch";
import CopyEmailButton from "./styled/CopyEmailButton";
import Tooltip from "./styled/Tooltip";

type TriggerEmailProps = {
  agent: Agent;
  trigger_uuid?: string;
  onRefresh?: () => void;
};

export function TriggerEmail({
  agent,
  trigger_uuid,
  onRefresh,
}: TriggerEmailProps) {
  const posthog = usePostHog();
  const [userEmail, setUserEmail] = useState("loading...");
  const [isActive, setIsActive] = useState(false);
  const [triggerPhrase, setTriggerPhrase] = useState(agent.name);
  const [anychangesMade, setAnyChangesMade] = useState(false);

  const handleTriggerPhraseChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTriggerPhrase(event.target.value);
    setAnyChangesMade(true);
  };
  const handleToggle = () => {
    if (isActive) {
      posthog.capture("agentEdit: toggle trigger off", {
        source: "agent trigger",
        type: "email",
      });
    } else {
      posthog.capture("agentEdit: toggle trigger on", {
        source: "agent trigger",
        type: "email",
      });
    }
    setIsActive((value) => !value);
    handleSave(!isActive);
  };

  const handleSave = async (force_active?: boolean) => {
    let active = isActive;
    if (force_active === true || force_active === false) {
      active = force_active;
    }
    if (!trigger_uuid) {
      const trigger = await MarconipyApi.trigger.create(agent.uuid, {
        trigger_type: "email",
        active,
      });
      trigger_uuid = trigger.uuid;
    }
    if (active) {
      posthog.capture("agentEdit: edit slack trigger", {
        source: "agent triggers",
        type: "email",
      });
    } else {
      posthog.capture("agentEdit: deactivate slack trigger", {
        source: "agent triggers",
        type: "email",
      });
    }
    await MarconipyApi.trigger.update(agent.uuid, trigger_uuid, {
      active: active,
      trigger_type: "email",
      metadata: { trigger_phrase: triggerPhrase },
    });

    setAnyChangesMade(false);
    if (active && !force_active) {
      toast.success(`Saved`);
    }
    onRefresh && onRefresh();
  };
  const deleteTrigger = async () => {
    if (!trigger_uuid) return;
    await MarconipyApi.trigger.delete(agent.uuid, trigger_uuid);
    onRefresh && onRefresh();
  };

  useEffect(() => {
    const loadTrigger = async () => {
      if (trigger_uuid) {
        const triggers = await MarconipyApi.trigger.getAll(agent.uuid);
        const fchannel = triggers.find((c) => c.uuid === trigger_uuid);
        if (!fchannel) return;
        setIsActive(fchannel.active);
        if (fchannel.metadata && fchannel.metadata["trigger_phrase"]) {
          setTriggerPhrase(fchannel.metadata["trigger_phrase"]);
        }
      }
    };
    loadTrigger();
  }, [agent.uuid, trigger_uuid]);

  useEffect(() => {
    const loadUserEmail = async () => {
      const user = await MarconipyApi.user.me();
      setUserEmail(user.email);
    };
    loadUserEmail();
  }, []);

  const changeEmailRequest = async () => {
    showNewMessage(
      "Hey team! I would like to change the email address for my TailorTask trigger.",
    );
  };

  return (
    <div className="">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Label className="font-semibold">✉️ Trigger with email</Label>
          <Switch onChange={() => handleToggle()} checked={isActive} />
        </SwitchField>
        {trigger_uuid && isActive && (
          <div className="ml-4">
            <Tooltip content="Delete Slack trigger">
              <Button variant="secondary" onClick={deleteTrigger}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm mb-2">
        {isActive ? (
          <>
            <RiCheckFill className="text-tertiary mr-1" />
            Trigger is active
          </>
        ) : (
          <>
            <RiLoader2Fill className="opacity-50 mr-1" />
            Trigger is inactive
          </>
        )}
      </div>

      {isActive && (
        <div className="mt-4 items-start">
          <div className="items-start flex flex-col space-y-2 mb-4">
            <p>This agent will be triggered when you send an email to:</p>
            <CopyEmailButton email="tt@tailortask.ai" variant="secondary" />
            {/* https://docs.google.com/document/d/1THTZOmjhhASBpG8VGa8J9FshYy07sXWyvPTiDezwItU/edit */}
            <p className="text-sm">
              From your email address <strong>{userEmail}</strong>{" "}
              <a
                className="text-secondary-dark underline hover:no-underline"
                href="#"
                onClick={changeEmailRequest}
              >
                (Change)
              </a>
            </p>
          </div>
          <div className="flex items-center my-2">
            <Tooltip content="If you say this phrase to your TailorTask slack bot, it will trigger this agent">
              <p className="text-sm">Trigger when you say:</p>
            </Tooltip>
          </div>
          <div className="flex items-center">
            <Input
              type="text"
              id="triggerPhrase"
              value={triggerPhrase}
              onChange={handleTriggerPhraseChange}
              placeholder="Enter trigger phrase"
            />
          </div>
        </div>
      )}
      {anychangesMade && (
        <div className="mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              handleSave();
            }}
          >
            Save changes
          </Button>
        </div>
      )}
    </div>
  );
}
