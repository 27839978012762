export const Footer = () => {
  const aclassName =
    "text-sm underline hover:no-underline hover:opacity-100 opacity-75 px-2";

  return (
    <div className="col-span-12 w-full">
      <div className="flex flex-wrap justify-between items-center max-w-7xl mx-auto px-4 py-2 md:px-6 lg:px-8">
        <a className={aclassName} href="/featured">
          Featured
        </a>
        <a className={aclassName} href="/featured/archive">
          Archive
        </a>
        <a className={aclassName} href="/contact-us">
          Contact us
        </a>
        <a className={aclassName} href="/ai-news">
          AI News
        </a>
        <a className={aclassName} href="/principles">
          Our Principles
        </a>
        <a
          className={aclassName}
          href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          Tutorials
        </a>
        <a className={aclassName} href="/careers">
          Careers
        </a>
        <a className={aclassName} href="/terms-of-use">
          Terms of use
        </a>
        <a className={aclassName} href="/privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
