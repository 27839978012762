import { SourceItem, SourceSet, Topic } from "../types";
import BaseApi from "./base";

interface TopicSourceSetsByAgent {
  topic: Topic;
  sourcesets: SourceSet[];
}

export default class SourceApi extends BaseApi {
  static async getSourceSet(uuid: string): Promise<SourceSet> {
    return this.authenticatedGetApiCall<SourceSet>(`/sourcesets/${uuid}/`);
  }
  static async batchGetSourceSets(uuids: string[]): Promise<SourceSet[]> {
    return this.authenticatedGetApiCall<SourceSet[]>(
      `/sourcesets/bulk-retrieve/?uuids=${uuids.join(",")}`,
    );
  }

  static async getSourceItem(uuid: string): Promise<SourceItem> {
    return this.authenticatedGetApiCall<SourceItem>(`/sourceitems/${uuid}/`);
  }

  static async batchGetSourceItems(uuids: string[]): Promise<SourceItem[]> {
    return this.authenticatedGetApiCall<SourceItem[]>(
      `/sourceitems/bulk-retrieve/?uuids=${uuids.join(",")}`,
    );
  }

  static async searchSourceSetAutocomplete(
    query: string,
  ): Promise<SourceSet[]> {
    return this.authenticatedPostApiCall<SourceSet[]>(
      `/sourcesets/search/autocomplete/`,
      {
        query,
      },
    );
  }
  static async searchSourceSetByLink(url: string): Promise<SourceSet> {
    return this.authenticatedPostApiCall<SourceSet>(
      `/sourcesets/search/by_link/`,
      {
        url,
      },
    );
  }

  static async uploadSourcesetsCSV(
    agentUUID: string,
    tool_key: string,
    formData: FormData,
  ): Promise<void> {
    return this.authenticatedFormPostApiCall(
      `/sourcesets/upload_csv/?tool_key=` +
        tool_key +
        "&agent_uuid=" +
        agentUUID,
      formData,
    );
  }

  static async getTopic(uuid: string): Promise<Topic> {
    return this.authenticatedGetApiCall<Topic>(`/topics/${uuid}/`);
  }

  static async getTopicSourceSetsByAgent(
    topicUUID: string,
    agentUUID: string | null = null,
  ): Promise<TopicSourceSetsByAgent> {
    return this.authenticatedGetApiCall<TopicSourceSetsByAgent>(
      `/agents/${agentUUID}/topics/${topicUUID}/sourcesets/`,
    );
  }

  static async batchGetTopics(uuids: string[]): Promise<Topic[]> {
    return this.authenticatedGetApiCall<Topic[]>(
      `/topics/bulk-retrieve/?uuids=${uuids.join(",")}`,
    );
  }

  static async searchTopic(query: string): Promise<Topic> {
    return this.authenticatedPostApiCall<Topic>(`/topics/search/`, {
      query,
    });
  }
  static async searchTopicAutocomplete(query: string): Promise<Topic[]> {
    return this.authenticatedPostApiCall<Topic[]>(
      `/topics/search/autocomplete/`,
      {
        query,
      },
    );
  }
}
