import { OutputChannelEmail } from "@/components/OutputChannelEmail";
import { OutputChannelSlack } from "@/components/OutputChannelSlack";
import { OutputChannelZapier } from "@/components/OutputChannelZapier";
import { Divider } from "@/components/design-system/divider";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, OutputChannel } from "@/utils/types";
import React, { useCallback, useEffect, useState } from "react";

type OutputChannelsProps = {
  agent: Agent;
  onAgentRefresh?: () => void;
  onOutputConfirmed?: (step: string) => void;
};

const OutputChannels: React.FC<OutputChannelsProps> = ({ agent }) => {
  const [emailChannels, setEmailChannels] = useState<OutputChannel[]>([]);
  const [slackChannels, setSlackChannels] = useState<OutputChannel[]>([]);
  const [zapierChannels, setZapierChannels] = useState<OutputChannel[]>([]);

  const loadChannels = useCallback(async () => {
    const output_channels = await MarconipyApi.outputChannel.getAll(agent.uuid);
    setEmailChannels(
      output_channels.filter((oc) => oc.channel_type === "email"),
    );
    setSlackChannels(
      output_channels.filter((oc) => oc.channel_type === "slack"),
    );
    setZapierChannels(
      output_channels.filter((oc) => oc.channel_type === "zapier"),
    );
  }, [agent.uuid]);

  useEffect(() => {
    loadChannels();
  }, [loadChannels, agent.uuid]);

  return (
    <>
      {emailChannels.map((channel) => (
        <OutputChannelEmail
          key={channel.uuid}
          agent={agent}
          output_channel_uuid={channel.uuid}
          onRefresh={loadChannels}
        />
      ))}
      {emailChannels.length === 0 && (
        <OutputChannelEmail agent={agent} onRefresh={loadChannels} />
      )}
      <Divider />
      {slackChannels.map((channel) => (
        <OutputChannelSlack
          key={channel.uuid}
          agent={agent}
          output_channel_uuid={channel.uuid}
          onRefresh={loadChannels}
        />
      ))}
      {slackChannels.length === 0 && (
        <OutputChannelSlack agent={agent} onRefresh={loadChannels} />
      )}
      <Divider />
      {zapierChannels.map((channel) => (
        <OutputChannelZapier
          key={channel.uuid}
          agent={agent}
          output_channel_uuid={channel.uuid}
          onRefresh={loadChannels}
        />
      ))}
      {zapierChannels.length === 0 && (
        <OutputChannelZapier agent={agent} onRefresh={loadChannels} />
      )}
    </>
  );
};

export default OutputChannels;
