import { Button } from "./design-system/button";

interface QuickReplyBubblesProps {
  onSendMessage: (message: string) => void;
}

const examplePrompts = [
  "Keep track of my competitors",
  "Come up with ideas for Linkedin every day",
  "Monitor what people are saying in my industry",
  "Write a SEO optimized blog post based on a query",
];

const QuickReplyBubbles: React.FC<QuickReplyBubblesProps> = ({
  onSendMessage,
}) => {
  return (
    <>
      {/* <div className="flex flex-wrap gap-2"> */}
      {examplePrompts.map((prompt, index) => (
        <Button
          key={index}
          variant="basic"
          outline={true}
          onClick={() => onSendMessage(prompt)}
        >
          {prompt}
        </Button>
      ))}
    </>
  );
};

export default QuickReplyBubbles;
