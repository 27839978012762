import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, TextDocumentInput } from "@/utils/types";
import { useEffect, useRef, useState } from "react";
import { RiCloseLine, RiLoader2Line } from "react-icons/ri";

type TextDocumentInputPartialViewProps = {
  agent: Agent;
  tool_key: string;
  onRefresh?: () => void;
};

const TextDocumentInputPartialView = ({
  agent,
  tool_key,
  onRefresh,
}: TextDocumentInputPartialViewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedDocuments, setUploadedDocuments] =
    useState<TextDocumentInput[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFilePicker = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    localStorage.setItem("redirectUrl", window.location.href);
  }, []);

  useEffect(() => {
    if (!agent.artefact || !agent.artefact.inputs) return;

    const inputs = agent.artefact.inputs;
    if (
      inputs.textdocument &&
      inputs.textdocument.length > 0 &&
      inputs.textdocument.find((s) => s.tool_key === tool_key)
    ) {
      const foundDocuments = inputs.textdocument.filter(
        (s) => s.tool_key === tool_key,
      );
      setUploadedDocuments(foundDocuments);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [tool_key, agent, setUploadedDocuments]);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file size (5MB = 5 * 1024 * 1024 bytes)
    if (file.size > 5 * 1024 * 1024) {
      alert("File size exceeds 5MB limit");
      return;
    }

    // Add check for empty files
    if (file.size === 0) {
      alert("Cannot upload empty files");
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await MarconipyApi.input.uploadFile(agent.uuid, tool_key, formData);
      fileInputRef.current!.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleRemoveDocument = async (uuid: string) => {
    setIsLoading(true);
    try {
      await MarconipyApi.input.remove(agent.uuid, uuid, tool_key);
      setUploadedDocuments((prev) =>
        prev?.filter((doc) => doc.object.uuid !== uuid),
      );
      if (onRefresh) onRefresh();
    } catch (error) {
      console.error("Error removing file:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileUpload}
          accept=".txt,.doc,.docx,.pdf"
          className="hidden"
          id="file-upload"
        />
        <Button
          variant={uploadedDocuments && uploadedDocuments.length == 0 ? "primary" : "secondary"}
          disabled={isLoading}
          onClick={openFilePicker}
          className="cursor-pointer"
        >
          {isLoading ? (
            <>
              <RiLoader2Line className="animate-spin" /> Loading
            </>
          ) : (
            "Upload Document"
          )}
        </Button>
      </div>

      {uploadedDocuments && uploadedDocuments.length > 0 && (
        <div className="space-y-2">
          <h3 className="text-sm font-medium">Uploaded Documents</h3>
          <div className="space-y-2">
            {uploadedDocuments.map((doc) => (
              <div
                key={doc.object.uuid}
                className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-950 rounded"
              >
                <span className="text-sm">{doc.object.name}</span>
                <Button
                  variant="plain"
                  size="sm"
                  onClick={() => handleRemoveDocument(doc.object.uuid)}
                >
                  <RiCloseLine className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TextDocumentInputPartialView;
