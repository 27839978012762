import { Loading } from "@/components/Loading";
import ResultExplorer from "@/components/ResultExplorer";
import MarconipyApi from "@/utils/marconipyApi";
import { Message, Run, Tool } from "@/utils/types";
import { useEffect, useState } from "react";

type RunPartialViewProps = {
  agentUUID: string;
  run: Run;
};

const RunPartialView = ({ agentUUID, run }: RunPartialViewProps) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);

  useEffect(() => {
    const getMessagesAndTools = async () => {
      const messages = await MarconipyApi.run.getMessages(run.uuid);
      const tools = await MarconipyApi.agent.getAllTools(agentUUID);
      setTools(tools);
      setMessages(messages);
      setLoading(false);
    };
    getMessagesAndTools();
  }, [agentUUID, run]);

  if (messages.length == 0) return <Loading />;

  return (
    <div className="mb-8 max100vh">
      {loading && (
        <div className="flex justify-center">
          <Loading />
        </div>
      )}
      {!loading && (
        <ResultExplorer
          messages={messages}
          tools={tools}
          artefact={run.artefact}
        />
      )}
    </div>
  );
};

export default RunPartialView;
