import LoginForm from "@/components/auth/LoginForm";
import NavigationBarUnlogged from "@/components/NavigationBarPublic";
import Card from "@/components/styled/Card";
import Container from "@/components/styled/Container";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const additionalMessage = localStorage.getItem("additionalMessage") || false;

  return (
    <>
      <Helmet>
        <title>Login - TailorTask</title>
        <meta
          name="description"
          content="Login to access your TailorTask account. Get an A.I. to automate your most boring tasks."
        />
        <meta property="og:title" content="Login - Tailor" />
        <meta
          property="og:description"
          content="Login to access your TailorTask account. Get an A.I. to automate your most boring tasks."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://app.tailortask.ai/login" />
      </Helmet>
      <NavigationBarUnlogged variant="login" />
      <div className="h-full col-span-12 sm:col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 grid grid-cols-12">
        <Container>
          {additionalMessage && (
            <p className="bg-primary-light rounded-lg text-black text-left text-sm w-full p-2">
              {additionalMessage}
            </p>
          )}
          <Card className="flex flex-col gap-2 py-4">
            <h1>Login</h1>
            <LoginForm
              onSignupRedirect={() => navigate("/signup")}
              onForgottenPasswordRedirect={() => navigate("/forgot-password")}
            />
          </Card>
        </Container>
      </div>
    </>
  );
}

export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.auth.isAuthenticated();
  if (loggedIn) {
    console.log("User is already logged in");
    return redirect("/");
  } else {
    return false;
  }
}
