// TailorErrorBoundary.tsx
import { Divider } from "@/components/design-system/divider";
import { Footer } from "@/components/Footer";
import { LoadingError } from "@/components/LoadingError";
import Card from "@/components/styled/Card";
import SVGBackground from "@/components/styled/SVGBackground";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useRouteError } from "react-router-dom";

interface BusyBadgerErrorBoundaryProps {
  children?: React.ReactNode;
  error?: any;
}

export const BusyBadgerErrorBoundary: React.FC<
  BusyBadgerErrorBoundaryProps
> = ({ children, error }) => {
  let throwerror = useRouteError() || error;

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [networkError, setNetworkError] = React.useState<boolean>(false);

  const defineErrorMessage = (error: any): string => {
    switch (error.code) {
      case "ERR_NETWORK":
        setNetworkError(true);
        return "Network error";
      case "UNAUTHORIZED":
        return "Unauthorized";
      default:
        return error.message;
    }
  };

  React.useEffect(() => {
    if (throwerror) {
      setErrorMessage(defineErrorMessage(throwerror));
    }
  }, [throwerror]);

  return (
    <ErrorBoundary
      fallback={<h1>⚠️Something went wrong while handling an exception</h1>}
    >
      <div className="relative flex flex-col md:flex-row h-screen w-screen">
        <div
          id="main-container"
          className="relative font-sans tracking-wide text-black dark:text-white overflow-hidden h-full w-full pt-16"
        >
          <div className="static grid grid-cols-12 overflow-hidden h-full">
            <div className="col-span-10 col-start-2 md:col-span-7 md:col-start-3">
              <Card className="mb-20 p-4 text-center">
                {!networkError && (
                  <>
                    <LoadingError />
                    <h1 className="mt-8 mb-4">There was an error</h1>
                    <p className="mb-10">{errorMessage}</p>
                  </>
                )}
                {networkError && (
                  <>
                    <LoadingError />
                    <h1 className="mt-8 mb-4">Connection problem</h1>
                    <p className="mb-10">
                      There is a problem connecting to our servers. Please check
                      your connection and try again.
                    </p>
                  </>
                )}
                {children}
              </Card>
            </div>
          </div>
          <Divider />
          <Footer />
          <SVGBackground />
        </div>
      </div>
    </ErrorBoundary>
  );
};
