import { Button } from "@/components/design-system/button";
import { Heading } from "@/components/design-system/heading";
import CopyEmailButton from "@/components/styled/CopyEmailButton";
import { show } from "@intercom/messenger-js-sdk";
import { RiBook2Fill, RiMessage2Fill, RiYoutubeFill } from "react-icons/ri";

const HelpPanel = () => {
  return (
    <div>
      <Heading>Need Help?</Heading>

      <div className="flex flex-col gap-6 my-2">
        <div className="flex items-center">
          <RiBook2Fill className="w-6 h-6 mr-4 text-tertiary-dark" />
          <div>
            <h3 className="text-lg font-semibold">Documentation</h3>
            <p className="text-gray-600 dark:text-white">
              Find detailed guides and tutorials
            </p>
            <a
              href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=73"
              target="_blank"
              className="text-primary-dark dark:text-primary-light hover:underline"
              rel="noreferrer"
            >
              Visit Documentation
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <RiYoutubeFill className="w-6 h-6 mr-4 text-primary" />
          <div>
            <h3 className="text-lg font-semibold">Video Tutorials</h3>
            <p className="text-gray-600 dark:text-white">
              Watch step-by-step video guides
            </p>
            <a
              href="https://www.youtube.com/channel/UCXw-jB1hgaP99Q5PiIszMPg"
              target="_blank"
              className="text-primary-dark dark:text-primary-light hover:underline"
              rel="noreferrer"
            >
              Visit YouTube Channel
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <RiMessage2Fill className="w-6 h-6 mr-4 text-secondary-dark" />
          <div>
            <h3 className="text-lg font-semibold">Contact Support</h3>
            <p className="text-gray-600 dark:text-white mb-2">
              Get help from our support team
            </p>
            <div className="flex flex-col gap-2 justify-start">
              <Button onClick={() => show()} variant="secondary">
                <RiMessage2Fill />
                Ask in chat
              </Button>
              <CopyEmailButton email="info@tailortask.ai" variant="secondary" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mt-8 text-center">
        <p className="text-gray-600 dark:text-white">
          Can't find what you're looking for? 
          <a href="https://tailortask.com/faq" className="ml-1  hover:underline">
            Check our FAQ
          </a>
        </p>
      </div> */}
    </div>
  );
};

export default HelpPanel;
