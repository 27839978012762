import MarconipyApi from "@/utils/marconipyApi";
import { Agent, Tool } from "@/utils/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAgents } from "@/contexts/AgentsContext";
import { isEqual } from "lodash";
import { usePostHog } from "posthog-js/react";
const useTools = ({
  agent,
  onToolsConfirmed,
}: {
  agent: Agent | null;
  onToolsConfirmed?: (step: string) => void;
  onAgentRefresh?: () => void;
}): {
  isLoading: boolean;
  currentTool: Tool | undefined;
  currentToolIndex: number;
  tools: Tool[];
  selectedTools: string[];
  handleToolSelection: (toolKey: string, proceed?: boolean) => void;
  openSetupModalAtTool: (tool?: Tool) => void;
  isToolSetupDialogOpen: boolean;
  openToolSetupDialog: () => void;
  closeToolSetupDialog: () => void;
  allToolsRequiringUserInputs: Tool[];
  toolsThatNeedSetup: Tool[];
  isStepCompleted: boolean;
  handleConfirmTools: () => void;
  navigateTools: (direction: "next" | "prev") => void;
  isSaving: boolean;
  error: string | null;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [tools, setTools] = useState<Tool[]>([]);
  const [selectedTools, setSelectedTools] = useState<string[]>(
    agent?.selected_tools_for_run || [],
  );
  const [selectedToolDialogOpenKey, setSelectedToolDialogOpenKey] = useState<
    string | null
  >(null);
  const [toolSetupDialogOpen, setToolSetupDialogOpen] =
    useState<boolean>(false);
  const [toolsThatNeedSetup, setToolsThatNeedSetup] = useState<Tool[]>([]);
  const [allToolsRequiringUserInputs, setAllToolsRequiringUserInputs] =
    useState<Tool[]>([]);
  const [currentToolIndex, setCurrentToolIndex] = useState<number>(0);
  const { updateCurrentAgent } = useAgents();
  const selectedToolsRef = React.useRef<string[]>();
  const artefactInputsRef = React.useRef<any>();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const posthog = usePostHog();
  const [toolsHaveChanged, setToolsHaveChanged] = useState(false);
  const isStepCompleted =
    !toolsHaveChanged &&
    agent?.metadata?.steps_status?.["tools"] === "completed";

  const handleConfirmTools = useCallback(async () => {
    if (!agent) {
      return;
    }
    setIsSaving(true);
    posthog.capture(`agentEdit: confirmation tools clicked`, {
      source: "agent view",
    });
    setError(null);
    try {
      setIsSaving(true);
      await updateCurrentAgent(agent, {
        metadata: { tools: true },
      });
      onToolsConfirmed && onToolsConfirmed("tools");
      setIsSaving(false);
    } catch (err) {
      setError("An error occurred");
      console.error("Error updating agent:", err);
    } finally {
      setIsSaving(false);
    }
  }, [onToolsConfirmed, posthog, updateCurrentAgent, agent]);

  useEffect(() => {
    const loadTools = async () => {
      if (!agent) {
        return;
      }
      const tools = await MarconipyApi.agent.getAvailableTools(agent.uuid);
      setTools(tools);
      setIsLoading(false);
      //validate tools
      let localInvalidTools: Tool[] = [];
      let allToolsRequiringUserInputs: Tool[] = [];
      tools.forEach((tool) => {
        if (agent.selected_tools_for_run.includes(tool.key)) {
          if (!tool.inputs_are_valid) {
            localInvalidTools.push(tool);
          }
          if (Object.entries(tool.user_inputs).length > 0) {
            allToolsRequiringUserInputs.push(tool);
          }
        }
      });
      setAllToolsRequiringUserInputs(allToolsRequiringUserInputs);
      setToolsThatNeedSetup(localInvalidTools);
      if (localInvalidTools.length > 0) {
        setSelectedToolDialogOpenKey(localInvalidTools[0]?.key);
      }
    };
    if (!agent) {
      return;
    }
    if (
      tools.length == 0 ||
      (selectedToolsRef.current &&
        !isEqual(selectedToolsRef.current, agent.selected_tools_for_run)) ||
      (artefactInputsRef.current &&
        agent.artefact &&
        agent.artefact.inputs &&
        !isEqual(artefactInputsRef.current, agent.artefact.inputs))
    ) {
      loadTools();
      selectedToolsRef.current = agent.selected_tools_for_run;
      if (agent.artefact && agent.artefact.inputs) {
        artefactInputsRef.current = agent.artefact.inputs;
      }
    }
  }, [agent, selectedTools, tools]);

  const handleToolSelection = useCallback(
    async (toolKey: string) => {
      if (!agent) {
        return;
      }
      const updatedSelectedTools = selectedTools.includes(toolKey)
        ? selectedTools.filter((key) => key !== toolKey)
        : [...selectedTools, toolKey];

      setSelectedTools(updatedSelectedTools);

      try {
        await updateCurrentAgent(agent, {
          selected_tools_for_run: updatedSelectedTools,
        });
      } catch (error) {
        console.error("Failed to update selected tools:", error);
        // Revert the selection if the API call fails
        setSelectedTools(selectedTools);
      }
      setToolsHaveChanged(true);
    },
    [selectedTools, updateCurrentAgent, agent],
  );

  const openSetupModalAtTool = useCallback(
    (tool: Tool | undefined = undefined) => {
      if (tool) {
        setSelectedToolDialogOpenKey(tool.key);
      } else {
        let firstTool = allToolsRequiringUserInputs.find(
          (tool) => !tool.inputs_are_valid,
        );
        if (!firstTool) {
          console.error("No tools that need setup found");
          return;
        }
        setSelectedToolDialogOpenKey(firstTool.key);
      }
      setToolSetupDialogOpen(true);
    },
    [allToolsRequiringUserInputs],
  );

  const navigateTools = useCallback(
    (direction: "next" | "prev") => {
      let newIndex = 0;
      if (direction === "next") {
        if (currentToolIndex < allToolsRequiringUserInputs.length - 1) {
          newIndex = currentToolIndex + 1;
        }
      } else {
        if (currentToolIndex > 0) {
          newIndex = currentToolIndex - 1;
        }
      }
      setCurrentToolIndex(newIndex);
      setSelectedToolDialogOpenKey(allToolsRequiringUserInputs[newIndex].key);
    },
    [allToolsRequiringUserInputs, currentToolIndex],
  );

  const currentTool = useMemo(
    () => tools.find((t) => t.key === selectedToolDialogOpenKey),
    [selectedToolDialogOpenKey, tools],
  );

  const openToolSetupDialog = useCallback(
    () => setToolSetupDialogOpen(true),
    [],
  );

  const closeToolSetupDialog = useCallback(
    () => setToolSetupDialogOpen(false),
    [],
  );

  useEffect(() => {
    setSelectedTools(agent?.selected_tools_for_run || []);
  }, [agent?.selected_tools_for_run]);

  return {
    isLoading,
    currentTool,
    currentToolIndex,
    tools,
    selectedTools,
    handleToolSelection,
    openSetupModalAtTool,
    isToolSetupDialogOpen: toolSetupDialogOpen,
    openToolSetupDialog,
    closeToolSetupDialog,
    allToolsRequiringUserInputs,
    toolsThatNeedSetup,
    isStepCompleted,
    handleConfirmTools,
    navigateTools,
    isSaving,
    error,
  };
};

export default useTools;
