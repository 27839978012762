import { useEffect } from "react";

const useBodyClasses = (classesToRemove: string[]) => {
  useEffect(() => {
    const body = document.body;
    const originalClasses = body.className.split(" ");

    // Remove specified classes
    classesToRemove.forEach((classToRemove) => {
      body.classList.remove(classToRemove);
    });

    // Cleanup function to restore original classes
    return () => {
      body.className = originalClasses.join(" ");
    };
  }, [classesToRemove]);
};

export default useBodyClasses;
