import clsx from "clsx";
import { type ReactNode } from "react";

type Size = "sm" | "md" | "lg";

const sizeStyles = {
  sm: "w-6 h-6",
  md: "w-8 h-8",
  lg: "w-10 h-10",
};

const indicatorSizes = {
  sm: "w-2 h-2",
  md: "w-2.5 h-2.5",
  lg: "w-3 h-3",
};

type AgentIconProps = {
  icon: ReactNode;
  variant?: "primary" | "secondary" | "tertiary";
  showActiveIndicator?: boolean;
  isActive?: boolean;
  size?: Size;
  showBorder?: boolean;
};

export default function AgentIcon({
  icon,
  variant = "primary",
  showActiveIndicator = false,
  isActive = false,
  size = "sm",
  showBorder = true,
}: AgentIconProps) {
  const bgVariants = {
    primary: "bg-primary/15",
    secondary: "bg-secondary/10",
    tertiary: "bg-tertiary/15",
  };

  return (
    <div className="relative inline-flex">
      <div
        className={clsx(
          "flex rounded-lg items-center justify-center",
          showBorder && "ring-1 ring-zinc-950/5 dark:ring-white/10",
          sizeStyles[size],
          bgVariants[variant],
        )}
      >
        <div
          className={clsx({
            "scale-100": size === "sm",
            "scale-125": size === "md",
            "scale-150": size === "lg",
          })}
        >
          {icon}
        </div>
      </div>

      {showActiveIndicator && (
        <div
          className={clsx(
            "absolute bottom-0 right-0 rounded-full border",
            indicatorSizes[size],
            {
              "bg-green border-white": isActive,
              "bg-white border-black dark:bg-black dark:border-white":
                !isActive,
            },
          )}
        />
      )}
    </div>
  );
}
