import { Button } from "@/components/design-system/button";
import { useAgents } from "@/contexts/AgentsContext";
import { useEventContext } from "@/contexts/EventsContext";
import { useTeams } from "@/contexts/TeamContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, RightSidePanelOptions, SelectOption } from "@/utils/types";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import clsx from "clsx";
import Cookies from "js-cookie";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import {
  RiAddLargeLine,
  RiArrowLeftFill,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiEditLine,
  RiEyeFill,
  RiFileCopyLine,
  RiFileFill,
  RiFolderTransferFill,
  RiMenuFill,
  RiShareLine,
} from "react-icons/ri";
import { toast } from "react-toastify";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "./design-system/alert";
import { Badge } from "./design-system/badge";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "./design-system/dialog";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
} from "./design-system/dropdown";
import {
  FloatingMenu,
  FloatingSubMenu,
  FloatingSubMenuItem,
} from "./design-system/floating-menu";
import { Input } from "./design-system/input";
import { Navbar, NavbarDivider, NavbarSpacer } from "./design-system/navbar";
import { Select } from "./design-system/select";
import { StackedLayout } from "./design-system/stacked-layout";
import { Text } from "./design-system/text";
import AgentIcon from "./styled/AgentIcon";
import AnimatedText from "./styled/AnimatedText";
import Logo from "./styled/Logo";
import Tooltip from "./styled/Tooltip";

const ADVANCED_MODE_COOKIE = "tailortask_advanced_mode";
type SetupSidePanelProps = {
  children: React.ReactNode;
  agent: Agent;
  activeSidePanelTab: RightSidePanelOptions;
  switchSidePanelTab: (panel: RightSidePanelOptions) => void;
  onNewRun: () => void;
  newAnimatedName?: string;
};
export function AgentNavigationBar({
  children,
  agent,
  activeSidePanelTab: activeSidePanelTab,
  switchSidePanelTab: switchSidePanel,
  newAnimatedName,
}: SetupSidePanelProps) {
  const { selectedTeam } = useTeams();
  const [isEditing, setIsEditing] = useState(false);
  const [agentName, setAgentName] = useState(agent.name);
  const { emit } = useEventContext(); // Use the event context
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const posthog = usePostHog();
  //move agent variables
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);
  const [selectedTeamUUID, setSelectedTeamUUID] = useState<string>("default");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [shareTab, setShareTab] = useState<string>("share");

  const {
    updateCurrentAgent,
    deleteAgent,
    cloneAgent,
    currentConversation,
    navigateToEditMode,
  } = useAgents();

  const editMode = currentConversation?.edit_mode;
  const sendInvite = async (email: string) => {
    if (!selectedTeam) return;
    try {
      await MarconipyApi.team.inviteTeamMember(selectedTeam.uuid, email);
      setInviteEmail("");
      toast.success("Invite sent!");
    } catch (error) {
      console.error("Error inviting team member:", error);
      toast.error("Team member already exists");
      // Handle error (e.g., show error message to user)
    }
  };
  useEffect(() => {
    const savedMode = Cookies.get(ADVANCED_MODE_COOKIE);
    if (savedMode !== undefined) {
      const isAdvanced = savedMode == "advanced";
      setIsAdvancedMode(isAdvanced);
    }
    const savedTab = Cookies.get("tt_tab_" + agent.uuid);
    if (savedTab !== undefined && editMode) {
      switchSidePanel(savedTab as RightSidePanelOptions);
    }
  }, [editMode, switchSidePanel, agent.uuid]);

  useEffect(() => {
    Cookies.set("tt_tab_" + agent.uuid, activeSidePanelTab);
  }, [activeSidePanelTab, agent.uuid]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentName(e.target.value);
  };

  const handleNameSubmit = async () => {
    posthog.capture("agentEdit: edit agent name", {
      source: "agent navigation bar",
    });
    setIsEditing(false);
    await updateCurrentAgent(agent, { name: agentName });
  };

  const handleNewAgent = async () => {
    // navigate("/agents/new");
    window.location.href = "/agents/new";
  };
  const handleModeToggle = (newMode: boolean) => {
    posthog.capture("view mode toggle", { to: newMode ? "advanced" : "basic" });
    setIsAdvancedMode(newMode);
    Cookies.set(ADVANCED_MODE_COOKIE, newMode ? "advanced" : "basic");
    emit({ type: "VIEW_MODE_CHANGED", payload: { advanced: newMode } });
    window.location.reload();
  };

  const handleMoveAgent = async () => {
    posthog.capture("agentEdit: move agent", {
      source: "agent navigation bar",
    });
    const teams = await MarconipyApi.team.getAll();
    const filteredTeams = teams.map((team) => ({
      label:
        team.uuid == selectedTeam?.uuid ? team.name + " (current)" : team.name,
      value: team.uuid,
    }));
    setTeamOptions(filteredTeams);
    setSelectedTeamUUID(filteredTeams[0].value);
    setShowMoveModal(true);
  };
  const handleMoveSave = async () => {
    posthog.capture("agentEdit: save move agent", {
      source: "agent navigation bar",
    });
    const new_team_uuid = selectedTeamUUID;
    if (new_team_uuid == "default") {
      toast.error("Please select a team");
      return;
    }
    const newteam = teamOptions.find((team) => team.value == new_team_uuid);
    if (!newteam) {
      toast.error("Invalid team");
      return;
    }
    toast.success("Moved to " + newteam.label + ", reloading...");
    setShowMoveModal(false);
    await updateCurrentAgent(agent, {
      team_uuid: new_team_uuid,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleDeleteAgent = async () => {
    setIsDeleting(true);
    await deleteAgent(agent);
  };
  const handleCloneAgent = async () => {
    cloneAgent(agent);
  };

  const handleEmojiClick = (e: any) => {
    const emoji = e.native;
    posthog.capture("agentEdit: edit agent icon", {
      source: "agent navigation bar",
    });
    updateCurrentAgent(agent, { icon: emoji });
  };

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (newAnimatedName) {
      setIsAnimating(true);
    }
  }, [newAnimatedName]);

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <StackedLayout
      navbar={
        <Navbar className="px-2">
          {editMode && (
            <Logo
              variant="svg"
              onClick={() => (window.location.href = "/agents/new")}
              className="ml-0.5 lg:m-0 w-6 h-6 cursor-pointer hover:animate-spin"
            />
          )}
          {editMode && (
            <FloatingMenu
              content={
                <div className="py-1.5">
                  <RiMenuFill />
                </div>
              }
            >
              <FloatingSubMenu
                content={
                  <>
                    <RiFileFill />
                    File
                  </>
                }
              >
                <FloatingSubMenuItem onClick={handleNewAgent}>
                  <RiAddLargeLine />
                  New Agent
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={handleCloneAgent}>
                  <RiFileCopyLine />
                  Clone Agent
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={handleMoveAgent}>
                  <RiFolderTransferFill />
                  Move to Team
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={() => setIsDeleteModalOpen(true)}>
                  <RiDeleteBin2Line />
                  Delete Agent
                </FloatingSubMenuItem>
              </FloatingSubMenu>
              <FloatingSubMenu
                content={
                  <>
                    <RiEyeFill />
                    View
                  </>
                }
              >
                <FloatingSubMenuItem onClick={() => handleModeToggle(false)}>
                  {isAdvancedMode ? (
                    <RiCheckboxBlankCircleLine className="menuicon" />
                  ) : (
                    <RiCheckboxCircleLine className="menuicon" />
                  )}
                  Basic View
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={() => handleModeToggle(true)}>
                  {isAdvancedMode ? (
                    <RiCheckboxCircleLine className="menuicon" />
                  ) : (
                    <RiCheckboxBlankCircleLine className="menuicon" />
                  )}
                  Advanced View
                </FloatingSubMenuItem>
              </FloatingSubMenu>
              <FloatingSubMenuItem
                onClick={() => {
                  window.location.href = `/agents/new`;
                }}
              >
                <RiArrowLeftFill />
                Back to Agent list
              </FloatingSubMenuItem>
            </FloatingMenu>
          )}
          {editMode && <NavbarDivider className="max-lg:hidden" />}
          {isEditing ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNameSubmit();
              }}
              className="flex items-center"
            >
              <Input
                type="text"
                value={agentName}
                onChange={handleNameChange}
                autoFocus
              />
              <Button variant="secondary" type="submit" className="ml-2">
                Save
              </Button>
            </form>
          ) : (
            <>
              {editMode && (
                <Dropdown>
                  <DropdownButton variant="basic">
                    <AgentIcon
                      icon={
                        agent.icon != "" ? agent.icon : agent.name.split("")[0]
                      }
                    />
                  </DropdownButton>
                  <DropdownMenu grid={false} padding={false}>
                    <Picker
                      data={data}
                      onEmojiSelect={handleEmojiClick}
                      className=""
                    />
                  </DropdownMenu>
                </Dropdown>
              )}
              {!editMode && (
                <AgentIcon
                  icon={agent.icon != "" ? agent.icon : agent.name.split("")[0]}
                />
              )}
              {editMode && (
                <Button
                  variant="basic"
                  className="group"
                  onClick={() => setIsEditing(true)}
                >
                  {isAnimating ? (
                    <AnimatedText
                      text={newAnimatedName || agentName}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  ) : (
                    <span>{newAnimatedName || agentName}</span>
                  )}
                  {` `}
                  {agent.deleted && (
                    <span className="text-red-500">[Deleted]</span>
                  )}
                  {editMode && (
                    <RiEditLine className="opacity-0 group-hover:opacity-100" />
                  )}
                </Button>
              )}
              {!editMode && (
                <Text className="ml-2 font-semibold text-zinc-900 dark:text-white">
                  {isAnimating ? (
                    <AnimatedText
                      text={newAnimatedName || agentName}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  ) : (
                    <span>{newAnimatedName || agentName}</span>
                  )}
                  {` `}
                  {agent.deleted && (
                    <span className="text-red-500">[Deleted]</span>
                  )}
                </Text>
              )}
            </>
          )}
          {editMode && (
            <Badge className="hover:cursor-default">
              <Tooltip content="Saved automatically">Editing</Tooltip>
            </Badge>
          )}
          <NavbarSpacer />
          {!editMode && (
            <>
              {agent.active && (
                <FloatingMenu
                  content={
                    <div className="flex gap-2 items-center text-zinc-950 text-sm font-semibold py-1 dark:text-white">
                      <RiShareLine />
                      Share
                    </div>
                  }
                >
                  <div className="min-w-[400px] p-3 flex flex-col gap-2">
                    <div className="flex border-b gap-2 opacity-70">
                      <div
                        className={clsx("flex cursor-pointer", {
                          "border-b-2 border-zinc-950 dark:border-gray-100":
                            shareTab == "share",
                        })}
                        onClick={() => setShareTab("share")}
                      >
                        Share
                      </div>
                      <div
                        className={clsx("flex cursor-pointer", {
                          "border-b-2 border-zinc-950 dark:border-gray-100":
                            shareTab == "publish",
                        })}
                        onClick={() => setShareTab("publish")}
                      >
                        Publish
                      </div>
                    </div>
                    {shareTab == "share" && (
                      <div className="flex flex-col gap-2">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            sendInvite(inviteEmail);
                          }}
                          className="flex gap-2"
                        >
                          <Input
                            type="email"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                            placeholder="Enter team member's email address"
                          />
                          <Button
                            variant="primary"
                            disabled={inviteEmail.length === 0}
                            type="submit"
                            className="flex-none"
                          >
                            Send Invite
                          </Button>
                        </form>
                      </div>
                    )}
                    {shareTab == "publish" && (
                      <div>
                        <h3>Coming soon...</h3>
                      </div>
                    )}
                  </div>
                </FloatingMenu>
              )}
              <Button
                variant="plain"
                onClick={() => {
                  navigateToEditMode(agent);
                }}
              >
                <RiEditLine />
                Edit agent
              </Button>
            </>
          )}
        </Navbar>
      }
      sidebar={null}
    >
      {children}
      <Dialog open={showMoveModal} onClose={() => setShowMoveModal(false)}>
        <DialogTitle>Move Agent</DialogTitle>
        <DialogDescription>Move Agent to another Team</DialogDescription>
        <DialogBody>
          <Select
            name="type"
            onChange={(e) => setSelectedTeamUUID(e.target.value)}
          >
            {teamOptions.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </DialogBody>
        <DialogActions>
          <Button variant="secondary" onClick={() => setShowMoveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleMoveSave}
            disabled={!selectedTeamUUID || selectedTeamUUID == "default"}
          >
            <RiFolderTransferFill />
            Move
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <AlertTitle>Confirm Agent Deletion</AlertTitle>
        <AlertDescription>
          Are you sure you want to delete this agent? This action cannot be
          undone.
        </AlertDescription>
        <AlertActions>
          <Button
            variant="secondary"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteAgent}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting Agent..." : "Delete agent"}
          </Button>
        </AlertActions>
      </Alert>
    </StackedLayout>
  );
}
