import classnames from "classnames";

const ProgressBar = ({
  steps,
  currentStep,
  widths,
  stepProgress = 100,
  onClick,
}: {
  steps: number;
  currentStep: number;
  widths?: number[];
  stepProgress?: number;
  onClick?: (position: number, index: number) => void;
  isActive?: boolean;
}) => {
  return (
    <div className="justify-center align-center flex mx-0 gap-1">
      {Array.from({ length: steps }, (_, i) => i).map((stepIndex) => {
        if (widths != undefined && widths[stepIndex] == 0) {
          return null;
        }
        return (
          <div
            key={stepIndex}
            className={classnames("relative h-2 rounded-lg bg-white", {
              "w-8": widths == undefined,
              "cursor-pointer": onClick != undefined,
            })}
            style={{
              width:
                widths != undefined && widths.length >= stepIndex
                  ? `${widths[stepIndex]}%`
                  : undefined,
            }}
          >
            {widths &&
              Array.from(
                { length: Math.floor(widths[stepIndex]) },
                (_, i) => i,
              ).map((i) => (
                <div
                  onClick={() => {
                    if (onClick) {
                      let sum = 0;
                      for (let j = 0; j < stepIndex; j++) {
                        sum += widths[j];
                      }
                      sum += i;
                      onClick(sum, stepIndex);
                    }
                  }}
                  key={i}
                  className={classnames(
                    "absolute top-0 left-0 h-2 transition-colors",
                    {
                      "bg-gray-50":
                        currentStep < stepIndex ||
                        (currentStep == stepIndex &&
                          stepProgress / 100 < i / widths[stepIndex]),
                      "bg-primary":
                        currentStep > stepIndex ||
                        (currentStep == stepIndex &&
                          stepProgress / 100 >= i / widths[stepIndex]),
                      "rounded-l": i == 0,
                      "rounded-r": i == Math.floor(widths[stepIndex]) - 1,
                    },
                  )}
                  style={{
                    width: `${101 / widths[stepIndex]}%`,
                    left: `${(i * 100) / widths[stepIndex]}%`,
                  }}
                />
              ))}
            {!widths && (
              <div
                className={classnames("transition-colors w-full h-full", {
                  "bg-gray-100": currentStep < stepIndex,
                  "bg-primary": currentStep >= stepIndex,
                  "rounded-l": stepIndex == 0,
                  "rounded-r": stepIndex == steps - 1,
                })}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
