import { NotificationSettings } from "../types";
import BaseApi from "./base";

export default class NotificationApi extends BaseApi {
  static async getNotificationSettings(): Promise<NotificationSettings> {
    return await this.authenticatedGetApiCall<NotificationSettings>(
      `users/@me/notification-settings/`,
    );
  }

  static async changeNotificationSettings(
    vars: any = {},
  ): Promise<NotificationSettings> {
    return await this.authenticatedPatchApiCall<NotificationSettings>(
      `users/@me/notification-settings/update/`,
      vars,
    );
  }
}
