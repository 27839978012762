import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Agent, RequiredInput } from "@/utils/types";
import React, { useEffect, useState } from "react";
import { RiAddBoxFill, RiDeleteBin2Fill } from "react-icons/ri";
import { Divider } from "./design-system/divider";
import { Input } from "./design-system/input";
import { Switch } from "./design-system/switch";
import { Textarea } from "./design-system/textarea";
import Tooltip from "./styled/Tooltip";

type QuestionAnswer = {
  id: string;
  question: string;
  answer: string;
};

type QuestionAnswerEditorProps = {
  agent: Agent;
};

const ContextEditor = ({ agent }: QuestionAnswerEditorProps) => {
  const [saving, setSaving] = useState(false);
  const [justSaved, setJustSaved] = useState(false);
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>(
    agent.required_inputs ?? [],
  );

  useEffect(() => {
    let qapairs = [
      {
        question: "Loading",
        answer: "Loading",
      },
    ];
    if (agent.context?.qa_pairs) {
      qapairs = agent.context.qa_pairs;
    }
    let newquestionanswers = [];
    for (let i = 0; i < qapairs.length; i++) {
      newquestionanswers.push({
        id: i.toString(),
        question: qapairs[i].question,
        answer: qapairs[i].answer,
      });
    }
    setQuestionAnswers(newquestionanswers);
  }, [agent.context]);

  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([
    { id: "1", question: "What is your product?", answer: "" },
    { id: "2", question: "Who is your target audience?", answer: "" },
  ]);

  const [error, setError] = useState<string | null>(null);

  const addQuestionAnswer = () => {
    setQuestionAnswers([
      ...questionAnswers,
      { id: Date.now().toString(), question: "", answer: "" },
    ]);
  };
  const addRequiredInput = () => {
    setRequiredInputs([
      ...requiredInputs,
      { id: Date.now().toString(), key: "", description: "", optional: false },
    ]);
  };

  const updateQuestionAnswer = (
    id: string,
    field: "question" | "answer",
    value: string,
  ) => {
    setQuestionAnswers(
      questionAnswers.map((qa) =>
        qa.id === id ? { ...qa, [field]: value } : qa,
      ),
    );
  };

  const updateRequiredInput = (
    id: string,
    field: "key" | "description",
    value: string,
  ) => {
    setRequiredInputs(
      requiredInputs.map((input) =>
        input.id === id ? { ...input, [field]: value } : input,
      ),
    );
  };

  const removeQuestionAnswer = (id: string) => {
    setQuestionAnswers(questionAnswers.filter((qa) => qa.id !== id));
  };

  const removeRequiredInput = (id: string) => {
    setRequiredInputs(requiredInputs.filter((input) => input.id !== id));
  };

  const toggleOptionalInput = (id: string) => {
    setRequiredInputs(
      requiredInputs.map((input) =>
        input.id === id ? { ...input, optional: !input.optional } : input,
      ),
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = questionAnswers.every(
      (qa) => qa.question.length >= 5 && qa.answer.length >= 5,
    );

    if (isValid) {
      setError(null);
      onSubmit(questionAnswers);
    } else {
      setError("Each question and answer must be at least 5 characters long.");
    }
  };

  const onSubmit = async (questionAnswers: QuestionAnswer[]) => {
    setSaving(true);
    const context = {
      qa_pairs: questionAnswers.map((qa) => ({
        question: qa.question,
        answer: qa.answer,
      })),
    };

    try {
      await MarconipyApi.agent.updateAgentContext(
        agent.uuid,
        context,
        requiredInputs,
      );
      setSaving(false);
      setJustSaved(true);
      setTimeout(() => setJustSaved(false), 2000);
    } catch (e) {
      setError("Failed to update context");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 my-4">
      <div>
        {requiredInputs.map((input: RequiredInput, index: number) => (
          <div key={index} className="space-y-2 mb-4 pb-1">
            <div className="flex justify-between gap-4 items-center">
              <Input
                value={input.key}
                onChange={(e) =>
                  updateRequiredInput(input.id, "key", e.target.value)
                }
                placeholder="Enter the input's key"
              />
              <Tooltip content="Delete input">
                <div className="flex items-center gap-2">
                  <p>Required</p>
                  <Switch
                    onChange={() => toggleOptionalInput(input.id)}
                    checked={!input.optional}
                  />
                </div>
              </Tooltip>
              <Tooltip content="Delete input">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => removeRequiredInput(input.id)}
                >
                  <RiDeleteBin2Fill />
                </Button>
              </Tooltip>
            </div>
            <Textarea
              value={input.description}
              onChange={(e) =>
                updateRequiredInput(input.id, "description", e.target.value)
              }
              placeholder="Enter the input's description"
              className="w-full dark:text-white"
            />
          </div>
        ))}
        <Button type="button" onClick={addRequiredInput} variant="secondary">
          <RiAddBoxFill /> Add form input
        </Button>
      </div>
      <Divider />
      {questionAnswers.map((qa) => (
        <div key={qa.id} className="space-y-2 mb-4 pb-1">
          <div className="flex justify-between gap-4 items-center">
            <Input
              value={qa.question}
              onChange={(e) =>
                updateQuestionAnswer(qa.id, "question", e.target.value)
              }
              placeholder="Enter your question"
            />
            <Tooltip content="Delete question">
              <Button
                type="button"
                variant="secondary"
                onClick={() => removeQuestionAnswer(qa.id)}
              >
                <RiDeleteBin2Fill />
              </Button>
            </Tooltip>
          </div>
          <Textarea
            value={qa.answer}
            onChange={(e) =>
              updateQuestionAnswer(qa.id, "answer", e.target.value)
            }
            placeholder="Enter your answer"
            className="w-full dark:text-white"
          />
        </div>
      ))}
      <Button type="button" onClick={addQuestionAnswer} variant="secondary">
        <RiAddBoxFill /> Add question & answer
      </Button>
      {error && <div className="destructive">{error}</div>}
      <p className="text-sm">
        <a
          href="https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4"
          target="_blank"
          rel="noreferrer"
          className="text-primary hover:underline"
        >
          How to craft an effective brief
        </a>
      </p>
      <Button
        variant={justSaved ? "secondary" : "primary"}
        type="submit"
        disabled={saving}
      >
        {saving ? "Saving..." : justSaved ? "Saved!" : "Save"}
      </Button>
    </form>
  );
};

export default ContextEditor;
