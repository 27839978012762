type SkeletonElementProps = {
  width?: number | string;
  height?: number | string;
  cornerRadius?: number;
};

const SkeletonElement = ({
  width,
  height,
  cornerRadius,
}: SkeletonElementProps) => {
  return (
    <p
      className="animate-pulse bg-gray-300 my-2 max-w-full"
      style={{ width: width, height: height, borderRadius: cornerRadius }}
    />
  );
};

export default SkeletonElement;
