import { Loading } from "@/components/Loading";
import MarconipyApi from "@/utils/marconipyApi";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SlackCallback: React.FC = () => {
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading",
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const sendSlackOauth = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get("code");
      const state = queryParams.get("state");

      if (code && state) {
        await MarconipyApi.slack.oauthCallback(code, state);
        const redirectUrl = Cookies.get("slack_redirect") || "/dashboard";
        // Remove the cookie
        Cookies.remove("slack_redirect");
        // Redirect after a short delay
        setTimeout(() => navigate(redirectUrl), 200);
      } else {
        setStatus("error");
      }
    };
    sendSlackOauth();
  }, [location, navigate]);

  // if (status === 'loading') return <div>Completing Slack integration...</div>;
  // if (status === 'success') return <div>Slack successfully connected! Redirecting...</div>;
  // return <div>Error connecting Slack. Please try again.</div>;
  return (
    <div>
      {status != "error" && <Loading />}
      {status === "error" && (
        <div>Error connecting Slack. Please try again.</div>
      )}
    </div>
  );
};

export default SlackCallback;

export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  return null;
}
