import ContentShareButtons from "@/components/ContentShareButtons";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import NavigationBarUnlogged from "@/components/NavigationBarPublic";
import { PublicFooter } from "@/components/PublicFooter";
import Label from "@/components/styled/Label";
import MarkdownRenderer from "@/components/styled/MarkdownRenderer";
import useBodyClasses from "@/components/useBodyClasses";
import { useAuth } from "@/contexts/AuthenticationContext";
import MarconipyApi from "@/utils/marconipyApi";
import { ArtefactDocument, ShareableArtefact } from "@/utils/types";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";

const ShareView: React.FC = () => {
  const shareableResult = useLoaderData() as ShareableArtefact;
  const [result, setResult] = useState<ArtefactDocument>("");
  const { isAuth } = useAuth();
  useBodyClasses(["overflow-hidden", "h-full"]);

  useEffect(() => {
    for (const key of Object.keys(shareableResult.content)) {
      if (key === "result") {
        const content =
          shareableResult.content[key as keyof typeof shareableResult.content];
        setResult(content as ArtefactDocument);
        return;
      }
    }
  }, [shareableResult]);
  return (
    <>
      <Helmet>
        <title>{shareableResult.title} - TailorTask</title>
        <meta
          name="description"
          content={(typeof result == "string" ? result : result.document)
            .replace(/[#*`_~]/g, "")
            .replace(shareableResult.title, "")
            .substring(0, 100)}
        />
        <meta
          property="og:title"
          content={`${shareableResult.title} - TailorTask`}
        />
        <meta
          property="og:description"
          content={(typeof result == "string" ? result : result.document)
            .replace(/[#*`_~]/g, "")
            .substring(0, 100)}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <div className="h-full w-full">
        <NavigationBarUnlogged variant="made-with" />
        <div className="flex justify-center px-4 sm:px-0">
          <div className="max-w-[800px]">
            <div className="flex mb-8 gap-4">
              <h1 className="text-2xl sm:text-4xl font-semibold py-4">
                {shareableResult.title}
              </h1>
              <div className="">
                <ContentShareButtons
                  artefactUUID={shareableResult.uuid}
                  content={typeof result === "string" ? result : result.document}
                />
              </div>
            </div>
            <div className="flex flex-col mb-8 gap-4">
              {!isAuth && (
                <div className="rounded-lg bg-gradient-to-r from-primary/10 to-primary/5 p-6 shadow-sm border border-primary/10">
                  <div className="flex flex-col gap-3">
                    <h3 className="font-medium text-lg text-primary">
                      Powered by TailorTask AI
                    </h3>
                    <p className="text-gray-700">
                      This result was generated by an AI Agent using TailorTask.
                    </p>
                    <p className="text-gray-600">
                      {`Create your own AI Agent in less than 10 minutes - it's
                      quick and easy!`}
                    </p>
                    <Button
                      href="/signup"
                      className="mt-2 w-fit hover:scale-105 transition-transform"
                    >
                      Get started for free
                    </Button>
                  </div>
                </div>
              )}
              {shareableResult.metadata?.inputs &&
              JSON.stringify(shareableResult.metadata?.inputs) !== "[]" ? (
                <div className="flex flex-col gap-2">
                  <Divider />
                  <div>
                    <Label>Inputs</Label>
                    {shareableResult.metadata.inputs.map(
                      (input: { description: string; value: string }) => (
                        <div
                          className="flex gap-2 items-center text-sm"
                          key={"input-" + input.value}
                        >
                          <span className="text-gray-900 dark:text-gray-100">
                            {input.description}:
                          </span>{" "}
                          {input.value}
                        </div>
                      ),
                    )}
                  </div>
                  <Divider />
                </div>
              ) : (
                ""
              )}
            </div>
            {typeof result == "string" ? (
              <MarkdownRenderer text={result.replace(shareableResult.title, "")} />
            ) : (
              <MarkdownRenderer text={result.document.replace(shareableResult.title, "")} />
            )}
          </div>
        </div>
        <br/>
        <br/>
        <Divider />
      </div>
      <PublicFooter />
    </>
  );
};

export default ShareView;

export async function loader({ params }: { params: any }) {
  const uuid = params.runUUID.toString();
  if (!uuid) {
    throw new Error("No runUUID provided");
  }
  //grab the run uuid from the URL: it formatted like /agent/{runUUID}
  const result = await MarconipyApi.public.getShareableResult(uuid);
  return result;
}
