import { Button } from "@/components/design-system/button";
import { useEventListener } from "@/contexts/EventsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { AgentState, Message, RequiredInput, Tool } from "@/utils/types";
import Cookies from "js-cookie";
import { isEqual } from "lodash";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useRef, useState } from "react";
import { RiLoader2Fill, RiSendPlane2Fill } from "react-icons/ri";
import Lottie from "react-lottie-player";
import typingIndicator from "./../lottie/typing.json";
import "./ChatComponent.scss";
import ContentShareButtons from "./ContentShareButtons";
import { Dialog, DialogBody } from "./design-system/dialog";
import { MessageComponent } from "./MessageComponent";
import QuickReplyBubbles from "./QuickReplyBubbles";
import SkeletonElementPreset from "./SkeletonElementPreset";
import MarkdownRenderer from "./styled/MarkdownRenderer";
import Tooltip from "./styled/Tooltip";

const ADVANCED_MODE_COOKIE = "tailortask_advanced_mode";

interface ChatProps {
  chatRef?: React.RefObject<HTMLDivElement>;
  agentUUID: string;
  conversationUUID: string;
  agentIcon?: string;
  agentState: AgentState | null;
  isAgentActive: boolean;
  onAgentRefresh?: () => void;
  onNewAgentState?: (state: any) => void;
  onQuickReplyAction?: (action: any) => void;
  onQuickReplyForm?: (fields: RequiredInput[]) => void;
  shiftToBottom?: boolean; // in case we have a topbar, we shift the view
  showQuickReplyBubbles?: boolean;
  isConnected: boolean;
  onSendMessage: (message: string) => void;
  messages: Message[];
  isLoading: boolean;
  loadMessages: ({
    force,
  }: {
    force?: boolean;
    beforeTimestamp?: number;
  }) => Promise<void>;
  editMode?: boolean;
}

const useAutoFocusTextarea = (canSend: boolean, loading: boolean) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (canSend && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [canSend, loading]);

  return textareaRef;
};

const ChatComponent: React.FC<ChatProps> = ({
  chatRef,
  agentUUID,
  conversationUUID,
  agentIcon,
  agentState,
  isAgentActive,
  onQuickReplyAction,
  onQuickReplyForm,
  shiftToBottom,
  showQuickReplyBubbles = true,
  isConnected,
  messages,
  onSendMessage: sendMessage,
  isLoading,
  loadMessages,
  editMode,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [canSendMessage, setCanSendMessage] = useState(true);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const messageListRef = useRef<null | HTMLDivElement>(null);
  const [placeholder, setPlaceholder] = useState("Type a message...");
  const textareaRef = useAutoFocusTextarea(
    canSendMessage && !sending,
    isLoading,
  );
  const [advancedMode, setAdvancedMode] = useState(false);
  const posthog = usePostHog();
  const [textareaHeight, setTextareaHeight] = useState<number>(40);
  const [tools, setTools] = useState<Tool[]>([]);
  const [previewContent, setPreviewContent] = useState<string | null>(null);
  const [artefactUUID, setArtefactUUID] = useState<string | null>(null);
  const [showInput, setShowInput] = useState<boolean>(!!editMode);
  const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const prevMessagesRef = useRef<Message[]>([]);
  const oldestMessageRef = useRef<string>();

  useEffect(() => {
    const loadAllTools = async () => {
      const tools = await MarconipyApi.agent.getAllTools(agentUUID);
      setTools(tools);
    };
    loadAllTools();
  }, [agentUUID]);

  useEffect(() => {
    const savedMode = Cookies.get(ADVANCED_MODE_COOKIE);
    if (savedMode !== undefined) {
      const isAdvanced = savedMode == "advanced";
      setAdvancedMode(isAdvanced);
    }
  }, [conversationUUID]);

  const handleGiveFeedback = () => {
    setInputMessage("I have some feedback: ");
    setShowInput(true);
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 100);
  };

  const onLocalQuickReplyAction = (action: any) => {
    //check if the action is something that needs to be handled in the chat component or escalated to the parent
    if (action == "give_feedback") {
      handleGiveFeedback();
    } else if (action == "save_and_exit") {
      window.location.href = `/agents/new`;
    } else {
      onQuickReplyAction && onQuickReplyAction(action);
    }
  };

  useEffect(() => {
    const updateSending = () => {
      if (!agentState) {
        return;
      }
      switch (agentState.name) {
        case "unstarted":
          setCanSendMessage(true);
          setShowTypingIndicator(false);
          setPlaceholder(
            "I'm a content marketer, and I need to come up with ideas...",
          );
          break;
        case "pending":
          setCanSendMessage(false);
          setShowTypingIndicator(true);
          setPlaceholder("Tailor is thinking...");
          break;
        case "running":
          setSending(false);
          setCanSendMessage(false);
          setShowTypingIndicator(true);
          setPlaceholder("Tailor is working...");
          break;
        case "waiting_for_input":
          setSending(false);
          setCanSendMessage(true);
          setShowTypingIndicator(false);
          setPlaceholder("Tailor is waiting for your input...");
          break;
        case "waiting_for_ui_input":
          setCanSendMessage(false);
          setShowTypingIndicator(false);
          setPlaceholder("👉 Complete the setup on the right panel");
          break;
        case "tool_in_progress":
          setCanSendMessage(false);
          setShowTypingIndicator(false);
          setPlaceholder("Tailor is executing a tool...");
          break;
        case "generating_response":
          setCanSendMessage(false);
          setShowTypingIndicator(true);
          setPlaceholder("Tailor is thinking...");
          break;
        case "sending_to_outputs":
          setCanSendMessage(false);
          setShowTypingIndicator(true);
          setPlaceholder("Tailor is sending the results...");
          break;
        case "completed":
          setSending(false);
          setCanSendMessage(true);
          setShowTypingIndicator(false);
          setPlaceholder("Give feedback on the results");
          break;
        case "run_waiting_for_input":
          setSending(false);
          setCanSendMessage(true);
          setShowTypingIndicator(false);
          setPlaceholder("Tailor is waiting for your input...");
          break;
        case "waiting_for_required_input":
          setCanSendMessage(false);
          setShowTypingIndicator(false);
          setPlaceholder("Tailor is waiting for you to fill up the form...");
          break;
        case "needs_customer_support":
          setCanSendMessage(false);
          setShowTypingIndicator(false);
          setPlaceholder(
            "This agent has some issues, contact our customer support at info@tailortask.ai",
          );
          break;
        default:
          setSending(false);
          setCanSendMessage(true);
          setShowTypingIndicator(false);
          break;
      }
    };

    updateSending();
  }, [agentState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputMessage(e.target.value);
    // Adjust textarea height
    if (textareaRef.current) {
      let newH = textareaRef.current.scrollHeight;
      if (newH > 200) {
        newH = 200;
      }
      if (e.target.value == "") {
        setTextareaHeight(40);
      } else {
        setTextareaHeight(newH);
      }
    } else {
      setTextareaHeight(40);
    }
  };
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const isAtTop = element.scrollTop === 0;

    if (isAtTop && messages.length > 0) {
      const oldestMessage = messages[0];
      const oldestMessageUUID = oldestMessage.uuid;
      oldestMessageRef.current = oldestMessageUUID;
      // Store reference to current first message
      loadMessages({
        beforeTimestamp: oldestMessage.pagination_timestamp,
      });
    }
  };

  useEffect(() => {
    if (!isEqual(messages, prevMessagesRef.current)) {
      prevMessagesRef.current = messages;
      if (oldestMessageRef.current) {
        messageRefs.current[oldestMessageRef.current]?.scrollIntoView();
      }
    }
  }, [messages]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaHeight}px`;
    }
    if (messageListRef.current) {
      messageListRef.current.style.height = "auto";
      const shift = shiftToBottom ? 105 : 35;
      messageListRef.current.style.height = `calc(100vh - ${textareaHeight + shift}px)`;
    }
  }, [textareaHeight, setTextareaHeight, textareaRef, shiftToBottom]);

  useEffect(() => {
    if (!editMode) {
      if (
        agentState?.name == "waiting_for_input" ||
        agentState?.name == "run_waiting_for_input"
      ) {
        setShowInput(true);
        setInputMessage("");
        setTimeout(() => {
          textareaRef.current?.focus();
        }, 100);
      } else {
        setShowInput(false);
      }
    } else {
      setShowInput(true);
    }
  }, [agentState, editMode, textareaRef]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleSendMessage = async (event: React.FormEvent) => {
    event.preventDefault();
    posthog.capture("chat_message_sent");
    completeSendMessage(inputMessage);
  };
  const handleQuickReply = (message: string) => {
    setInputMessage(message);
    posthog.capture("quick_reply_sent");
    completeSendMessage(message);
  };

  const completeSendMessage = async (messagecontent: string) => {
    setSending(true);
    setCanSendMessage(false);
    if (messagecontent.trim() && isConnected) {
      sendMessage(messagecontent);
      setInputMessage("");
      setTimeout(() => {
        setTextareaHeight(40);
        // setSending(false);
      }, 200);
    } else {
      console.log("Message is empty or not connected");
    }
  };

  useEventListener("VIEW_MODE_CHANGED", (payload) => {
    setAdvancedMode(payload.advanced);
  });

  const closePreviewDialog = () => {
    setPreviewContent(null);
    setArtefactUUID(null);
  };

  const isToolUseMessage = (message: Message) => {
    if (message.role !== "assistant") return false;
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.type === "tool_use");
  };
  const isToolResultMessage = (message: Message) => {
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.type === "tool_result");
  };
  const isBriefMessage = (message: Message) => {
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.text?.startsWith("BRIEF:"));
  };

  const renderMessages = () => {
    // let filteredMessages = messages;

    let filteroutmessagesWithIndex = [];

    //traverse the messages and hide the tool messages that are followed by another tool message
    let filteredMessages = messages.filter((message) => !message.hide_from_ui);
    for (let i = 0; i < filteredMessages.length; i++) {
      let affectedIndex = i - 1;
      let previous_message = filteredMessages[affectedIndex];
      let currentMessage = filteredMessages[i];

      // remove "save and exit" quick reply if not in editMode
      if (
        Array.isArray(currentMessage.content) &&
        currentMessage.content.find((item) => item.type === "quick_replies")
      ) {
        const currentMessageQuickReplies = currentMessage.content.find(
          (item) => item.type === "quick_replies",
        );
        if (
          currentMessageQuickReplies &&
          currentMessageQuickReplies.quick_replies?.find(
            (item) => item.type == "action" && item.action == "save_and_exit",
          ) &&
          !editMode
        ) {
          filteredMessages[i] = {
            ...filteredMessages[i],
            content: [
              {
                type: "quick_replies",
                quick_replies: currentMessageQuickReplies.quick_replies.map(
                  (item) => {
                    if (item.action == "save_and_exit") {
                      return {
                        ...item,
                        action: "run_agent",
                      };
                    } else {
                      return item;
                    }
                  },
                ),
              },
            ],
          };
        }
        if (
          currentMessageQuickReplies &&
          currentMessageQuickReplies.quick_replies?.find(
            (item) => item.type == "action" && item.action == "confirm_agent",
          ) &&
          isAgentActive &&
          editMode
        ) {
          filteredMessages[i] = {
            ...filteredMessages[i],
            content: [
              {
                type: "quick_replies",
                quick_replies: currentMessageQuickReplies.quick_replies.map(
                  (item) => {
                    if (item.action == "confirm_agent") {
                      return {
                        ...item,
                        action: "save_and_exit",
                      };
                    } else {
                      return item;
                    }
                  },
                ),
              },
            ],
          };
        }
      }
      if (!advancedMode) {
        // show form results in chat
        if (
          isToolResultMessage(filteredMessages[i]) &&
          previous_message &&
          Array.isArray(previous_message.content) &&
          Array.isArray(currentMessage.content) &&
          previous_message.content.find((item) => item.type === "quick_replies")
        ) {
          const previousMessageQuickReplies = previous_message.content.find(
            (item) => item.type === "quick_replies",
          )?.quick_replies;
          if (
            previousMessageQuickReplies?.find((item) => item.type == "form")
          ) {
            const textContent = currentMessage.content.find(
              (item) => item.type === "text",
            );
            const text = textContent?.text ?? "";
            filteredMessages[i] = {
              ...filteredMessages[i],
              content: [
                {
                  type: "text",
                  text,
                },
              ],
            };
          }
        }

        if (isToolUseMessage(filteredMessages[i])) {
          while (
            previous_message &&
            isToolResultMessage(previous_message) &&
            filteredMessages[affectedIndex - 1]
          ) {
            affectedIndex = affectedIndex - 1;
            previous_message = filteredMessages[affectedIndex];
          }
          if (previous_message && isToolUseMessage(previous_message)) {
            if (filteredMessages[i].run) {
              if (previous_message.run == filteredMessages[i].run) {
                filteroutmessagesWithIndex.push(affectedIndex);
              }
            } else {
              filteroutmessagesWithIndex.push(affectedIndex);
            }
          }
        } else if (
          isBriefMessage(filteredMessages[i]) &&
          i < filteredMessages.length - 1
        ) {
          filteroutmessagesWithIndex.push(i);
        }
      }
    }
    return filteredMessages.map((message, index) => {
      const previousMessage = index > 0 ? filteredMessages[index - 1] : null;
      const isLastMessage = index === filteredMessages.length - 1;
      const showDivider =
        (message.run && previousMessage && !previousMessage.run) ||
        (message.run &&
          previousMessage &&
          previousMessage.run &&
          previousMessage.run !== message.run);
      const showDividerEnd =
        (!message.run && previousMessage && previousMessage.run) ||
        (message.run &&
          previousMessage &&
          previousMessage.run &&
          previousMessage.run !== message.run);

      if (message.hide_from_ui) {
        return null;
      }
      if (
        Array.isArray(message.content) &&
        message.content.find(
          (item) =>
            item.type === "tool_use" && item.name == "ask_user_to_fill_up_form",
        )
      ) {
        return null;
      }

      return (
        <React.Fragment key={message.uuid}>
          {(showDivider || showDividerEnd) && (
            <div className="flex items-center my-4">
              <div className="flex-grow border-t border-gray"></div>
              <div className="flex-shrink mx-4 text-sm text-black dark:text-white">
                {showDivider && (
                  <Tooltip content="We are running your Agent">
                    <span className="hover:opacity-100 hover:cursor-default opacity-70">
                      Running your Agent
                    </span>
                  </Tooltip>
                )}
                {showDividerEnd && (
                  <Tooltip content="The run of your Agent ended">
                    <span className="hover:opacity-100 hover:cursor-default opacity-70">
                      Run ended
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="flex-grow border-t border-gray"></div>
            </div>
          )}
          {!filteroutmessagesWithIndex.includes(index) && (
            <MessageComponent
              ref={(el) => (messageRefs.current[message.uuid] = el)}
              message={message}
              index={index}
              conversationUUID={conversationUUID}
              agentIcon={agentIcon}
              isLastMessage={isLastMessage}
              isRun={message.run ? true : false}
              refreshAllMessages={loadMessages}
              advancedMode={advancedMode}
              onQuickReplyAction={onLocalQuickReplyAction}
              onQuickReplyForm={onQuickReplyForm}
              onOpenDocument={(
                content: string,
                artefactUUID: string | null,
              ) => {
                setPreviewContent(content);
                setArtefactUUID(artefactUUID ?? null);
              }}
              tools={tools}
            />
          )}
        </React.Fragment>
      );
    });
  };
  return (
    <React.Fragment>
      {/* <div ref={chatContainerRef} className="relative"> */}
      <div
        className="dark:bg-black-dark overflow-y-scroll px-[12px]"
        ref={messageListRef}
        onScroll={handleScroll}
      >
        <div className="min-h-[10px]" />
        {renderMessages()}
        {messages.length == 0 && isLoading && (
          <SkeletonElementPreset variant="chat-messages" />
        )}
        {showTypingIndicator && agentState?.name != "waiting_for_ui_input" && (
          <div className="flex ml-10 message group justify-start dark:invert">
            <Lottie
              loop
              animationData={typingIndicator}
              play
              style={{ width: 50, height: 50 }}
            />
          </div>
        )}
        <div ref={chatRef} className="min-h-[30px]" />
      </div>
      <div className="p-4 dark:bg-black">
        {messages.length < 2 &&
          !sending &&
          !isLoading &&
          showQuickReplyBubbles && (
            <div className="quick-replies-container flex flex-wrap gap-1">
              <QuickReplyBubbles onSendMessage={handleQuickReply} />
            </div>
          )}
        {showInput && (
          <form
            onSubmit={(e) => {
              handleSendMessage(e);
            }}
            className="flex gap-2"
            aria-disabled={sending || !canSendMessage}
          >
            <textarea
              ref={textareaRef}
              value={inputMessage}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="bg-white flex-1 border border-gray rounded-l-lg p-2 resize-none overflow-hidden dark:bg-black-dark"
              placeholder={placeholder}
              rows={1}
              style={{ minHeight: "40px", maxHeight: "200px" }}
              disabled={!canSendMessage || sending || isLoading}
            />
            <Button
              type="submit"
              disabled={!canSendMessage || sending || inputMessage.length === 0}
            >
              {sending ? (
                <RiLoader2Fill className="animate-spin" />
              ) : (
                <RiSendPlane2Fill />
              )}
            </Button>
          </form>
        )}
      </div>
      {/* </div> */}
      <Dialog open={!!previewContent} onClose={closePreviewDialog} size="5xl">
        {artefactUUID && previewContent && (
          <ContentShareButtons
            artefactUUID={artefactUUID}
            content={previewContent}
          />
        )}
        <DialogBody>
          <div className="text-wrap dark:text-white">
            {previewContent && <MarkdownRenderer text={previewContent} />}
          </div>
        </DialogBody>
      </Dialog>
    </React.Fragment>
  );
};

export default ChatComponent;
