import Tooltip from "./Tooltip";
import TimeAgo from "react-timeago";

type TimeLabelProps = {
  time: string | Date;
  label?: string;
  additionalClasses?: string;
};

export default function TimeLabel(props: TimeLabelProps) {
  return (
    <Tooltip
      content={
        new Date(props.time).toLocaleDateString() +
        " - " +
        new Date(props.time).toLocaleTimeString()
      }
    >
      <p
        className={` hover:underline hover:cursor-default ${props.additionalClasses}`}
      >
        {props.label ? `${props.label}: ` : ""}
        <TimeAgo date={props.time} />
      </p>
    </Tooltip>
  );
}
