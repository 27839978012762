import SignupForm from "@/components/auth/SignupForm";
import NavigationBarUnlogged from "@/components/NavigationBarPublic";
import Card from "@/components/styled/Card";
import Container from "@/components/styled/Container";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";

export default function Signup() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const invite = searchParams.get("invite");

  const handleRedirectToLogin = () => {
    if (invite) {
      navigate("/login?invite=" + invite);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign up - TailorTask</title>
        <meta
          name="description"
          content="Create your TailorTask account. Get an A.I. to do your repetitive tasks: SEO, marketing, operations and more."
        />
        <meta property="og:title" content="Signup - TailorTask" />
        <meta
          property="og:description"
          content="Create your TailorTask account. Get an A.I. to do your repetitive tasks: SEO, marketing, operations and more."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://app.tailortask.ai/signup" />
      </Helmet>
      <NavigationBarUnlogged variant="signup" />
      <div className="col-span-12 sm:col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 grid grid-cols-12">
        <Container className="flex flex-row">
          <Card className="flex flex-col gap-2 py-4">
            <h1>
              {invite ? "Sign up to accept your invite" : "Create an account"}
            </h1>
            <SignupForm onLoginRedirect={handleRedirectToLogin} />
          </Card>
          <div className="hidden xl:flex flex-col mt-8 ml-16 gap-4 opacity-80 hover:opacity-100">
            <h2 className="text-4xl">Goodbye boring tasks 👋</h2>
            <h3 className="text-lg">
              TailorTask is the easiest way to automate your repetitive
              workflows.
              <br />
              <br />
              1️⃣ Explain your task to {`TailorTask's`} A.I.
              <br />
              <br />
              2️⃣ Setup a schedule
              <br />
              <br />✅ Sit back and relax
            </h3>
          </div>
        </Container>
      </div>
    </>
  );
}
export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.auth.isAuthenticated();
  if (loggedIn) {
    console.log("User is already logged in");
    return redirect("/");
  } else {
    return false;
  }
}
