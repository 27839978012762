import {
  Alert,
  AlertActions,
  AlertBody,
  AlertTitle,
} from "@/components/design-system/alert";
import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { Organization, Plan, Team } from "@/utils/types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RiRocketLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthenticationContext";

interface TeamContextType {
  loading: boolean;
  teams: Team[];
  plan: Plan | null;
  organization: Organization | null;
  selectedTeam: Team | null;
  setSelectedTeam: (team: Team) => void;
  switchToTeamUUID: (teamUUID: string) => void;
  createNewTeam: () => Promise<void>;
  deleteTeam: (team: Team) => Promise<void>;
  showUpgradeDialog: () => void;
  teamHasChanged: boolean;
}

const TeamContext = createContext<TeamContextType | null>(null);

export const useTeams = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error("useTeams must be used within a TeamProvider");
  }
  return context;
};

export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([] as Team[]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [plan, setPlan] = useState<Plan | null>(null);
  const [upgradedNeeded, setUpgradeNeeded] = useState(false);
  const teamUUIDRef = useRef<string | null>(
    selectedTeam ? selectedTeam.uuid : null,
  );
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const handleSelectTeam = useCallback(async (team: Team) => {
    setSelectedTeam(team);
    localStorage.setItem("last_team_uuid", team.uuid);
    await MarconipyApi.team.changeDefaultTeam(team.uuid);
    window.location.href = "/agents";
  }, []);

  const loadTeams = useCallback(async () => {
    let teams = await MarconipyApi.team.getAll();
    teams = teams.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    );
    setTeams(teams);
    const lastTeamUUID = localStorage.getItem("last_team_uuid");
    let team = teams.find((team: Team) => team.uuid == lastTeamUUID);
    if (!team) {
      team = teams[0];
    }
    setSelectedTeam(team);
    setLoading(false);
  }, []);

  const createNewTeam = useCallback(async () => {
    const data = (await MarconipyApi.team.create()) as any;
    await loadTeams();
    setSelectedTeam(data.team);
    navigate("/agents");
  }, [loadTeams, navigate]);

  const deleteTeam = useCallback(async (team: Team) => {
    try {
      await MarconipyApi.team.delete(team.uuid);
      // Handle successful deletion (e.g., redirect to teams list or show a success message)
      window.location.href = "/agents";
    } catch (error) {
      console.error("Error deleting team:", error);
      // Handle error (e.g., show error message to user)
    }
  }, []);

  const showUpgradeDialog = useCallback(() => {
    setUpgradeNeeded(true);
  }, []);

  const switchToTeamUUID = useCallback(
    async (teamUUID: string) => {
      const team = teams.find((team) => team.uuid == teamUUID);
      if (team) {
        setSelectedTeam(team);
      }
    },
    [teams],
  );

  useEffect(() => {
    if (isAuth) {
      loadTeams();
    }
  }, [isAuth, loadTeams]);

  useEffect(() => {
    if (selectedTeam && selectedTeam.uuid != teamUUIDRef.current) {
      teamUUIDRef.current = selectedTeam.uuid;
    }
  }, [selectedTeam]);

  useEffect(() => {
    const loadDefaultTeam = async () => {
      if (!selectedTeam) {
        return;
      }
      const teamObj = await MarconipyApi.team.get(selectedTeam.uuid);
      setOrganization(teamObj.organization);
      setPlan(teamObj.plan);
    };
    if (isAuth && selectedTeam) {
      loadDefaultTeam();
    }
  }, [isAuth, selectedTeam]);

  const contextValue: TeamContextType = {
    loading,
    teams,
    selectedTeam,
    organization,
    plan,
    setSelectedTeam: handleSelectTeam,
    createNewTeam,
    deleteTeam,
    showUpgradeDialog,
    switchToTeamUUID,
    teamHasChanged: !!(
      selectedTeam &&
      teamUUIDRef.current &&
      selectedTeam.uuid != teamUUIDRef.current
    ),
  };

  return (
    <TeamContext.Provider value={contextValue}>
      <>
        {children}
        <Alert open={upgradedNeeded} onClose={() => setUpgradeNeeded(false)}>
          <AlertTitle>Upgrade your plan</AlertTitle>
          <AlertBody>
            You have reached the limit of your current plan.
            <br />
            Please upgrade to continue automating your workflows.
          </AlertBody>
          <AlertActions>
            <Button
              variant="primary"
              onClick={() => {
                setUpgradeNeeded(false);
                navigate("/teams/" + selectedTeam?.uuid + "/upgrade");
              }}
            >
              <RiRocketLine />
              Upgrade
            </Button>
          </AlertActions>
        </Alert>
      </>
    </TeamContext.Provider>
  );
};

// Loader function remains the same
export async function loader({ params }: { params: any }) {
  return null;
}
