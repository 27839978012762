import BaseApi from "./base";

interface OauthAppDetails {
  name: string;
  description: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
}

interface OauthExchangeResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  team_name: string;
  email: string;
}

export default class OauthApi extends BaseApi {
  static async appDetails(
    clientId: string,
    redirectUri: string,
    scope: string,
  ): Promise<OauthAppDetails> {
    return await this.authenticatedPostApiCall<OauthAppDetails>(
      `/oauth/app-details/`,
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
      },
    );
  }

  static async authorizeOAuth(
    clientId: string,
    redirectUri: string,
    scope: string,
  ): Promise<{ code: string }> {
    return await this.authenticatedPostApiCall<{ code: string }>(
      `/oauth/authorize/`,
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
      },
    );
  }

  static async exchangeCodeForToken(
    code: string,
    clientId: string,
    clientSecret: string,
    redirectUri: string,
  ): Promise<OauthExchangeResponse> {
    return await this.authenticatedPostApiCall<OauthExchangeResponse>(
      `/oauth/token/`,
      {
        code: code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
      },
    );
  }
}
