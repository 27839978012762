import { User, UserWithStatus } from "../types";
import BaseApi from "./base";

export default class UsersApi extends BaseApi {
  static async update({
    first_name = "",
    email = "",
    last_name = "",
    name = "",
    onboarded = false,
  }: {
    first_name?: string;
    email?: string;
    last_name?: string;
    name?: string;
    onboarded?: boolean;
  }): Promise<User> {
    let authObj: Record<string, any> = {};
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    authObj["timezone"] = tz;
    if (first_name !== "") {
      authObj["first_name"] = first_name;
    }
    if (email !== "") {
      authObj["email"] = email;
    }
    if (last_name !== "") {
      authObj["last_name"] = last_name;
    }
    if (name !== "") {
      authObj["name"] = name;
    }
    if (onboarded) {
      authObj["onboarded"] = onboarded;
    }

    return await this.authenticatedPatchApiCall<User>(
      "/dj-rest-auth/user/",
      authObj,
    );
  }
  static async me(): Promise<UserWithStatus> {
    return await this.authenticatedGetApiCall(`users/@me/status/`);
  }
}
