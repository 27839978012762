import { Button } from "@/components/design-system/button";
import Label from "@/components/styled/Label";
import MarconipyApi from "@/utils/marconipyApi";
import React, { useState } from "react";
import { Input } from "../design-system/input";

type PasswordForgottenFormProps = {
  onLoginRedirect: () => void;
};
export default function PasswordForgottenForm({
  onLoginRedirect,
}: PasswordForgottenFormProps) {
  const [username, setUsername] = useState("");
  const [stage, setStage] = useState("email");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      await MarconipyApi.auth.resetPassword(username);
      setStage("code");
    } catch (err: any) {
      let errors = err.response.data;
      for (let key of Object.keys(errors)) {
        setError(errors[key][0]);
        break;
      }
      setStage("error");
    }
  };
  const handleSubmitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setError("");
    try {
      await MarconipyApi.auth.confirmCode(username, code);
      setStage("password");
    } catch (err: any) {
      let errors = err.response.data;
      for (let key of Object.keys(errors)) {
        setError(errors[key][0]);
        break;
      }
      setStage("error");
    }
  };
  const handleSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      await MarconipyApi.auth.sendNewPassword(
        username,
        password,
        password,
        code,
      );
      setStage("success");
    } catch (err: any) {
      let errors = err.response.data;
      console.log(errors);
      setError(JSON.stringify(err.response.data));
      // for (let key of Object.keys(errors)) {
      //   setError(errors[key]);
      //   break;
      // }
      setStage("error");
    }
  };
  const handleError = async () => {
    setError("");
    setStage("email");
  };

  return (
    <div className="flex flex-col gap-2">
      {stage == "email" && (
        <>
          <form onSubmit={handleSubmitEmail} className="flex flex-col">
            <Label>Your email:</Label>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="youremail@domain.com"
              autoFocus
            />
            {error && <small className="text-primary">{error}</small>}
            <br />
            <Button variant="primary" type="submit">
              Next
            </Button>
          </form>
        </>
      )}
      {stage == "code" && (
        <>
          <form onSubmit={handleSubmitCode} className="flex flex-col">
            <Label>Check your email for a code:</Label>
            <Input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="super secret code"
              autoFocus
            />
            {error && <small className="text-primary">{error}</small>}
            <br />
            <Button variant="primary" type="submit">
              Next
            </Button>
          </form>
        </>
      )}
      {stage == "password" && (
        <>
          <form onSubmit={handleSubmitPassword} className="flex flex-col">
            <Label>Type your new password:</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Your password"
            />
            {error && <small className="text-primary">{error}</small>}
            <br />
            <Button variant="primary" type="submit">
              Next
            </Button>
          </form>
        </>
      )}
      {stage == "success" && (
        <div className="flex justify-center">
          <Button variant="primary" onClick={onLoginRedirect}>
            Success! You can now log in
          </Button>
        </div>
      )}
      {stage == "error" && (
        <>
          <p>There was an error:</p>
          <p className="text-primary">{error}</p>
          <br />
          <Button variant="primary" onClick={handleError}>
            Try again
          </Button>
        </>
      )}
      <Button variant="secondary" onClick={onLoginRedirect}>
        Go back
      </Button>
    </div>
  );
}
